// Constants
import { ClientAppId } from "@ms/uno-constants/lib/local/AppConstants";
import { PortfolioItemSubType } from "@ms/uno-constants/lib/local/PortfolioConstants";
import { MetadataContainerIdKey, MetadataContainerTypeKey, MetadataConvertPlanIdKey, MetadataFriendlyNameKey, MetadataGroupIdKey, MetadataIsSharedKey, MetadataLastPinnedTimeStampKey, MetadataOrgIdKey, MetadataOrgTypeKey, MetadataPlaceTypeKey, MetadataSubTypeKey, MetadataTypeKey, ProjectMetadataOrgType, ProjectMetadataType } from "../../constants/MruConstants";
// Models
import { ContainerType } from "../../service/graph-legacy/container/ContainerType";
import { MruApp } from "../../service/mru/MruApp";
import { MruCategory } from "../../service/mru/MruCategory";
import { MruExtension } from "../../service/mru/MruExtension";
import { MruType } from "../../service/mru/MruType";
import { MruState } from "../../service/mru/MruState";
// Utilities
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
import { applyDiffMomentCustomizer, getDiff, getDiffMomentCustomizer } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import { getQueryParamValue } from "@ms/uno-utilities/lib/local/UrlUtilities";
import isEmpty from "lodash/isEmpty";
import mergeWith from "lodash/mergeWith";
import moment from "moment";
import { isSharedContainer } from "../../utilities/PlanUtilities";
/**
 * Mru client object
 */ export class MruItem {
    applyDiffs(...diff) {
        if (diff.length > 0) {
            return mergeWith(this.getClone(), ...diff, applyDiffMomentCustomizer);
        }
        return this;
    }
    getDiff(target) {
        return getDiff(this, target, getDiffMomentCustomizer);
    }
    getClone() {
        return new MruItem(this);
    }
    setProperty(key, value) {
        let clone = this.getClone();
        clone[key] = value;
        if (key === "isPinned" && value === true) {
            clone = clone.setProperty("metadata", {
                ...this.metadata,
                lastPinnedTimeStamp: moment()
            });
        }
        return clone;
    }
    forChange(isCreate) {
        let isPinned;
        if (isCreate) {
            /**
             * Even if the item might already exist in the Mru service, Uno can fall back to mru item creation if it has not been fetched yet.
             * We do not want to potentially overwrite the is_pinned state in the service, especially if its already pinned.
             * For writes to MRU service, undefined "is_pinned" defaults to false for Adds; defaults to existing value for updates.
             * Therefore, on creation, we should only include is_pinned if it is explicitly set to true.
             */ isPinned = this.isPinned ? this.isPinned : undefined;
        } else {
            isPinned = this.isPinned;
        }
        const mruResource = {
            app: this.app,
            category: this.category,
            type: this.mruType,
            is_pinned: isPinned,
            url: this.url,
            meta_data: this.getResourceMetadata(),
            title: this.title,
            server_doc_id: this.serverDocId,
            extension: this.extension ?? undefined
        };
        return mruResource;
    }
    /**
     * Create mru item for premium plan
     * @param planId Id for the premium plan
     * @param orgId Id for the CDS org for the project
     * @param isPinned is it favorited
     * @param title name of the plan
     * @param url mru url
     * @param friendlyName cds instance friendly name
     * @param groupId Id for group its associated with
     * @param convertedPlanId Id for the converted plan
     * @returns item to be sent to mru service
     */ static createForPremiumPlan(planId, orgId, isPinned, title, url, friendlyName, groupId, convertedPlanId) {
        return new MruItem({
            mruId: generateGuid(),
            category: MruCategory.Document,
            mruType: MruType.DocumentUrl,
            app: MruApp.Project,
            isPinned: isPinned,
            url: url,
            title: title,
            serverDocId: planId,
            timestamp: moment(),
            metadata: {
                placeType: "",
                metadataType: ProjectMetadataType.Project,
                orgType: ProjectMetadataOrgType.Default,
                orgId: orgId,
                groupId: groupId ?? null,
                friendlyName: friendlyName,
                lastPinnedTimeStamp: isPinned ? moment() : null,
                convertedPlanId
            },
            extension: MruExtension.Project
        });
    }
    /**
     * Create mru item for basic plan
     * @param planId Id for the basic plan
     * @param isPinned is it favorited
     * @param title name of the plan
     * @param url mru url
     * @param containerId Id for container its associated with (like group/ roster etc)
     * @param containerType Id for container its associated with
     * @returns item to be sent to mru service
     */ static createForBasicPlan(planId, isPinned, title, url, containerId, containerType) {
        return new MruItem({
            mruId: generateGuid(),
            category: MruCategory.Document,
            mruType: MruType.DocumentUrl,
            app: MruApp.Planner,
            isPinned: isPinned,
            url: url,
            title: title,
            serverDocId: planId,
            timestamp: moment(),
            metadata: {
                containerType: containerType,
                containerId: containerId,
                lastPinnedTimeStamp: isPinned ? moment() : null
            },
            extension: MruExtension.Planner
        });
    }
    /**
     * Create mru item for todo
     * @param planId Id for the premium plan
     * @param isPinned is it favorited
     * @param title name of the plan
     * @param url mru url
     * @param isShared Is the todo list shared
     * @returns item to be sent to mru service
     */ static createForTodo(planId, isPinned, title, url, isShared) {
        return new MruItem({
            mruId: generateGuid(),
            category: MruCategory.Document,
            mruType: MruType.DocumentUrl,
            app: MruApp.ToDo,
            isPinned: isPinned,
            url: url,
            title: title,
            serverDocId: planId,
            timestamp: moment(),
            metadata: {
                isShared: isShared,
                lastPinnedTimeStamp: isPinned ? moment() : null
            },
            extension: MruExtension.Todo
        });
    }
    /**
     * Create mru item for portfolio
     * @param portfolioId Id for the portfolio
     * @param isPinned is it favorited
     * @param portfolioName name of the portfolio
     * @param url mru url
     * @returns item to be sent to mru service
     */ static createForPortfolio(portfolioId, isPinned, portfolioName, url) {
        // TODO: 9148441 - Construct MRU URL for Portfolio and populate it.
        const myPortfoliosPageUrl = `https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.planner/myportfolios?portfolioId=${portfolioId}`;
        return new MruItem({
            mruId: generateGuid(),
            category: MruCategory.Document,
            mruType: MruType.DocumentUrl,
            app: MruApp.Portfolio,
            isPinned: isPinned,
            url: url ?? myPortfoliosPageUrl,
            title: portfolioName,
            serverDocId: portfolioId,
            timestamp: moment(),
            metadata: {
                lastPinnedTimeStamp: isPinned ? moment() : null,
                subType: PortfolioItemSubType.Portfolio
            },
            extension: MruExtension.Portfolio
        });
    }
    /**
     * Build from a MRU resource entity as initialization data.
     * @param mruItemResource Mru Item resource entity
     */ static fromResource(resource) {
        return new MruItem({
            mruId: resource.mru_id,
            category: resource.category,
            mruType: resource.type,
            app: resource.app,
            isPinned: resource.is_pinned,
            url: resource.url,
            title: resource.title,
            serverDocId: resource.server_doc_id ? resource.server_doc_id : this.extractServerDocId(resource.url),
            timestamp: moment.utc(resource.time_stamp),
            metadata: MruItem.getMetadataFromResource(resource),
            extension: MruItem.getExtensionFromResource(resource)
        });
    }
    forPersistence() {
        const mruResource = {
            app: this.app,
            category: this.category,
            is_pinned: this.isPinned,
            meta_data: this.getResourceMetadata(),
            display_path: [],
            extension: this.extension ?? undefined,
            mru_id: this.mruId,
            server_doc_id: this.serverDocId,
            service_info: {
                user_id: "",
                friendly_service_name: ""
            },
            state: MruState.Normal,
            time_stamp: this.timestamp.toISOString(),
            title: this.title,
            type: this.mruType,
            url: this.url,
            isPinnedString: this.isPinned ? "true" : "false"
        };
        return mruResource;
    }
    static extractServerDocId(url) {
        let serverDocId;
        try {
            serverDocId = getQueryParamValue(url, "ProjUid");
        } catch (error) {
            serverDocId = generateGuid();
        }
        return serverDocId ?? generateGuid();
    }
    isShared() {
        /* flightDate is the date following the day of complete flight rollout.
        See flight: https://ecs.skype.com/ProjectServices/ProjectServices/configurations/1145611 */ const flightDate = moment(new Date(2023, 10, 30));
        switch(this.app){
            case MruApp.Project:
                const typedProjectMetadata = this.metadata;
                if (typedProjectMetadata.groupId != null) {
                    return true;
                }
                /* If the project's timestamp falls after the flight rollout date,
                 we can be certain its groupId value is accurate and up to date.
                 Else, it's unknown. */ return flightDate <= this.timestamp ? false : null;
            case MruApp.Planner:
                const typedPlannerMetadata = this.metadata;
                const { containerType, containerId } = typedPlannerMetadata;
                if (containerId == null && containerType !== ContainerType.User || containerType == null) {
                    // If the containerId is null or empty, we can't determine if it's shared or not
                    return null;
                } else {
                    return isSharedContainer(containerType);
                }
            case MruApp.ToDo:
                const typedTodoMetadata = this.metadata;
                return typedTodoMetadata.isShared ?? false;
            case MruApp.Portfolio:
                return null;
            default:
                return false;
        }
    }
    getLastPinnedTimeStamp() {
        return "lastPinnedTimeStamp" in this.metadata ? this.metadata.lastPinnedTimeStamp : null;
    }
    /**
     * Build the metadata Record object for the IMruItemResource
     */ getResourceMetadata() {
        const record = {};
        switch(this.app){
            case MruApp.Project:
                const typedProjectMetadata = this.metadata;
                if (typedProjectMetadata.groupId != null) {
                    record[MetadataGroupIdKey] = typedProjectMetadata.groupId;
                }
                if (typedProjectMetadata.orgType != null) {
                    record[MetadataOrgTypeKey] = typedProjectMetadata.orgType;
                }
                if (typedProjectMetadata.friendlyName != null) {
                    record[MetadataFriendlyNameKey] = typedProjectMetadata.friendlyName;
                }
                if (this.category === MruCategory.Place && typedProjectMetadata.placeType != null) {
                    record[MetadataPlaceTypeKey] = typedProjectMetadata.placeType;
                } else if (this.category === MruCategory.Document && typedProjectMetadata.metadataType != null) {
                    record[MetadataTypeKey] = typedProjectMetadata.metadataType;
                }
                if (typedProjectMetadata.convertedPlanId != null) {
                    record[MetadataConvertPlanIdKey] = typedProjectMetadata.convertedPlanId;
                }
                break;
            case MruApp.Planner:
                const typedPlannerMetadata = this.metadata;
                if (typedPlannerMetadata.containerType != null) {
                    record[MetadataContainerTypeKey] = typedPlannerMetadata.containerType;
                }
                if (typedPlannerMetadata.containerId != null) {
                    record[MetadataContainerIdKey] = typedPlannerMetadata.containerId;
                }
                break;
            case MruApp.ToDo:
                const typedTodoMetadata = this.metadata;
                if (typedTodoMetadata.isShared != null) {
                    record[MetadataIsSharedKey] = typedTodoMetadata.isShared.toString();
                }
                break;
            case MruApp.Portfolio:
                const typedPortfolioMetadata = this.metadata;
                if (typedPortfolioMetadata.subType != null) {
                    record[MetadataSubTypeKey] = typedPortfolioMetadata.subType;
                }
                break;
            default:
                break;
        }
        if (this.app === MruApp.Project || this.app === MruApp.Planner || this.app === MruApp.ToDo || this.app === MruApp.Portfolio) {
            record[MetadataLastPinnedTimeStampKey] = "lastPinnedTimeStamp" in this.metadata ? this.metadata.lastPinnedTimeStamp?.toISOString() : null;
        }
        return record;
    }
    /**
     * Builds the correct document extension given the resource values
     * @param resource Mru Item resource entity
     */ static getExtensionFromResource(resource) {
        if (resource.category !== MruCategory.Document) {
            return null;
        }
        switch(resource.app){
            case MruApp.Project:
                return MruExtension.Project;
            case MruApp.Planner:
                return MruExtension.Planner;
            case MruApp.ToDo:
                return MruExtension.Todo;
            case MruApp.Portfolio:
                return MruExtension.Portfolio;
            default:
                return null;
        }
    }
    /**
     * Build the IMruMetadata object from the IMruItemResource metadata Record object
     * @param resourceMetadata Metadata record from the resource
     * @param mruApp Mru app type from the resource
     */ static getMetadataFromResource(resource) {
        const resourceMetadata = resource.meta_data;
        let metadata;
        switch(resource.app){
            case MruApp.Project:
                const typedProjectMetadata = {
                    groupId: resourceMetadata[MetadataGroupIdKey] ?? null,
                    placeType: resourceMetadata[MetadataPlaceTypeKey] ?? null,
                    metadataType: stringToEnum(resourceMetadata[MetadataTypeKey], ProjectMetadataType) ?? null,
                    orgType: stringToEnum(resourceMetadata[MetadataOrgTypeKey], ProjectMetadataOrgType) ?? ProjectMetadataOrgType.Default,
                    orgId: resourceMetadata[MetadataOrgIdKey] ?? null,
                    friendlyName: resourceMetadata[MetadataFriendlyNameKey] ?? "",
                    lastPinnedTimeStamp: resourceMetadata[MetadataLastPinnedTimeStampKey] ? moment.utc(resourceMetadata[MetadataLastPinnedTimeStampKey]) : null,
                    convertedPlanId: resourceMetadata[MetadataConvertPlanIdKey] ?? null
                };
                metadata = typedProjectMetadata;
                break;
            case MruApp.Planner:
                const typedPlannerMetadata = {
                    containerType: stringToEnum(resourceMetadata[MetadataContainerTypeKey], ContainerType),
                    containerId: resourceMetadata["containerId"] ?? null,
                    lastPinnedTimeStamp: resourceMetadata[MetadataLastPinnedTimeStampKey] ? moment.utc(resourceMetadata[MetadataLastPinnedTimeStampKey]) : null
                };
                metadata = typedPlannerMetadata;
                break;
            case MruApp.ToDo:
                const typedTodoMetadata = {
                    isShared: resourceMetadata[MetadataIsSharedKey]?.toLowerCase() === "true" ? true : false,
                    lastPinnedTimeStamp: resourceMetadata[MetadataLastPinnedTimeStampKey] ? moment.utc(resourceMetadata[MetadataLastPinnedTimeStampKey]) : null
                };
                metadata = typedTodoMetadata;
                break;
            case MruApp.Portfolio:
                const typedPortfolioMetadata = {
                    lastPinnedTimeStamp: resourceMetadata[MetadataLastPinnedTimeStampKey] ? moment.utc(resourceMetadata[MetadataLastPinnedTimeStampKey]) : null,
                    subType: stringToEnum(resourceMetadata[MetadataSubTypeKey], PortfolioItemSubType)
                };
                metadata = typedPortfolioMetadata;
                break;
            case MruApp.Excel:
            case MruApp.Word:
            case MruApp.PowerPoint:
            case MruApp.PdfViewer:
                const typedSharepointFileMetadata = {
                    oneDriveInfo: resource.onedrive_info ? {
                        driveId: resource.onedrive_info.drive_id,
                        itemId: resource.onedrive_info.item_id,
                        parentReferenceId: resource.onedrive_info.parent_reference_id,
                        driveType: resource.onedrive_info.drive_type
                    } : null,
                    creationInfo: resource.creation_info ? {
                        userInfo: {
                            displayName: resource.creation_info.user_info?.display_name,
                            upn: resource.creation_info.user_info?.upn
                        },
                        app: resource.creation_info.app,
                        timestamp: resource.creation_info.timestamp
                    } : null,
                    webUrl: resource.web_url ?? null
                };
                metadata = typedSharepointFileMetadata;
                break;
            default:
                throw new Error(`Unsupported mru app type: ${resource.app}`);
        }
        return metadata;
    }
    getContainerId() {
        switch(this.app){
            case MruApp.Project:
                const typedProjectMetadata = this.metadata;
                // To clear the MRU copy of the group ID, we must set it to "" instead of null.
                // In this case, the local copy keeps "" and the MRU service will remove the group ID.
                // Return null if the group ID is empty string so the display logic works.
                return typedProjectMetadata.groupId === "" ? null : typedProjectMetadata.groupId;
            case MruApp.Planner:
                const typedPlannerMetadata = this.metadata;
                return typedPlannerMetadata.containerId;
            case MruApp.ToDo:
                return ClientAppId.ToDo;
            case MruApp.Portfolio:
            default:
                return null;
        }
    }
    getContainerType() {
        switch(this.app){
            case MruApp.Project:
                const typedProjectMetadata = this.metadata;
                return isEmpty(typedProjectMetadata.groupId) ? ContainerType.User : ContainerType.Group;
            case MruApp.Planner:
                // In the case that the mru service doesn't update a planner mru item with container data, we should return null to indicate that we're not certain of the plan's container type
                const typedPlannerMetadata = this.metadata;
                return typedPlannerMetadata.containerType;
            case MruApp.ToDo:
                return ContainerType.ToDo;
            case MruApp.Portfolio:
            default:
                return null;
        }
    }
    isProjectOnlinePlan() {
        return this.app === MruApp.Project && this.metadata.metadataType === ProjectMetadataType.PJO;
    }
    isProjectOperationsPlan() {
        return this.app === MruApp.Project && this.metadata.metadataType === ProjectMetadataType.ProjectOperations;
    }
    // TODO (WI:9795305): Figure best way to identify if a plan is named org if we cant rely on mru data
    isNamedOrgPlan() {
        return this.app === MruApp.Project && this.metadata.orgType === ProjectMetadataOrgType.Named;
    }
    constructor(propertyBag){
        if (propertyBag.serverDocId == null) {
            throw new Error("ArgumentNullException: serverDocId");
        }
        this.mruId = propertyBag.mruId;
        this.category = propertyBag.category;
        this.mruType = propertyBag.mruType;
        this.app = propertyBag.app;
        this.isPinned = propertyBag.isPinned;
        this.url = propertyBag.url;
        this.title = propertyBag.title;
        this.serverDocId = propertyBag.serverDocId;
        this.timestamp = moment(propertyBag.timestamp.toISOString());
        this.metadata = propertyBag.metadata;
        this.extension = propertyBag.extension;
    }
}
