// App Bootstrap
import { getActionCreatorProviders, getAsyncDispatcher, getAuthServiceProvider, getContextAndInitializeCultureData, getLogicModuleProviders, getRequestPrioritizer, getServiceProviders, getStoreProviders, getTelemetryContext, loadAndInitializeWorkers, logPlanViewSubRouteType, setupAppContext, getAgentProviders, getPersistentAsyncStorage, } from "../utilities/AppBootstrapUtils";
import { getInitialRoute } from "../utilities/AppBootstrapRouteUtils";
// Constants
import { AppRootElementId, ClientFlavor, Environment } from "@ms/uno-constants/lib/local/AppConstants";
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
// Controls
import { applyUnoGlobalStyleOverrides } from "@ms/uno-shell/lib/local/controls/unoShell/Global.styles";
import { UnoShell } from "@ms/uno-shell/lib/local/controls/unoShell/UnoShell";
import { CreateTaskFromMessageShell } from "@ms/uno-shell/lib/local/controls/createTaskFromMessageShell/CreateTaskFromMessageShell";
import { ProductionServiceOrchestrator } from "../base/factories/ProductionServiceOrchestrator";
import { TeamsActionCreatorFactory } from "./factories/TeamsActionCreatorFactory";
import { TeamsLogicModuleFactory } from "./factories/TeamsLogicModuleFactory";
import { TeamsServiceFactory } from "./factories/TeamsServiceFactory";
import { TeamsStoreFactory } from "./factories/TeamsStoreFactory";
import { AgentFactory } from "../base/factories/AgentFactory";
import { FluxRegistry } from "@ms/uno-fluxcore/lib/local/registry";
import { UnoRegistryDescriptor } from "../UnoRegistryDescriptor";
import { DynamicImportFailureAction } from "@ms/uno-actions/lib/local/app/DynamicImportFailureAction";
// Host Adaptor
import { FailedReason } from "@ms/uno-hostadaptors/lib/local/IUnoHostAdaptor";
import { TeamsAdaptor } from "@ms/uno-hostadaptors/lib/local/TeamsAdaptor";
import { TeamsWorkerLoader } from "./loaders/TeamsWorkerLoader";
// Models
import { InitializationError } from "@ms/uno-errors/lib/local/errors/InitializationError";
import { User } from "@ms/uno-models/lib/local/client/user/User";
import { LoggerManager } from "@ms/uno-telemetry/lib/local/LoggerManager";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { initializeEngagementLogger, setCurrentRouteInfoForLogs } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
import { WebPlatformPerformanceMarker } from "@ms/uno-telemetry/lib/local/performance/WebPlatformPerformanceMarker";
// Utilities
import { createDefaultPolicy } from "@ms/uno-utilities/lib/local/TrustedTypeUtilities";
import { logNotificationInfo, extractEntityRoutingIdFromSubRoute } from "@ms/uno-routing";
import { getLocalStorage } from "@ms/uno-utilities/lib/local/storage/StorageUtilities";
import { ComponentLoader } from "../utilities/loader/ComponentLoader";
import React from "react";
import * as ReactDOM from "react-dom";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
import { sleep } from "@ms/uno-utilities/lib/local/ControlFlowUtilities";
/**
 * The production bootstrap function
 */
export async function bootstrap() {
    // Set up performance Marker and mark bootstrap
    const performanceMarker = new WebPlatformPerformanceMarker();
    // Added timestamp as extradata to calculate the P1 metric (Time taken from user click on the icon to iframe creation and URL load)
    // The user click time is in global time format, while the redirect start time is the relative time from the start of the app (0).
    // Adding the current date time here allows us to accurately calculate the P1 time.
    performanceMarker.mark(MarkerTag.AppBootstrap, { timestamp: Date.now().toString() });
    // Initialize the host adaptor
    const hostAdaptor = new TeamsAdaptor(performanceMarker);
    // Teams SDK, AppContext needs to be initialized only once. Re-boot for cached load should skip the same.
    if (!hostAdaptor.isInitialized()) {
        await hostAdaptor.initializeSdk();
        try {
            setupAppContext();
        }
        catch (e) {
            const err = new InitializationError(e.name, e.message);
            hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message: err.message });
            // explicitly throw error here (as it's not sufficiently handled)
            throw err;
        }
    }
    const configProvider = () => AppContext.appConfiguration;
    const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
    hostAdaptor.setConfigProvider(configProvider);
    // Cast to Teams context is safe as we are in Teams
    const context = (await getContextAndInitializeCultureData(hostAdaptor));
    const telemetryContext = getTelemetryContext(context);
    /** Teams client sessionId */
    const teamsSessionId = context.sessionId;
    /** The permanent Teams Ring the user belongs to in ECS */
    const homeRingId = configProvider().sessionMetaData?.homeRingId ?? "";
    /** Teams license type */
    const teamsLicenseType = context.userTeamsLicenseType ?? "";
    // Setup the flux registry
    const registry = new FluxRegistry(UnoRegistryDescriptor);
    // Setup loggers
    const loggerManager = new LoggerManager(telemetryContext, configProvider);
    const telemetryLoggers = loggerManager.getLoggers();
    const loggers = {
        ...telemetryLoggers,
        performanceMarker,
    };
    hostAdaptor.setLoggers(loggers);
    // Early exit on background load if the change gate is enabled.
    // This should be used only in case of an incident and not for feature flighting,
    // as notify failure to Teams would mark a bad background load history for the app.
    // Primarily use Teams app manifest backgroundLoadConfiguration property for enabling background load (pre-warming/pre-fetching).
    if (configProvider().changeGates.DisableTeamsAppBackgroundLoad && context.isBackgroundLoad) {
        hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message: `[Bootstrap] Background load disabled by change gate` });
        return;
    }
    // Setup nested app authentication if the flight is enabled
    if (configProvider().flights.EnableNestedAppAuth) {
        hostAdaptor.setupNestedAppAuth(loggers);
    }
    let initialRoute = getInitialRoute(clientFlavor);
    const initialRouteFromDeepLink = await hostAdaptor.getInitialRoute();
    if (initialRouteFromDeepLink != null) {
        initialRoute = initialRouteFromDeepLink;
    }
    setCurrentRouteInfoForLogs(initialRoute.subRouteType, initialRoute.subRoute, loggers.logHandler);
    // Logging XSessionId(Current teams sessionId) to correlate
    const { hostname } = location;
    loggers.traceLogger.logTrace(0x1e423004 /* tag_4q9ae */, TraceLevel.Info, `[Bootstrap] Microsoft Teams SDK is initialized and bootstrapping application`, {
        XSessionId: teamsSessionId,
        HomeRingId: homeRingId,
        XLicenseType: teamsLicenseType,
        CorrelationId: configProvider().sessionMetaData.appLoadCorrelationId,
        ServiceBuildVersion: configProvider().sessionMetaData.serverBuild,
        Hostname: hostname,
    });
    const ringIdValidationErrorMessage = hostAdaptor.validateRingId();
    if (ringIdValidationErrorMessage) {
        const err = new InitializationError("InvalidTeamsRingId", ringIdValidationErrorMessage);
        loggers.traceLogger.logTrace(0x1e3d2214 /* tag_4psiu */, TraceLevel.Error, `[ErrorType=${err.name}][Error=${err.message}]`);
        // TODO WI #1234: Evaluate whether to stop here, notifyFailure or re-throw upon config mismatch with host, based on telemetry/testing
    }
    // Check if the combination of rollout stage and environment is unexpected
    const isCombinationUnexpected = AppContext.isRolloutStageAndEnvironmentCombinationUnexpected();
    if (isCombinationUnexpected) {
        loggers.traceLogger.logTrace(0x1e3e26d6 /* tag_4p81w */, TraceLevel.Error, `[Bootstrap] Found invalid combination of rollout stage and environment: [RolloutStage=${configProvider().settings.rolloutStage}][Environment=${configProvider().sessionMetaData.environment}]`);
    }
    // Setup persistent storage
    const persistentStorage = getLocalStorage(context.userId, loggers.traceLogger, configProvider);
    const user = User.fromUserContext(context);
    const loader = new ComponentLoader(loggers);
    const storeFactory = new TeamsStoreFactory(loader, context, user, initialRoute, loggers, persistentStorage, configProvider, initialRouteFromDeepLink != null, null /** No default query parameters **/);
    const storeProviders = getStoreProviders(storeFactory);
    // Initialize agent providers
    const agentFactory = new AgentFactory(loader);
    const agentProviders = getAgentProviders(agentFactory);
    // Initialize engagement logging
    initializeEngagementLogger(storeProviders.route().getCurrentSubRouteType.bind(storeProviders.route()), loggers.engagementLogger);
    // Log relevant engagement info (which can only be done AFTER we've initialized EngagementLogger)
    loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.TeamsApp, EngagementTelemetryConstants.Bootstrap, {
        XSessionId: teamsSessionId,
        HomeRingId: homeRingId,
        XLicenseType: teamsLicenseType,
        Hostname: hostname,
    });
    if (clientFlavor !== ClientFlavor.TaskFromMessage) {
        logNotificationInfo(initialRoute, loggers);
        logPlanViewSubRouteType(EngagementTelemetryConstants.TeamsApp, storeProviders.route().getCurrentSubRouteType(), loggers);
    }
    // Dispatcher for the flux system.
    const dispatcher = getAsyncDispatcher(configProvider, registry, storeFactory, loggers);
    loader.setOnFailure((name) => dispatcher.dispatchActionAsync(new DynamicImportFailureAction()));
    // Set up request prioritizer
    const requestPrioritizer = getRequestPrioritizer(storeProviders);
    // Setup Providers
    const serviceFactory = new TeamsServiceFactory(loader, loggers);
    // lazy loaded logic modules depend on lazy loaded services typically but authModule as an exception is needed by services upfront.
    // That is why authModule is created here and passed to services.
    const authService = getAuthServiceProvider(serviceFactory, dispatcher, hostAdaptor, loggers, configProvider)();
    const authStore = storeFactory.getAuthStoreProvider()();
    const lmFactory = new TeamsLogicModuleFactory(loader, loggers);
    const authModule = lmFactory.getAuthModuleProvider(dispatcher, authService, authStore, configProvider)();
    // Auth module's fetchAccessTokenAsync method needed by services
    const fetchAccessToken = authModule.fetchAccessTokenAsync.bind(authModule);
    // Auth module's refreshAccessTokenAsync method needed by services
    const refreshAccessToken = authModule.refreshAccessTokenAsync.bind(authModule);
    const serviceOrchestrator = new ProductionServiceOrchestrator(serviceFactory, requestPrioritizer, configProvider, loggers, fetchAccessToken, refreshAccessToken);
    const serviceProviders = getServiceProviders(serviceOrchestrator, authService);
    const lmProviders = getLogicModuleProviders(lmFactory, dispatcher, serviceProviders, storeProviders, configProvider, hostAdaptor, authService, authStore, fetchAccessToken);
    const acFactory = new TeamsActionCreatorFactory(loader, dispatcher, lmProviders, storeProviders, loggers, configProvider, hostAdaptor);
    const acProviders = getActionCreatorProviders(acFactory);
    // Fire app start action (must be done after store init)
    const perfCollectionActionCreator = acProviders.performanceCollection();
    perfCollectionActionCreator.appStart(storeProviders.route().getCurrentPath(), Date.now());
    if (clientFlavor !== ClientFlavor.TaskFromMessage) {
        setupHostCaching(configProvider, hostAdaptor, loggers, storeProviders.route(), acProviders);
    }
    // Setup persistent async storage
    const persistentAsyncStorage = getPersistentAsyncStorage(configProvider, context.userId, loggers);
    const workerLoader = new TeamsWorkerLoader(acProviders, storeProviders, (store) => storeFactory.loadForPersistence(store), (store) => storeFactory.loadForHydration(store), loader, loggers, telemetryLoggers, null, persistentAsyncStorage, configProvider, serviceProviders, lmProviders, hostAdaptor, dispatcher);
    loadAndInitializeWorkers(workerLoader, requestPrioritizer, loggers, persistentAsyncStorage, configProvider, hostAdaptor);
    // Apply Global styles before any classes or styles are generated.
    applyUnoGlobalStyleOverrides();
    // Do not register theme change handler for background load. It will be registered when the app is loaded in foreground.
    // If `isBackgroundLoad` is not present, it defaults to false.
    if (!context.isBackgroundLoad) {
        registerThemeChange(hostAdaptor, acProviders);
    }
    // Clear and unregister legacy TT service workers if the flight is enabled
    if (configProvider().flights.EnableLegacyTTServiceWorkerCleanup) {
        clearAndUnregisterLegacyTTServiceWorkers(loggers);
    }
    const showDevTools = configProvider().sessionMetaData.environment === Environment.Ppe;
    // create default policy
    if (configProvider().flights.EnableDefaultPolicy) {
        createDefaultPolicy();
    }
    ReactDOM.render(clientFlavor === ClientFlavor.TaskFromMessage ? (React.createElement(CreateTaskFromMessageShell, { dispatcherEventManagement: dispatcher, actionCreatorProviders: acProviders, serviceProviders: serviceProviders, storeProviders: storeProviders, workerLoader: workerLoader, hostAdaptor: hostAdaptor, loggers: loggers, onLoad: () => hostAdaptor.notifyLoaded(), onSuccess: () => {
            if (!configProvider().flights.EnableTeamsNotifySuccessV2) {
                hostAdaptor.notifyRenderSuccess();
            }
        }, onFail: (message) => hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message }), configProvider: configProvider })) : (React.createElement(UnoShell, { dispatcherEventManagement: dispatcher, actionCreatorProviders: acProviders, logicModuleProviders: lmProviders, serviceProviders: serviceProviders, storeProviders: storeProviders, workerLoader: workerLoader, hostAdaptor: hostAdaptor, loggers: loggers, onLoad: () => hostAdaptor.notifyLoaded(), onSuccess: () => {
            if (!configProvider().flights.EnableTeamsNotifySuccessV2) {
                hostAdaptor.notifyRenderSuccess();
            }
        }, onFail: (message) => hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message }), configProvider: configProvider, showDevTools: showDevTools, agentProviders: agentProviders })), document.getElementById(AppRootElementId));
}
/**
 * Setup load and unload handler for app caching
 * @param hostAdaptor The host adaptor
 * @param loggers Loggers
 * @param routeStore Route store
 * @param acProviders Action creator providers
 */
function setupHostCaching(configProvider, hostAdaptor, loggers, routeStore, acProviders) {
    if (configProvider().settings.disableAppCaching) {
        return;
    }
    const onLoadCallback = async (newClientFlavor, newRoute, contentUrl) => {
        loggers.traceLogger.logTrace(0x1e3d464e /* tag_4puzo */, TraceLevel.Info, `Teams cached app load. [ClientFlavor=${newClientFlavor}]`);
        // Override client flavor
        const currentClientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
        const clientFlavorChanged = newClientFlavor !== currentClientFlavor;
        AppContext.overrideClientFlavor(newClientFlavor);
        // Entity id before the cached load. Null for cases where entity id is not present such as myDay, myTasks etc.
        // Extract entity id based on the existing route (previous state before the cached load).
        const oldEntityId = extractEntityRoutingIdFromSubRoute(routeStore.getCurrentSubRoute(), routeStore.getCurrentSubRouteType());
        // Entity id after the cached load. Null for cases where entity id is not present such as myDay, myTasks.
        // Undefined for cases when client flavor changes where reboot would happen and determine the new entity id based on persisted route on new client flavor.
        let newEntityId = undefined;
        if (clientFlavorChanged) {
            // If there's a client flavor update, re-boot the app to ensure the correct client flavor is used across dependencies
            // When the app is brought out of cache, teams SDK invoke the onLoad handler that the app provids when it registered.
            // The onLoad handler from Teams SDK will pass the new contentUrl. It is up to the app to use this info to navigate to the correct context.
            // Note: Updating the href will trigger the re-boot of the app. With local testing we have seen the JS bundles are being picked up from disk cache.
            // This is required on client change only as other cases are handled with below -
            // 1. Tab <-> Tab switches - Updating the route with uno's routing action creator
            // 2. App <-> App switches - No route change required as the app is loaded with on the same route
            window.location.href = contentUrl;
        }
        else {
            // If new route is provided within same client flavor (switch between different tabs), navigate to the new route
            if (newRoute != null) {
                logNotificationInfo(newRoute, loggers);
                newEntityId = extractEntityRoutingIdFromSubRoute(newRoute.subRoute, newRoute.subRouteType);
                const routeActionCreator = acProviders.routing();
                routeActionCreator.navigate(newRoute);
            }
            else {
                // For flavors (such as App) where the newRoute is null i.e. no route change required, the entity id remains the same.
                newEntityId = oldEntityId;
            }
            // Update app context and re-register theme change handler.
            // This is not required when client flavor changes as the app will be re-booted and handle them inherently.
            setTimeout(async () => {
                // [WI #9580747]: onLoadCallback is invoked before the viewport is set for the app.
                // Triggering below store update before the viewport is set could cause incorrect rendering of the components.
                // Execute the theme update after a delay to ensure the app has been fully loaded before triggering the update.
                const appContextActionCreator = await acProviders.appContext();
                appContextActionCreator.fetchAppContext();
            }, 100);
            registerThemeChange(hostAdaptor, acProviders);
            // Call notify success to indicate that the app has been successfully loaded from cache
            hostAdaptor.notifyRenderSuccess();
        }
        loggers.performanceMarker.mark(MarkerTag.CachedLoadComplete, {
            oldFlavor: currentClientFlavor, // Client flavor before the cached load
            newFlavor: newClientFlavor, // Client flavor after the cached load
            oldEntityId: oldEntityId ?? "", // Entity id before the cached load. Empty string for cases where entity id is not present.
            newEntityId: newEntityId ?? "", // Entity id after the cached load. Empty string for cases where entity id is not present.
        });
    };
    const onUnloadCallback = async (notifyUnloadSuccess) => {
        // TODO: Invoke the background API on IViewRender once implemented for each view to perform clean up.
        notifyUnloadSuccess();
    };
    hostAdaptor.setupHostCaching(onLoadCallback, onUnloadCallback);
}
/**
 * Register theme change handler to update theme
 * @param hostAdaptor The host adaptor
 * @param acProviders The action creator providers
 */
function registerThemeChange(hostAdaptor, acProviders) {
    // Register theme change handler to update theme
    hostAdaptor.registerThemeChangeHandler(async (theme) => {
        const appContextAc = await acProviders.appContext();
        appContextAc.updateAppTheme(theme);
    });
}
/**
 * Sends a message to any running legacy TT service worker registrations to clear their caches and unregisters them
 * @param loggers Loggers to use for logging
 */
async function clearAndUnregisterLegacyTTServiceWorkers(loggers) {
    // The specific command to send to the service worker to clear its caches and unregister
    // ref: https://dev.azure.com/domoreexp/Teamspace/_git/teams-client-tasks?path=/web/ui/common/bootConstants.ts&version=GBdevelop&line=169&lineEnd=171&lineStartColumn=1&lineEndColumn=3&lineStyle=plain&_a=contents
    const unregisterCommand = {
        command: "clearAndUnregister",
    };
    const legacyWorkerPath = "/teamsui/service-worker.js";
    // Check that service workers are supported
    if (!("serviceWorker" in navigator) || typeof navigator.serviceWorker.getRegistrations !== "function") {
        // no-op, service workers are not supported
        // end control flow here
        return;
    }
    // Get all service worker registrations and post the message to unregister them
    try {
        const regs = await navigator.serviceWorker.getRegistrations();
        const legacyRegs = regs.filter((reg) => reg.installing?.scriptURL?.includes(legacyWorkerPath) || reg.active?.scriptURL?.includes(legacyWorkerPath));
        if (legacyRegs.length > 0) {
            await Promise.all(legacyRegs.map(async (reg) => {
                if (reg.installing) {
                    reg.installing.postMessage(unregisterCommand);
                    loggers.traceLogger.logTrace(0x1e284246 /* tag_4kejg */, TraceLevel.Info, `Tell legacy ServiceWorker to clear out [state=${reg.installing.state}]`);
                }
                if (reg.active) {
                    reg.active.postMessage(unregisterCommand);
                    loggers.traceLogger.logTrace(0x1e284245 /* tag_4kejf */, TraceLevel.Info, `Tell legacy ServiceWorker to clear out [state=${reg.active.state}]`);
                }
                // just wait for 5s to allow the worker to clear caches before unregistering
                await sleep(5000);
                const result = await reg.unregister();
                loggers.traceLogger.logTrace(0x1e284244 /* tag_4keje */, TraceLevel.Info, `Legacy ServiceWorker unregistration done [result=${result}]`);
            }));
            loggers.traceLogger.logTrace(0x1e284243 /* tag_4kejd */, TraceLevel.Info, `Legacy ServiceWorker unregistrations done [count=${legacyRegs.length}]`);
        }
    }
    catch (e) {
        loggers.traceLogger.logTrace(0x1e284242 /* tag_4kejc */, TraceLevel.Warning, `Legacy ServiceWorker unregistrations failed [reason=${ErrorUtilities.getMessage(e)}]`);
    }
}
