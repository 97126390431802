// Action creator
import { MruItem } from "@ms/uno-models/lib/local/client/mru/MruItem";
import { TaskEntitySet } from "@ms/uno-models/lib/local/client/legacyPlanner/task/TaskEntitySet";
import { FetchBasicTasksForAssigneeSucceededAction, FetchBasicTasksForMyDaySucceededAction, FetchBasicTasksForPlanSucceededAction } from "@ms/uno-actions/lib/local/legacyPlanner/task/BasicTaskFetchActions";
import { Plan } from "@ms/uno-models/lib/local/client/legacyPlanner/plan/Plan";
import { PlanDetails } from "@ms/uno-models/lib/local/client/legacyPlanner/plan/PlanDetails";
import { PlanEntitySet } from "@ms/uno-models/lib/local/client/legacyPlanner/plan/PlanEntitySet";
import { Bucket } from "@ms/uno-models/lib/local/client/legacyPlanner/bucket/Bucket";
// Actions
import { FetchMruItemsSucceededAction } from "@ms/uno-actions/lib/local/mru/MruActions";
import { FetchBasicPlanSucceededAction } from "@ms/uno-actions/lib/local/legacyPlanner/plan/BasicPlanFetchActions";
import { FetchTeamsTasksTabsSucceededAction } from "@ms/uno-actions/lib/local/teamsTasksTabs/FetchTeamsTasksTabsActions";
import { FetchBasicBucketsForPlanSucceededAction } from "@ms/uno-actions/lib/local/legacyPlanner/bucket/BasicBucketFetchActions";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utiliies
import { getPlanRouteDetailsForTab } from "@ms/uno-routing/lib/local/utilities/TeamsTabParsingUtilities";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Constants
import { PreFetchedDataKeys } from "@ms/uno-constants/lib/local/DataPreFetcherConstants";
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
/**
 * Action creator storing the pre-fetched data
 */ export class DataPreFetchActionCreator {
    storePrefetchedServiceResult(key, result) {
        if (!result[key]) {
            this.loggers.traceLogger.logTrace(0x1e262723 /* tag_4j829 */ , TraceLevel.Warning, `No data found in the PreFetcherResult: [Key:${key}]`);
            return;
        }
        switch(key){
            case PreFetchedDataKeys.Mru:
                this.storePreFetchedMruData(result[key].data);
                break;
            case PreFetchedDataKeys.MyDayTasks:
                this.storePreFetchedMyDayTasksData(result[key].data);
                break;
            case PreFetchedDataKeys.ATMTasks:
                this.storePreFetchedATMTasksData(result[key].data);
                break;
            case PreFetchedDataKeys.Plan:
                this.storePreFetchedPlanData(result[key].data);
                break;
            case PreFetchedDataKeys.TTTabs:
                this.storePreFetchedTTTabsData(result[key].data);
                break;
            case PreFetchedDataKeys.Buckets:
                this.storePreFetchedBucketsData(result[key].planId, result[key].data);
                break;
            case PreFetchedDataKeys.BasicPlanTasks:
                this.storePreFetchedBasicPlanTasksData(result[key].planId, result[key].data);
                break;
            default:
                this.loggers.traceLogger.logTrace(0x1e262722 /* tag_4j828 */ , TraceLevel.Warning, `Unsupported pre-fetched data type to save`);
                break;
        }
    }
    /**
     * Stores the pre-fetched MRU data
     * @param data: The pre-fetched MRU data
     */ storePreFetchedMruData(data) {
        const mruItems = [];
        data.map((item)=>MruItem.fromResource(item)).filter((mruItem)=>mruItem != null).forEach((mruItem)=>mruItems.push(mruItem));
        this.dispatcher.dispatchActionAsync(new FetchMruItemsSucceededAction(mruItems));
    }
    /**
     * Stores the pre-fetched MyDay tasks data
     * @param data: The pre-fetched MyDay tasks data
     */ storePreFetchedMyDayTasksData(data) {
        const succeededAction = new FetchBasicTasksForMyDaySucceededAction(data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched ATM tasks data
     * @param data: The pre-fetched ATM tasks data
     */ storePreFetchedATMTasksData(data) {
        const appContextStore = this.storeLoader.load(Store.AppContext);
        const userId = appContextStore.getAppContext().userId;
        if (userId == null) {
            this.loggers.traceLogger.logTrace(0x1e283621 /* tag_4kdy7 */ , TraceLevel.Warning, "User id is null.");
            return;
        }
        const succeededAction = new FetchBasicTasksForAssigneeSucceededAction(userId, data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched plan data
     * @param data: The pre-fetched plan data
     */ storePreFetchedPlanData(data) {
        for (const plan of data){
            if (plan.details == null) {
                throw new Error("No plan details data returned");
            }
            const planEntitySet = PlanEntitySet.builder().withPlan(Plan.builder().fromGraphResource(plan).build()).withDetails(PlanDetails.builder().fromGraphResource(plan.details).build()).build();
            this.dispatcher.dispatchActionAsync(new FetchBasicPlanSucceededAction(planEntitySet));
        }
    }
    /**
     * Stores the pre-fetched TT tabs data
     * @param data: The pre-fetched TT tabs data
     */ storePreFetchedTTTabsData(data) {
        const tabs = data.map((tabResource)=>{
            const details = getPlanRouteDetailsForTab(tabResource.id, tabResource.tabContentUrl) ?? {
                planId: `invalidEntityId${tabResource.id}`,
                planType: PlanType.Basic
            };
            return {
                ...tabResource,
                tabName: decodeURIComponent(tabResource.tabName),
                entityId: details.planId,
                app: details.planType,
                orgId: details.planType === PlanType.Premium ? details.orgId : null
            };
        }) ?? [];
        this.dispatcher.dispatchActionAsync(new FetchTeamsTasksTabsSucceededAction(tabs));
    }
    /**
     * Stores the pre-fetched buckets data
     * @param planId: The plan id to which the buckets belong
     * @param data: The pre-fetched buckets data
     */ storePreFetchedBucketsData(planId, data) {
        const succeededAction = new FetchBasicBucketsForPlanSucceededAction(planId, data.map((bucketResource)=>Bucket.builder.fromGraphResource(bucketResource).build()));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Stores the pre-fetched tasks data for a basic plan
     * @param planId: The plan id to which the tasks belong
     * @param data: The pre-fetched tasks data
     */ storePreFetchedBasicPlanTasksData(planId, data) {
        const succeededAction = new FetchBasicTasksForPlanSucceededAction(planId, data.map((graphTaskResource)=>TaskEntitySet.builder().fromGraphResource(graphTaskResource).build()), undefined, true, data.map((task)=>task.id));
        this.dispatcher.dispatchActionAsync(succeededAction);
    }
    /**
     * Initializes a new instance of the DataPreFetchActionCreator.
     * @param storeProviders: Store providers
     * @param dispatcher: Dispatcher to dispatch actions
     * @param loggers: Logger for telemetry
     */ constructor(storeLoader, dispatcher, loggers){
        this.storeLoader = storeLoader;
        this.dispatcher = dispatcher;
        this.loggers = loggers;
    }
}
