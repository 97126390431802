// Utilities
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
import { generateLogStringFromError } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
import { AssetLoadingError } from "@ms/uno-errors/lib/local/errors/AssetLoadingError";
import { MruApp } from "@ms/uno-models/lib/local/service/mru/MruApp";
import { UnoChildView, UnoViewStatus } from "@ms/uno-models/lib/local/constants/UnoViewConstants";
import { premiumPlanInstantiateLoader } from "@ms/uno-view-premiumplan/lib/local/controls/adapter/PremiumPlanViewAdaptor/PremiumPlanLoaders";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
export class ChildAppInstantiationWorker {
    /** The name of the worker */ get name() {
        return "ChildAppInstantiationWorker";
    }
    /**
     * Initialize the worker
     */ async init() {
        if (!this.initialized) {
            this.mruStore = await this.storeProviders.mru();
            this.routeStore = this.storeProviders.route();
            this.unoShellStore = this.storeProviders.unoShell();
            this.mruStore.subscribeToStore(()=>this.tryInitializePremiumPlan());
            this.routeStore.subscribeToStore(()=>this.tryInitializePremiumPlan());
            this.unoShellStore.subscribeToStore(()=>this.tryInitializePremiumPlan());
            this.initialized = true;
            // do an initial pass to initialize the premium plan if a store was hydrated from cache
            await this.tryInitializePremiumPlan();
        }
    }
    /**
     * Handler to try and initialize the premium plan if needed based on the mru and route store
     */ async tryInitializePremiumPlan() {
        // if mru store is updated, check if there are pinned items
        const pinnedItems = this.mruStore.getPinnedItems().data.filter((mruItem)=>mruItem.app === MruApp.Project);
        // if route store is updated, check if the current sub route is premium plan
        const currentSubRouteType = this.routeStore.getCurrentSubRouteType();
        // if uno shell store is updated, check if the premium plan view needs to be re-instantiated
        const premiumPlanViewInstantiationStatus = this.unoShellStore.getChildViewInstantiationStatus(UnoChildView.PremiumPlan);
        // Reset the instantiation started flag if the view is already instantiated or errored
        if (premiumPlanViewInstantiationStatus === UnoViewStatus.Instantiated || premiumPlanViewInstantiationStatus === UnoViewStatus.Error) {
            this.premiumPlanInstantiationStarted = false;
        }
        // if there are pinned premium plan items or the current sub route is premium plan,
        // or the premium plan instantiation status indicates it needs re instantiate,
        // instantiate premium plan
        if (pinnedItems.length > 0 || currentSubRouteType === SubRouteType.PremiumPlan || premiumPlanViewInstantiationStatus === UnoViewStatus.ReInstantiate) {
            await this.populateViewInstanceConfig();
            this.instantiatePremiumPlan(false);
        }
    }
    /**
     * Instantiate premium plan view
     * @param isDevMode Indicates if the premium plan is being instantiated in dev mode
     */ async instantiatePremiumPlan(isDevMode) {
        const premiumPlanViewInstantiationStatus = this.unoShellStore.getChildViewInstantiationStatus(UnoChildView.PremiumPlan);
        // If premium plan is already instantiated or in the process of being instantiated, don't instantiate again
        if (this.premiumPlanInstantiationStarted || premiumPlanViewInstantiationStatus === UnoViewStatus.Instantiated || premiumPlanViewInstantiationStatus === UnoViewStatus.InstantiationStarted || premiumPlanViewInstantiationStatus === UnoViewStatus.ScriptsLoaded || premiumPlanViewInstantiationStatus === UnoViewStatus.Error) {
            return;
        }
        const childView = UnoChildView.PremiumPlan;
        this.premiumPlanInstantiationStarted = true;
        const unoShellAc = this.actionCreatorProviders.unoShell();
        this.loggers.performanceMarker.mark(MarkerTag.ChildAppInstantiation, {
            viewName: UnoChildView.PremiumPlan,
            state: UnoViewStatus.InstantiationStarted
        });
        // Load scripts
        let premiumPlanInstantiator;
        try {
            premiumPlanInstantiator = await premiumPlanInstantiateLoader();
        } catch (e) {
            const err = new AssetLoadingError(e.name, e.message);
            this.loggers.traceLogger.logTrace(0x1e26300a /* tag_4j9ak */ , TraceLevel.Warning, `[ErrorType=${err.name}][Error=${err.message}]`);
            unoShellAc.updateChildViewInstantiationStatus(childView, UnoViewStatus.Error);
            this.scheduleRetryInstantiation();
            return;
        }
        // Instantiate premium plan
        try {
            if (isDevMode) {
                await premiumPlanInstantiator.premiumPlanDevViewInstantiator.instantiate(this.viewInstanceConfig);
            } else {
                await premiumPlanInstantiator.premiumPlanViewInstantiator.instantiate(this.viewInstanceConfig);
            }
            unoShellAc.updateChildViewInstantiationStatus(childView, UnoViewStatus.Instantiated);
            this.loggers.performanceMarker.mark(MarkerTag.ChildAppInstantiation, {
                viewName: UnoChildView.PremiumPlan,
                state: UnoViewStatus.Instantiated
            });
        } catch (e) {
            // Temporary logging to solve why we're getting empty errors here
            this.loggers.traceLogger.logTrace(0x1e263009 /* tag_4j9aj */ , TraceLevel.Warning, `InitializationError - ${generateLogStringFromError(e)}`);
            // Don't throw error, show view's error state to user
            unoShellAc.updateChildViewInstantiationStatus(childView, UnoViewStatus.Error);
            this.scheduleRetryInstantiation();
        }
    }
    /**
     * Schedules a retry for the view that failed to instantiate
     * Todo: Bug 9769212: Use retry util in ChildAppInstantiationWorker
     */ async scheduleRetryInstantiation() {
        const unoShellAc = this.actionCreatorProviders.unoShell();
        const instantiationSettings = this.configProvider().settings.instantiation;
        if (this.retryCount < instantiationSettings.maxRetryCount) {
            this.retryCount++;
            setTimeout(()=>{
                unoShellAc.updateChildViewInstantiationStatus(UnoChildView.PremiumPlan, UnoViewStatus.Instantiated);
            }, instantiationSettings.retryIntervalMs);
            this.loggers.traceLogger.logTrace(0x1e263008 /* tag_4j9ai */ , TraceLevel.Info, `InitializationError - Premium view failed to instantiate. Retrying instantiation. [RetryCount=${this.retryCount}]`);
        } else {
            this.loggers.traceLogger.logTrace(0x1e263007 /* tag_4j9ah */ , TraceLevel.Error, `InitializationError - Premium view failed to instantiate and won't retry. [RetryCount=${this.retryCount}]`);
        }
    }
    /**
     * Helper method to populate the view instance config
     */ async populateViewInstanceConfig() {
        const appContext = this.storeProviders.appContext().getAppContext();
        this.viewInstanceConfig = {
            actionCreatorProviders: this.actionCreatorProviders,
            context: appContext,
            dispatcherEventManagement: this.dispactherEventManagement,
            logicModuleProviders: this.logicModuleProviders,
            serviceProviders: this.serviceProviders,
            storeProviders: this.storeProviders,
            loggers: this.loggers,
            configProvider: this.configProvider,
            hostAdaptor: this.hostAdaptor
        };
    }
    /**
     * Constructor for the ChildAppInstantiationWorker
     * @param actionCreatorProviders The action creator providers
     * @param storeProviders The store providers
     * @param configProvider The config provider
     * @param loggers The loggers context
     * @param serviceProviders The service providers
     * @param logicModuleProviders The logic module providers
     * @param hostAdaptor The host adaptor
     * @param dispactherEventManagement The dispatcher event management
     */ constructor(actionCreatorProviders, storeProviders, configProvider, loggers, serviceProviders, logicModuleProviders, hostAdaptor, dispactherEventManagement){
        this.actionCreatorProviders = actionCreatorProviders;
        this.storeProviders = storeProviders;
        this.configProvider = configProvider;
        this.loggers = loggers;
        this.serviceProviders = serviceProviders;
        this.logicModuleProviders = logicModuleProviders;
        this.hostAdaptor = hostAdaptor;
        this.dispactherEventManagement = dispactherEventManagement;
        this.initialized = false;
        this.premiumPlanInstantiationStarted = false;
        this.retryCount = 0;
    }
}
