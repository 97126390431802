// Models
import { PlannerClientExtensions } from "./PlannerClientExtensions";
import { CopilotPlanExtensionsKeys, CopilotPlanExtensions } from "./CopilotPlanExtensions";
// Utilities
import { getDiff } from "@ms/uno-utilities/lib/local/ObjectUtilities";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";
import assign from "lodash/assign";
/**
 * Internal properties (extension fields) of a plan
 */ export class PlanInternalProperties {
    toGraphSerializable() {
        const result = {};
        if (this.appProperties.plannerclient) {
            try {
                result.plannerclient = JSON.stringify(this.appProperties.plannerclient.gridSettings);
            } catch (error) {
                throw new Error(`Failed to serialize plannerclient data: [Error=${error}]`);
            }
        }
        if (this.appProperties.copilot) {
            try {
                result.copilot = JSON.stringify(this.appProperties.copilot);
            } catch (error) {
                throw new Error(`Failed to serialize copilot data: [Error=${error}]`);
            }
        }
        return result;
    }
    static fromResource(resource) {
        const appProperties = {};
        if (resource.plannerclient) {
            try {
                const plannerData = JSON.parse(resource.plannerclient);
                if (plannerData) {
                    const gridSettings = {
                        gridColumns: []
                    };
                    assign(gridSettings, plannerData);
                    const plannerClientExtensions = new PlannerClientExtensions(gridSettings);
                    appProperties.plannerclient = plannerClientExtensions;
                }
            } catch (error) {
                throw new Error(`Failed to parse plannerclient data: [Error=${error}]`);
            }
        }
        if (resource.copilot) {
            try {
                const copilotData = JSON.parse(resource.copilot);
                if (copilotData) {
                    appProperties.copilot = new CopilotPlanExtensions(copilotData.loopWorkspace, copilotData.goals, copilotData.loopWorkspaceCreationInProgress);
                }
            } catch (error) {
                throw new Error(`Failed to parse copilot data: [Error=${error}]`);
            }
        }
        return new PlanInternalProperties(appProperties);
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (isEmpty(source)) {
                // By default, internalProperties is an empty object
                return target;
            }
            if (key === "gridColumns" && isArray(source) && isArray(target)) {
                if (!isEqual(source, target)) {
                    // gridColumns is an array, return the target as we don't want to return the diff of the arrays
                    return target;
                } else {
                    return {};
                }
            }
            if (CopilotPlanExtensionsKeys.has(key)) {
                if (!isEqual(source, target)) {
                    return target;
                } else {
                    return {};
                }
            }
            return getDiff(source, target, getDiffCustomizer);
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    applyDiffs(...diff) {
        const mergeCustomizer = (source, target, key)=>{
            if (key === "gridColumns" && isArray(source) && isArray(target)) {
                return target;
            }
            if (key === "copilot") {
                return target;
            }
        };
        if (diff.length > 0) {
            return mergeWith(this.getClone(), ...diff, mergeCustomizer);
        }
        return this;
    }
    getClone() {
        return new PlanInternalProperties(this.appProperties);
    }
    constructor(appProperties = {}){
        this.appProperties = appProperties;
    }
}
