// Authentication
import { createNestablePublicClientApplication } from "@azure/msal-browser-1p";
import { BaseMsalHelper } from "./BaseMsalHelper";
/**
 * Helper for authentication using msal.js with nested app auth
 * https://learn.microsoft.com/en-us/office/dev/add-ins/develop/enable-nested-app-authentication-in-your-add-in
 */ export class NestedAppAuthHelper extends BaseMsalHelper {
    async initialize(nestedAppAuthConfig) {
        await this.initializeMsal(nestedAppAuthConfig);
    }
    async createMsalClient(authConfig) {
        const authorityUrl = authConfig.tenantId ? this.getAuthorityUrl(authConfig.tenantId) : this.getAuthorityUrl("common");
        return await createNestablePublicClientApplication({
            // Configuration object - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
            auth: {
                authority: authorityUrl,
                clientId: authConfig.clientId,
                clientCapabilities: this.options.cp1ClientCapability ? [
                    "cp1"
                ] : undefined
            },
            system: {
                loggerOptions: this.createLoggerOptions(authConfig.correlationId)
            }
        });
    }
    /**
     * Handle the popup blocked error
     * @param scopes Scope for the resource
     * @param claimsChallenge claims challenge parameter
     */ async handlePopupBlockedError(scopes, claimsChallenge) {
        return;
    }
    constructor(nestedAppAuthConfig, loggers, options){
        super(nestedAppAuthConfig, loggers, options);
    }
}
