/**
 * Performance Marker tag enum for all the performance markers
 */ export var MarkerTag;
(function(MarkerTag) {
    // Very first tag to be marked once the app bootstrap gets called.
    MarkerTag["AppBootstrap"] = "AppBootstrap";
    // Marked when the Teams SDK is initialized. This is only applicable for Teams app.
    MarkerTag["InitializedTeamsSDK"] = "InitializedTeamsSDK";
    // Marked in the lifecycle of any smart component.
    MarkerTag["ViewRender"] = "ViewRender";
    // Marked when the app load is started from cache.
    MarkerTag["CachedLoadStart"] = "CachedLoadStart";
    // Marked when the app load completes from cache.
    MarkerTag["CachedLoadComplete"] = "CachedLoadComplete";
    // Marked when the view data fetch cycle meets the checkpoints.
    MarkerTag["ViewDataFetch"] = "ViewDataFetch";
    // Marked when the child app instantiation starts.
    MarkerTag["ChildAppInstantiation"] = "ChildAppInstantiation";
    // Marked in the DataPreFetcher flow.
    MarkerTag["DataPreFetcher"] = "DataPreFetcher";
    // Marked when the boot completes according to BootObserverWorker.
    MarkerTag["BootCompleted"] = "BootCompleted";
})(MarkerTag || (MarkerTag = {}));
