import { PlannerServiceLimitErrorTypes } from "./ErrorConstants";
export var ErrorTypeOption;
(function(ErrorTypeOption) {
    ErrorTypeOption[ErrorTypeOption["Info"] = 0] = "Info";
    ErrorTypeOption[ErrorTypeOption["Error"] = 1] = "Error";
    ErrorTypeOption[ErrorTypeOption["None"] = 2] = "None";
})(ErrorTypeOption || (ErrorTypeOption = {}));
export var NotificationId;
(function(NotificationId) {
    NotificationId[NotificationId["AddUsersToGroupError"] = 1] = "AddUsersToGroupError";
    NotificationId[NotificationId["BasicBucketsNotFoundInfo"] = 2] = "BasicBucketsNotFoundInfo";
    NotificationId[NotificationId["CancelFileUploadError"] = 3] = "CancelFileUploadError";
    NotificationId[NotificationId["CopyBasicPlanError"] = 4] = "CopyBasicPlanError";
    NotificationId[NotificationId["CreateBasicBucketError"] = 5] = "CreateBasicBucketError";
    NotificationId[NotificationId["CreateGroupError"] = 6] = "CreateGroupError";
    NotificationId[NotificationId["CreateBasicPlanError"] = 7] = "CreateBasicPlanError";
    NotificationId[NotificationId["CreateBasicTaskError"] = 8] = "CreateBasicTaskError";
    NotificationId[NotificationId["CreatePremiumPlanError"] = 9] = "CreatePremiumPlanError";
    NotificationId[NotificationId["DeleteBasicBucketError"] = 10] = "DeleteBasicBucketError";
    NotificationId[NotificationId["DeleteRecurringBasicTaskSeriesDeleteError"] = 11] = "DeleteRecurringBasicTaskSeriesDeleteError";
    NotificationId[NotificationId["DeleteRecurringBasicTaskSeriesUpdateError"] = 12] = "DeleteRecurringBasicTaskSeriesUpdateError";
    NotificationId[NotificationId["DeleteBasicTaskError"] = 13] = "DeleteBasicTaskError";
    NotificationId[NotificationId["DeleteBasicPlanError"] = 14] = "DeleteBasicPlanError";
    NotificationId[NotificationId["DeleteUnifiedGroupError"] = 15] = "DeleteUnifiedGroupError";
    NotificationId[NotificationId["DeleteRecentPlanError"] = 16] = "DeleteRecentPlanError";
    NotificationId[NotificationId["DiffSyncDisabledError"] = 17] = "DiffSyncDisabledError";
    NotificationId[NotificationId["ExportBasicPlanError"] = 18] = "ExportBasicPlanError";
    NotificationId[NotificationId["ExportBasicPlanBasicBucketFetchError"] = 19] = "ExportBasicPlanBasicBucketFetchError";
    NotificationId[NotificationId["ExportBasicPlanBasicPlanFetchError"] = 20] = "ExportBasicPlanBasicPlanFetchError";
    NotificationId[NotificationId["ExportBasicPlanBasicTaskFetchError"] = 21] = "ExportBasicPlanBasicTaskFetchError";
    NotificationId[NotificationId["ExportBasicPlanBasicTaskDetailsFetchError"] = 22] = "ExportBasicPlanBasicTaskDetailsFetchError";
    NotificationId[NotificationId["ExportBasicPlanUserFetchError"] = 23] = "ExportBasicPlanUserFetchError";
    NotificationId[NotificationId["FileReadError"] = 24] = "FileReadError";
    NotificationId[NotificationId["GetAggregateDataForBasicPlanError"] = 25] = "GetAggregateDataForBasicPlanError";
    NotificationId[NotificationId["GetBasicBucketsForBasicPlanError"] = 26] = "GetBasicBucketsForBasicPlanError";
    NotificationId[NotificationId["GetConversationThreadError"] = 27] = "GetConversationThreadError";
    NotificationId[NotificationId["GetConversationThreadPostError"] = 28] = "GetConversationThreadPostError";
    NotificationId[NotificationId["GetDocumentBasicPlansError"] = 29] = "GetDocumentBasicPlansError";
    NotificationId[NotificationId["GetGroupDocumentsError"] = 30] = "GetGroupDocumentsError";
    NotificationId[NotificationId["GetPlanMembersError"] = 31] = "GetPlanMembersError";
    NotificationId[NotificationId["GetJoinedGroupsError"] = 32] = "GetJoinedGroupsError";
    NotificationId[NotificationId["GetBasicPlanDetailsError"] = 33] = "GetBasicPlanDetailsError";
    NotificationId[NotificationId["GetBasicPlanError"] = 34] = "GetBasicPlanError";
    NotificationId[NotificationId["GetBasicPlansForUserError"] = 35] = "GetBasicPlansForUserError";
    NotificationId[NotificationId["GetBasicPlansForGroupError"] = 36] = "GetBasicPlansForGroupError";
    NotificationId[NotificationId["GetOrgInfoForPlanError"] = 37] = "GetOrgInfoForPlanError";
    NotificationId[NotificationId["GetPremiumPlansForUserError"] = 38] = "GetPremiumPlansForUserError";
    NotificationId[NotificationId["GetPremiumPlansForGroupError"] = 39] = "GetPremiumPlansForGroupError";
    NotificationId[NotificationId["GetTaskBoardTaskFormattingForBasicPlanError"] = 40] = "GetTaskBoardTaskFormattingForBasicPlanError";
    NotificationId[NotificationId["GetBasicTaskDetailsError"] = 41] = "GetBasicTaskDetailsError";
    NotificationId[NotificationId["GetBasicTasksForBasicPlanError"] = 42] = "GetBasicTasksForBasicPlanError";
    NotificationId[NotificationId["GetBasicTasksPageForBasicPlanError"] = 43] = "GetBasicTasksPageForBasicPlanError";
    NotificationId[NotificationId["GetTasksForUserError"] = 44] = "GetTasksForUserError";
    NotificationId[NotificationId["GetPlansForUserError"] = 45] = "GetPlansForUserError";
    NotificationId[NotificationId["GetRecentPlansForUserError"] = 46] = "GetRecentPlansForUserError";
    NotificationId[NotificationId["GetUnifiedGroupDetailsError"] = 47] = "GetUnifiedGroupDetailsError";
    NotificationId[NotificationId["GetUnifiedGroupError"] = 48] = "GetUnifiedGroupError";
    NotificationId[NotificationId["GoneOffline"] = 49] = "GoneOffline";
    NotificationId[NotificationId["JoinUnifiedGroupError"] = 50] = "JoinUnifiedGroupError";
    NotificationId[NotificationId["LeaveUnifiedGroupError"] = 51] = "LeaveUnifiedGroupError";
    NotificationId[NotificationId["MembersNotFoundInfo"] = 52] = "MembersNotFoundInfo";
    NotificationId[NotificationId["MoveBasicTaskError"] = 53] = "MoveBasicTaskError";
    NotificationId[NotificationId["NewBuildInfo"] = 54] = "NewBuildInfo";
    NotificationId[NotificationId["NonMemberInfo"] = 55] = "NonMemberInfo";
    NotificationId[NotificationId["PostGroupConversationThreadReplyError"] = 56] = "PostGroupConversationThreadReplyError";
    NotificationId[NotificationId["CreateGroupConversationThreadError"] = 57] = "CreateGroupConversationThreadError";
    NotificationId[NotificationId["RefreshAuthTokenError"] = 58] = "RefreshAuthTokenError";
    NotificationId[NotificationId["RefreshAuthTokenIEError"] = 59] = "RefreshAuthTokenIEError";
    NotificationId[NotificationId["RemoveUserFromGroupError"] = 60] = "RemoveUserFromGroupError";
    NotificationId[NotificationId["FetchUserSettingsError"] = 61] = "FetchUserSettingsError";
    NotificationId[NotificationId["UpdateUserSettingsError"] = 62] = "UpdateUserSettingsError";
    NotificationId[NotificationId["ResolveUsersError"] = 63] = "ResolveUsersError";
    NotificationId[NotificationId["RollbackBuildInfo"] = 64] = "RollbackBuildInfo";
    NotificationId[NotificationId["SetReadonlyModeInfo"] = 65] = "SetReadonlyModeInfo";
    NotificationId[NotificationId["BasicTaskNotFoundError"] = 66] = "BasicTaskNotFoundError";
    NotificationId[NotificationId["BasicTasksNotFoundInfo"] = 67] = "BasicTasksNotFoundInfo";
    NotificationId[NotificationId["UnknownBasicTaskNotFoundError"] = 68] = "UnknownBasicTaskNotFoundError";
    NotificationId[NotificationId["UnauthenticatedError"] = 69] = "UnauthenticatedError";
    NotificationId[NotificationId["UndeleteBasicTaskError"] = 70] = "UndeleteBasicTaskError";
    NotificationId[NotificationId["UnifiedGroupNotFoundInfo"] = 71] = "UnifiedGroupNotFoundInfo";
    NotificationId[NotificationId["UnopenableAttachmentLinkInfo"] = 72] = "UnopenableAttachmentLinkInfo";
    NotificationId[NotificationId["UpdateBasicBucketError"] = 73] = "UpdateBasicBucketError";
    NotificationId[NotificationId["UpdateDeletedBasicTaskInfo"] = 74] = "UpdateDeletedBasicTaskInfo";
    NotificationId[NotificationId["UpdateBasicTaskError"] = 75] = "UpdateBasicTaskError";
    NotificationId[NotificationId["UpdateUnifiedGroupError"] = 76] = "UpdateUnifiedGroupError";
    NotificationId[NotificationId["UpdateUnifiedGroupSubscriptionError"] = 77] = "UpdateUnifiedGroupSubscriptionError";
    NotificationId[NotificationId["UpdateUserError"] = 78] = "UpdateUserError";
    NotificationId[NotificationId["UpdateBasicPlanEntitySetError"] = 79] = "UpdateBasicPlanEntitySetError";
    NotificationId[NotificationId["UpdatePremiumPlanError"] = 80] = "UpdatePremiumPlanError";
    NotificationId[NotificationId["UpdatePortfolioError"] = 81] = "UpdatePortfolioError";
    NotificationId[NotificationId["UploadFileError"] = 82] = "UploadFileError";
    NotificationId[NotificationId["ConvertSuggestionToAttachmentError"] = 83] = "ConvertSuggestionToAttachmentError";
    NotificationId[NotificationId["UserInterationRequiredAuthError"] = 84] = "UserInterationRequiredAuthError";
    NotificationId[NotificationId["LoadingBasicPlanDataError"] = 85] = "LoadingBasicPlanDataError";
    NotificationId[NotificationId["PopupWindowError"] = 86] = "PopupWindowError";
    NotificationId[NotificationId["DiffSyncMaxBasicPlansSharedWithUserLimitError"] = 87] = "DiffSyncMaxBasicPlansSharedWithUserLimitError";
    NotificationId[NotificationId["SetRosterSensitivityLabelError"] = 88] = "SetRosterSensitivityLabelError";
    NotificationId[NotificationId["ShareDriveItemError"] = 89] = "ShareDriveItemError";
    NotificationId[NotificationId["MoveBasicPlanError"] = 90] = "MoveBasicPlanError";
    NotificationId[NotificationId["PinRecentPlanError"] = 91] = "PinRecentPlanError";
    NotificationId[NotificationId["UnpinRecentPlanError"] = 92] = "UnpinRecentPlanError";
    NotificationId[NotificationId["CreatePortfolioError"] = 93] = "CreatePortfolioError";
    NotificationId[NotificationId["GetMyTeamsPlansForUserError"] = 94] = "GetMyTeamsPlansForUserError";
    NotificationId[NotificationId["PlanConvertingInfo"] = 95] = "PlanConvertingInfo";
    NotificationId[NotificationId["GetRecentItemsForUserError"] = 96] = "GetRecentItemsForUserError";
    NotificationId[NotificationId["DeleteRecentPortfolioError"] = 97] = "DeleteRecentPortfolioError";
    NotificationId[NotificationId["PinRecentPortfolioError"] = 98] = "PinRecentPortfolioError";
    NotificationId[NotificationId["UnpinRecentPortfolioError"] = 99] = "UnpinRecentPortfolioError";
    NotificationId[NotificationId["PortfoliosFetchFailureError"] = 100] = "PortfoliosFetchFailureError";
    NotificationId[NotificationId["DeleteProjectHomeResourceError"] = 101] = "DeleteProjectHomeResourceError";
    NotificationId[NotificationId["AddProjectHomeResourceError"] = 102] = "AddProjectHomeResourceError";
    NotificationId[NotificationId["FetchResourceItemsFromLoopError"] = 103] = "FetchResourceItemsFromLoopError";
    NotificationId[NotificationId["FetchResourceItemsForPickerError"] = 104] = "FetchResourceItemsForPickerError";
    NotificationId[NotificationId["DeletePortfolioError"] = 105] = "DeletePortfolioError";
    NotificationId[NotificationId["CdsProvisioningPollingTimeout"] = 106] = "CdsProvisioningPollingTimeout";
    NotificationId[NotificationId["GetPremiumPlansCreatedByUserError"] = 107] = "GetPremiumPlansCreatedByUserError";
    NotificationId[NotificationId["SharingWithMGSucceededButFailedToAddResourceToLoopWorkspaceError"] = 108] = "SharingWithMGSucceededButFailedToAddResourceToLoopWorkspaceError";
    NotificationId[NotificationId["FailedToAddResourceToPlanError"] = 109] = "FailedToAddResourceToPlanError";
    NotificationId[NotificationId["LinkPlanToTabError"] = 110] = "LinkPlanToTabError";
    NotificationId[NotificationId["PremiumPlanCreationStatusPollingTimeout"] = 111] = "PremiumPlanCreationStatusPollingTimeout";
    NotificationId[NotificationId["CreateCopilotPlanError"] = 112] = "CreateCopilotPlanError";
    NotificationId[NotificationId["MaximumProjectsOwnedByUserError"] = 113] = "MaximumProjectsOwnedByUserError";
    NotificationId[NotificationId["MaximumProjectsSharedWithUserError"] = 114] = "MaximumProjectsSharedWithUserError";
    NotificationId[NotificationId["MaximumTasksCreatedByUserError"] = 115] = "MaximumTasksCreatedByUserError";
    NotificationId[NotificationId["MaximumTasksAssignedToUserError"] = 116] = "MaximumTasksAssignedToUserError";
    NotificationId[NotificationId["MaximumTasksInProjectError"] = 117] = "MaximumTasksInProjectError";
    NotificationId[NotificationId["MaximumActiveTasksInProjectError"] = 118] = "MaximumActiveTasksInProjectError";
    NotificationId[NotificationId["MaximumBucketsInProjectError"] = 119] = "MaximumBucketsInProjectError";
    NotificationId[NotificationId["MaximumUsersSharedWithProjectError"] = 120] = "MaximumUsersSharedWithProjectError";
    NotificationId[NotificationId["MaximumReferencesOnTaskError"] = 121] = "MaximumReferencesOnTaskError";
    NotificationId[NotificationId["MaximumChecklistItemsOnTaskError"] = 122] = "MaximumChecklistItemsOnTaskError";
    NotificationId[NotificationId["MaximumAssigneesInTasksError"] = 123] = "MaximumAssigneesInTasksError";
    NotificationId[NotificationId["MaximumFavoritePlansForUserError"] = 124] = "MaximumFavoritePlansForUserError";
    NotificationId[NotificationId["MaximumRecentPlansForUserError"] = 125] = "MaximumRecentPlansForUserError";
    NotificationId[NotificationId["MaximumContextsOnPlanError"] = 126] = "MaximumContextsOnPlanError";
    NotificationId[NotificationId["UpdateBasicTasksError"] = 127] = "UpdateBasicTasksError";
})(NotificationId || (NotificationId = {}));
export const limitTypeErrorToNotificationId = {
    [PlannerServiceLimitErrorTypes.MaximumProjectsOwnedByUser]: 113,
    [PlannerServiceLimitErrorTypes.MaximumProjectsSharedWithUser]: 114,
    [PlannerServiceLimitErrorTypes.MaximumTasksCreatedByUser]: 115,
    [PlannerServiceLimitErrorTypes.MaximumTasksAssignedToUser]: 116,
    [PlannerServiceLimitErrorTypes.MaximumTasksInProject]: 117,
    [PlannerServiceLimitErrorTypes.MaximumActiveTasksInProject]: 118,
    [PlannerServiceLimitErrorTypes.MaximumBucketsInProject]: 119,
    [PlannerServiceLimitErrorTypes.MaximumUsersSharedWithProject]: 120,
    [PlannerServiceLimitErrorTypes.MaximumReferencesOnTask]: 121,
    [PlannerServiceLimitErrorTypes.MaximumChecklistItemsOnTask]: 122,
    [PlannerServiceLimitErrorTypes.MaximumAssigneesInTasks]: 123,
    [PlannerServiceLimitErrorTypes.MaximumFavoritePlansForUser]: 124,
    [PlannerServiceLimitErrorTypes.MaximumRecentPlansForUser]: 125,
    [PlannerServiceLimitErrorTypes.MaximumContextsOnPlan]: 126
};
export var NotificationType;
(function(NotificationType) {
    NotificationType["None"] = "None";
    NotificationType["Comment"] = "Comment";
    NotificationType["TaskLink"] = "TaskLink";
    NotificationType["PlanLink"] = "PlanLink";
})(NotificationType || (NotificationType = {}));
export var NotificationChannel;
(function(NotificationChannel) {
    NotificationChannel["None"] = "None";
    NotificationChannel["GroupMailbox"] = "GroupMailbox";
    NotificationChannel["Link"] = "Link";
})(NotificationChannel || (NotificationChannel = {}));
export var PlannerNewsId;
(function(PlannerNewsId) {
    PlannerNewsId[PlannerNewsId["TasksAppExperienceUpcoming"] = 0] = "TasksAppExperienceUpcoming";
})(PlannerNewsId || (PlannerNewsId = {}));
export var ToastLocation;
(function(ToastLocation) {
    ToastLocation[ToastLocation["TopLeft"] = 0] = "TopLeft";
    ToastLocation[ToastLocation["TopRight"] = 1] = "TopRight";
    ToastLocation[ToastLocation["BottomLeft"] = 2] = "BottomLeft";
    ToastLocation[ToastLocation["BottomRight"] = 3] = "BottomRight";
})(ToastLocation || (ToastLocation = {}));
/** The default toast length in ms */ export const DefaultToastTimeout = 10000;
/** The default number of toasts to display at once */ export const DefaultMaxDisplayedToasts = 8;
export var ToastType;
(function(ToastType) {
    ToastType["BasicPlanConversionToast"] = "BASIC_PLAN_CONVERSION_TOAST";
    ToastType["CopyLinkToast"] = "COPY_LINK_TOAST";
    ToastType["CopyPlanToast"] = "COPY_PLAN_TOAST";
    ToastType["CopyTaskToast"] = "COPY_TASK_TOAST";
    ToastType["ExportPlanToast"] = "EXPORT_PLAN_TOAST";
    ToastType["HiddenToast"] = "HIDDEN_TOAST";
    ToastType["MoveTaskToast"] = "MOVE_TASK_TOAST";
    ToastType["NotificationToast"] = "NOTIFICATION_TOAST";
    ToastType["DeleteTaskToast"] = "DELETE_TASK_TOAST";
    ToastType["RestoreTaskSuccessToast"] = "RESTORE_TASK_SUCCESS_TOAST";
    ToastType["StatusReportToast"] = "STATUS_REPORT_TOAST";
})(ToastType || (ToastType = {}));
/** The default toast type allow list */ export const DefaultToastsAllowed = [
    "BASIC_PLAN_CONVERSION_TOAST",
    "COPY_LINK_TOAST",
    "COPY_PLAN_TOAST",
    "COPY_TASK_TOAST",
    "EXPORT_PLAN_TOAST",
    "HIDDEN_TOAST",
    "MOVE_TASK_TOAST",
    "NOTIFICATION_TOAST",
    "DELETE_TASK_TOAST",
    "RESTORE_TASK_SUCCESS_TOAST",
    "STATUS_REPORT_TOAST"
];
/** Alert user decounce interval */ export const AlertUserDebounceInterval = 500;
