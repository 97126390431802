// Constants
import { StoreTableNames } from "@ms/uno-constants/lib/local/StoreConstants";
import { PersistedObjectStoreIndexNames, PersistedObjectStoreKeys } from "@ms/uno-constants/lib/local/PersistenceConstants";
/** indexes for mru data */ const mruIndexes = [
    {
        name: PersistedObjectStoreIndexNames.MruPinnedIndex,
        isUnique: false,
        isMultiEntry: false
    }
];
/** indexes for buckets data */ const basicBucketIndexes = [
    {
        name: PersistedObjectStoreIndexNames.BucketsPlanIndex,
        isUnique: false,
        isMultiEntry: false
    }
];
/** indexes for task store */ const taskIndexes = [
    {
        name: PersistedObjectStoreIndexNames.TasksPlanIndex,
        isUnique: false,
        isMultiEntry: false
    },
    {
        name: PersistedObjectStoreIndexNames.TasksMyDayDateIndex,
        isUnique: false,
        isMultiEntry: false
    },
    {
        name: PersistedObjectStoreIndexNames.TasksAssignmentsIndex,
        isUnique: false,
        isMultiEntry: true
    }
];
/** mru object store schema */ const mruStoreSchema = {
    /** name of the object store */ name: StoreTableNames.Mru,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Mru,
    /** object store indexes */ indexes: mruIndexes
};
/** mru collection status schema */ const mruCollectionStatusStoreSchema = {
    /** name of the object store */ name: StoreTableNames.MruCollectionStatus,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** plans object store schema */ const planStoreSchema = {
    /** name of the object store */ name: StoreTableNames.Plans,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Plans,
    /** object store indexes */ indexes: []
};
/** plan Details object store schema */ const planDetailsStoreSchema = {
    /** name of the object store */ name: StoreTableNames.PlanDetails,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Plans,
    /** object store indexes */ indexes: []
};
/** plan status schema */ const planStatusSchema = {
    /** name of the object store */ name: StoreTableNames.PlanStatus,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** Buckets objects store schema */ const bucketStoreSchema = {
    /** name of the object store */ name: StoreTableNames.Buckets,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Buckets,
    /** object store indexes */ indexes: basicBucketIndexes
};
/** buckets in plan status schema */ const bucketsInPlanStatusSchema = {
    /** name of the object store */ name: StoreTableNames.BucketsInPlanStatus,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** tasks object store schema */ const taskStoreSchema = {
    /** name of the object store */ name: StoreTableNames.Tasks,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Tasks,
    /** object store indexes */ indexes: taskIndexes
};
/** task details object store schema */ const taskDetailsStoreSchema = {
    /** name of the object store */ name: StoreTableNames.TaskDetails,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Tasks,
    /** object store indexes */ indexes: []
};
/** TaskboardTaskFormatForAssignedTo object store schema */ const taskboardTaskFormatForAssignedToStoreSchema = {
    /** name of the object store */ name: StoreTableNames.TaskboardTaskFormatForAssignedTo,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Tasks,
    /** object store indexes */ indexes: []
};
/** TaskboardTaskFormatForBucket object store schema */ const taskboardTaskFormatForBucketStoreSchema = {
    /** name of the object store */ name: StoreTableNames.TaskboardTaskFormatForBucket,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Tasks,
    /** object store indexes */ indexes: []
};
/** TaskboardTaskFormatForProgress object store schema */ const taskboardTaskFormatForProgressStoreSchema = {
    /** name of the object store */ name: StoreTableNames.TaskboardTaskFormatForProgress,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Tasks,
    /** object store indexes */ indexes: []
};
/** tasks in plan status schema */ const tasksInPlanStatusSchema = {
    /** name of the object store */ name: StoreTableNames.TasksInPlanStatus,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** tasks by assignee status schema */ const tasksByAssigneeStatusSchema = {
    /** name of the object store */ name: StoreTableNames.TasksByAssigneeStatus,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** tasks in my day status schema */ const tasksInMyDayStatusSchema = {
    /** name of the object store */ name: StoreTableNames.TasksInMyDayStatusTable,
    /** primary key */ keyPath: PersistedObjectStoreKeys.Status,
    /** object store indexes */ indexes: []
};
/** Planner browser cache async storage object store schema - V1 - mru items & collection status*/ export const PersistedObjectStoreSchemaV1 = [
    mruStoreSchema,
    mruCollectionStatusStoreSchema
];
/** Planner browser cache async storage object store schema - V2 */ export const PersistedObjectStoreSchemaV2 = [
    ...PersistedObjectStoreSchemaV1,
    planStoreSchema,
    planDetailsStoreSchema,
    planStatusSchema,
    taskStoreSchema,
    taskDetailsStoreSchema,
    taskboardTaskFormatForAssignedToStoreSchema,
    taskboardTaskFormatForBucketStoreSchema,
    taskboardTaskFormatForProgressStoreSchema,
    tasksInPlanStatusSchema,
    tasksByAssigneeStatusSchema,
    tasksInMyDayStatusSchema,
    bucketsInPlanStatusSchema,
    bucketStoreSchema
];
