import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { Mode } from "./IndexedDBConstants";
/** IndexedDB implementation */ export class IndexedDBStorage {
    /** ensures storage DB is initialized and open*/ async getDB() {
        if (this.database) {
            return this.database;
        }
        return this.initDB();
    }
    /** Method to initialize the storage
     * To be used to run the setup steps for the indexedDB storage
     */ async initDB() {
        if (this.dbOpenPromise) {
            return this.dbOpenPromise;
        }
        this.dbOpenPromise = new Promise((resolve, reject)=>{
            const request = indexedDB.open(this.name, this.version);
            request.onupgradeneeded = ()=>{
                this.traceLogger.logTrace(0x1e28b0cb /* tag_4kldl */ , TraceLevel.Verbose, `initStorageDBOnupgradeneeded [Db=${this.name}]`);
                const db = request.result;
                this.manageObjectStores(db);
            };
            request.onsuccess = ()=>{
                this.database = request.result;
                this.traceLogger.logTrace(0x1e28b0ca /* tag_4kldk */ , TraceLevel.Verbose, `initStorageDBOpened [Db=${this.name}]`);
                this.database.onversionchange = ()=>{
                    this.traceLogger.logTrace(0x1e28b0c9 /* tag_4kldj */ , TraceLevel.Info, `initStorageDBOnversionchange [Db=${this.name}]`);
                    this.database?.close();
                    location.reload();
                };
                resolve(this.database);
                this.dbOpenPromise = null;
            };
            request.onerror = ()=>{
                reject(new Error(`initStorageDBOpenFailure [Db=${this.name}][Error=${request.error}]`));
                this.dbOpenPromise = null;
            };
            // The onblocked event is triggered when there is an attempt to open a database that is already open in another tab or process,
            // and the version change cannot proceed until the other connection is closed
            request.onblocked = ()=>{
                this.traceLogger.logTrace(0x1e24d254 /* tag_4jnju */ , TraceLevel.Warning, `Database upgrade blocked [Db=${this.name}]`);
            };
        });
        return this.dbOpenPromise;
    }
    /** Method to manage object stores for the storage DB
     * creates any new object stores which do not exist already , as per schema specification on initial creation and db version upgrade
     * Also creates the specified indexes for the newly created object stores
     * Also manages deletes for the object stores which are not present in the current schema on db version upgrade
     * @param db database
     */ manageObjectStores(db) {
        // manage creation of object stores which do not exist already
        this.objectStoreSchema.forEach((store)=>{
            if (!db.objectStoreNames.contains(store.name)) {
                const objectStore = db.createObjectStore(store.name, {
                    keyPath: store.keyPath
                });
                if (objectStore) {
                    store.indexes.forEach((index)=>{
                        objectStore.createIndex(index.name, index.name, {
                            unique: index.isUnique,
                            multiEntry: index.isMultiEntry
                        });
                    });
                    this.traceLogger.logTrace(0x1e28b0c7 /* tag_4kldh */ , TraceLevel.Verbose, `initStorageObjectStoreCreated [Db=${this.name}][store=${store.name}]`);
                }
            }
        });
        // Delete object stores not present in the current schema
        for (const storeName of db.objectStoreNames){
            if (!this.objectStoreSchema.some((store)=>store.name === storeName)) {
                db.deleteObjectStore(storeName);
            }
        }
    }
    /** Checks if indexedDB is supported on the browser */ static isIndexedDBSupported() {
        return "indexedDB" in window;
    }
    async read(objectStore, key) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const request = store.get(key);
                request.onsuccess = ()=>{
                    const result = request.result;
                    resolve(result ? result : undefined);
                };
                request.onerror = ()=>{
                    reject(new Error(`DBReadFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`));
                };
            } catch (error) {
                reject(new Error(`ReadFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`));
            }
        });
    }
    async readAll(objectStore) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const keyPath = store.keyPath;
                const request = store.getAll();
                request.onsuccess = ()=>{
                    const formattedResults = {};
                    request.result.forEach((item)=>{
                        formattedResults[item[keyPath]] = item;
                    });
                    resolve(formattedResults);
                };
                request.onerror = ()=>{
                    reject(new Error(`DBReadAllFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`));
                };
            } catch (error) {
                reject(new Error(`ReadAllFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`));
            }
        });
    }
    async readByIndex(objectStore, indexName, indexValue) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadOnly);
                const store = transaction.objectStore(objectStore);
                const keyPath = store.keyPath;
                const index = store.index(indexName);
                const request = index.getAll(indexValue);
                request.onsuccess = ()=>{
                    const formattedResults = {};
                    request.result.forEach((item)=>{
                        formattedResults[item[keyPath]] = item;
                    });
                    resolve(formattedResults);
                };
                request.onerror = ()=>{
                    reject(new Error(`DBReadByIndexFailed [Db=${this.name}][store=${objectStore}][index=${indexName}][Error=${request.error}]`));
                };
            } catch (error) {
                reject(new Error(`ReadByIndexFailed [Db=${this.name}][objectStore=${objectStore}][Error=${error}]`));
            }
        });
    }
    async batchRead(operations) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const storeNames = Object.keys(operations);
                if (storeNames.length === 0) {
                    resolve({});
                    return;
                }
                const transaction = db.transaction(storeNames, Mode.ReadOnly);
                const results = {};
                storeNames.forEach((storeName)=>{
                    const store = transaction.objectStore(storeName);
                    results[storeName] = {};
                    operations[storeName].forEach((key)=>{
                        const request = store.get(key);
                        request.onsuccess = ()=>{
                            if (request.result) {
                                results[storeName][key] = request.result;
                            }
                        };
                        request.onerror = ()=>{
                            reject(new Error(`DBBatchReadFailed [Db=${this.name}][store=${storeName}][Error=${request.error}]`));
                        };
                    });
                });
                transaction.oncomplete = ()=>{
                    this.traceLogger.logTrace(0x1e249287 /* tag_4jjkh */ , TraceLevel.Info, "batchReadSuccess");
                    resolve(results);
                };
                transaction.onerror = ()=>{
                    reject(transaction.error);
                };
            } catch (error) {
                reject(new Error(`BatchReadFailed [Db=${this.name}][Error=${error}]`));
            }
        });
    }
    async write(objectStore, items) {
        const operationRecord = {};
        operationRecord[objectStore] = {
            itemsToWrite: items,
            keysToPurge: []
        };
        return this.performBatchOperation(operationRecord);
    }
    async purge(objectStore, keys) {
        const operationRecord = {};
        operationRecord[objectStore] = {
            itemsToWrite: [],
            keysToPurge: keys
        };
        return this.performBatchOperation(operationRecord);
    }
    async performBatchOperation(operations) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const storeNames = Object.keys(operations);
                const transaction = db.transaction(storeNames, Mode.ReadWrite);
                storeNames.forEach((storeName)=>{
                    const store = transaction.objectStore(storeName);
                    const { itemsToWrite, keysToPurge } = operations[storeName];
                    itemsToWrite.forEach((item)=>{
                        const request = store.put(item);
                        request.onerror = ()=>{
                            reject(new Error(`performBatchOperationWriteError [Db=${this.name}][Error=${request.error}]`));
                        };
                    });
                    keysToPurge.forEach((key)=>{
                        const request = store.delete(key);
                        request.onerror = ()=>{
                            reject(new Error(`performBatchOperationPurgeError [Db=${this.name}][Error=${request.error}]`));
                        };
                    });
                });
                transaction.oncomplete = ()=>{
                    this.traceLogger.logTrace(0x1e255684 /* tag_4jv0e */ , TraceLevel.Info, "performBatchOperationSuccess");
                    resolve();
                };
                transaction.onerror = ()=>{
                    reject(transaction.error);
                };
            } catch (error) {
                reject(new Error(`performBatchOperationsFailed [Db=${this.name}][Error=${error}]`));
            }
        });
    }
    async clearObjectStore(objectStore) {
        const db = await this.getDB();
        return new Promise((resolve, reject)=>{
            try {
                const transaction = db.transaction(objectStore, Mode.ReadWrite);
                const store = transaction.objectStore(objectStore);
                const request = store.clear();
                request.onsuccess = ()=>{
                    this.traceLogger.logTrace(0x1e28a023 /* tag_4kka9 */ , TraceLevel.Info, `DBObjectStoreCleared [Db=${this.name}][store=${objectStore}]`);
                    resolve();
                };
                request.onerror = ()=>{
                    reject(new Error(`DBObjectStoreClearFailed [Db=${this.name}][store=${objectStore}][Error=${request.error}]`));
                };
            } catch (error) {
                reject(new Error(`DBObjectStoreClearError [Db=${this.name}][store=${objectStore}][Error=${error}]`));
            }
        });
    }
    async clearStorage() {
        return new Promise((resolve, reject)=>{
            const request = indexedDB.deleteDatabase(this.name);
            request.onsuccess = ()=>{
                this.database = null;
                this.traceLogger.logTrace(0x1e28a01f /* tag_4kka5 */ , TraceLevel.Info, `DBDeleted [Db=${this.name}]`);
                resolve();
            };
            request.onerror = ()=>{
                reject(new Error(`DBClearFailed [Db=${this.name}][Error=${request.error}]`));
            };
            request.onblocked = ()=>{
                // Triggered if the deletion is blocked, for eg, if there are open connections to the database
                reject(new Error(`DBDeletionBlocked [Db=${this.name}]`));
            };
        });
    }
    constructor(userId, databaseNamePrefix, version, objectStoreSchema, traceLogger){
        /** indexedDB instance */ this.database = null;
        /** IDBDatabase open promise */ this.dbOpenPromise = null;
        this.name = `${databaseNamePrefix}_${userId}`;
        this.version = version;
        this.objectStoreSchema = objectStoreSchema;
        this.traceLogger = traceLogger;
        // Starting the initialization of the storage in the constructor itself to minimize the delay before DB is ready for operations
        // Promise is handled separately below to ensure the initialization completes before accessing the DB
        this.initDB();
    }
}
