import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for PremiumPlanStore, for use in the registry */ export const PremiumPlanStoreActionTypes = [
    ActionType.CreatePremiumPlanAction,
    ActionType.CreatePremiumPlanFailedAction,
    ActionType.CreatePremiumPlanSucceededAction,
    ActionType.FetchPremiumPlanItemsSharedWithUserAction,
    ActionType.FetchPremiumPlanItemsSharedWithUserFailedAction,
    ActionType.FetchPremiumPlanItemsSharedWithUserSucceededAction,
    ActionType.FetchPremiumPlanItemsCreatedByUserAction,
    ActionType.FetchPremiumPlanItemsCreatedByUserFailedAction,
    ActionType.FetchPremiumPlanItemsCreatedByUserSucceededAction,
    ActionType.FetchPremiumPlanItemsSharedWithGroupAction,
    ActionType.FetchPremiumPlanItemsSharedWithGroupFailedAction,
    ActionType.FetchPremiumPlanItemsSharedWithGroupSucceededAction,
    ActionType.OpenPremiumPlanAction,
    ActionType.OpenPremiumPlanFailedAction,
    ActionType.OpenPremiumPlanSucceededAction,
    ActionType.UpdatePremiumPlanAction,
    ActionType.UpdatePremiumPlanFailedAction,
    ActionType.UpdatePremiumPlanSucceededAction,
    ActionType.EnqueuePostProvisioningPlanCreationAction,
    ActionType.EnqueuePostProvisioningGroupPlanFetchAction,
    ActionType.EnqueuePostProvisioningUserCreatedPlanFetchAction,
    ActionType.EnqueuePostProvisioningUserSharedPlanFetchAction,
    ActionType.FetchMruItemsSucceeded,
    ActionType.PremiumPlanOptimisticProcessingCompleteAction
];
