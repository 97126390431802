import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for OperationTrackingStore, for use in the registry */ export const OperationTrackingStoreActionTypes = [
    ActionType.FetchRoamingUserDataAction,
    ActionType.FetchRoamingUserDataFailedAction,
    ActionType.FetchRoamingUserDataSucceededAction,
    ActionType.UpdateRoamingUserDataSucceededAction,
    ActionType.FetchCopyPlanOperationDescriptorAction,
    ActionType.FetchCopyPlanOperationDescriptorFailedAction,
    ActionType.FetchCopyPlanOperationDescriptorSucceededAction,
    ActionType.MarkBeginProcessingOperations,
    ActionType.MarkEndProcessingOperations,
    ActionType.FetchOperationStatusAction,
    ActionType.FetchOperationStatusFailedAction,
    ActionType.FetchOperationStatusSucceededAction
];
