import { InteractionRequiredAuthError, BrowserAuthError, BrowserAuthErrorCodes } from "@azure/msal-browser-1p";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { AzureCloudInstance, LogLevel } from "@azure/msal-common-uno";
import { Environment } from "@ms/uno-constants/lib/local/AppConstants";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/**
 * Base class for MsalHelper
 */ export class BaseMsalHelper {
    /**
     * Initialize the msal client
     * @param msalConfig Config for setup
     */ async initializeMsal(msalConfig) {
        try {
            this.msalPca = await this.createMsalClient(msalConfig);
        } catch (error) {
            this.loggers.traceLogger.logTrace(0x1e28f653 /* tag_4kpzt */ , TraceLevel.Error, this.constructErrorMessage({
                error,
                hasClaims: false
            }));
            throw error;
        }
    }
    /**
     * Gets the msal public client application
     */ get msalObject() {
        if (this.msalPca == null) {
            throw new Error("No Msal Public Client Application.");
        }
        return this.msalPca;
    }
    /**
     * Method to get the authority URL based on the tenant ID
     * @param tenantId Tenant ID to get the authority URL
     * @returns Authority URL
     */ getAuthorityUrl(tenantId) {
        if (tenantId && this.aadAuthorityHost !== AzureCloudInstance.None) {
            return `${this.aadAuthorityHost}/${tenantId}`;
        }
        return undefined;
    }
    /**
     * Gets the authority host based on the environment
     * @param environment Environment
     * @param loggers Loggers
     * @returns AzureCloudInstance authority host
     */ getAuthorityHost(environment, loggers) {
        switch(environment){
            case Environment.Dod:
            case Environment.Gcch:
            case Environment.Agc:
                return AzureCloudInstance.AzureUsGovernment;
            case Environment.Ppe:
            case Environment.Prod:
            case Environment.Gcc:
                return AzureCloudInstance.AzurePublic;
            default:
                ErrorUtilities.unreachableCase(environment, (log)=>{
                    loggers.traceLogger.logTrace(0x1e28f652 /* tag_4kpzs */ , TraceLevel.Warning, log);
                });
                return AzureCloudInstance.None;
        }
    }
    /**
     * Decode the claims challenge parameter if it is base64 encoded
     * The documentation for CAE says that the claims challenge parameter should be base64 encoded
     * We need to decode it before passing it to the acquireTokenSilent method
     * https://learn.microsoft.com/en-us/entra/identity-platform/app-resilience-continuous-access-evaluation?tabs=JavaScript
     * https://learn.microsoft.com/en-us/entra/identity-platform/claims-challenge?tabs=dotnet#claims-request
     * @param claimsChallenge Claims challenge parameter
     */ getClaimsParameter(claimsChallenge) {
        if (!claimsChallenge) {
            return undefined;
        }
        try {
            JSON.parse(claimsChallenge);
            this.loggers.traceLogger.logTrace(0x1e28f651 /* tag_4kpzr */ , TraceLevel.Info, "Claims challenge parameter is not base64 encoded.");
            return claimsChallenge;
        } catch  {
            this.loggers.traceLogger.logTrace(0x1e28f650 /* tag_4kpzq */ , TraceLevel.Info, "Claims challenge parameter is base64 encoded.");
            // The documentation for CAE says that the claims challenge parameter should be base64 encoded
            // We need to decode it before passing it to the acquireTokenSilent method
            return window.atob(claimsChallenge);
        }
    }
    /**
     * Error message to log
     * @param error Auth error thrown by msal
     * @param hasClaims If the request has claims
     * @param scopes scopes set for the request (won't be set for login request)
     */ constructErrorMessage(params) {
        const { error, scopes, hasClaims } = params;
        const errorHasClaims = !!error?.claims;
        return `Msal error [scopes=${scopes?.join(",") ?? ""}][errorCode=${error?.errorCode}][subError=${error?.subError}][errorName=${error?.name ?? ""}][correlationId=${error?.correlationId ?? ""}][hasClaims=${hasClaims}][errorHasClaims=${errorHasClaims}][message=${ErrorUtilities.getMessage(error)}] `;
    }
    /**
     * Create logger options for msal log callback
     * @param correlationId The correlationId for MATS
     * @returns LoggerOptions object
     */ createLoggerOptions(correlationId) {
        return {
            logLevel: LogLevel.Verbose,
            loggerCallback: (...args)=>this.msalLoggerCallback(...args, this.loggers),
            piiLoggingEnabled: false,
            correlationId
        };
    }
    /**
     *  Callback for MSAL logger
     * @param level MSAL log level
     * @param message Log message
     * @param containsPii True if contains pii
     * @param traceLogger The trace logger
     */ msalLoggerCallback(level, message, containsPii, loggers) {
        if (containsPii) {
            return;
        }
        // Popup errors are unavoidable because browsers block popups by default, so set them as warnings.
        const shouldConvertErrorToWarning = ()=>message.indexOf(BrowserAuthErrorCodes.emptyWindowError) >= 0;
        // Downlevel "Info" level logs from MSAL to Verbose in order to reduce log volume. These logs are not highly useful for general logging.
        // For debugging purposes, you can review these logs via lowering a specific tenant's trace log level threshold to verbose.
        let clientLogLevel;
        switch(level){
            case LogLevel.Trace:
            case LogLevel.Verbose:
            case LogLevel.Info:
                clientLogLevel = TraceLevel.Verbose;
                break;
            case LogLevel.Error:
                clientLogLevel = shouldConvertErrorToWarning() ? TraceLevel.Warning : TraceLevel.Error;
                break;
            case LogLevel.Warning:
                clientLogLevel = TraceLevel.Warning;
                break;
            default:
                ErrorUtilities.unreachableCase(level, (log)=>{
                    loggers.traceLogger.logTrace(0x1e28f64f /* tag_4kpzp */ , TraceLevel.Warning, log);
                });
                // Set default level to "Warning" in order to let us know if we are missing a case
                clientLogLevel = TraceLevel.Warning;
        }
        loggers.traceLogger.logTrace(0x1e28f64e /* tag_4kpzo */ , clientLogLevel, `[MSAL]: ${message}`);
    }
    /**
     * Acquire the token for a set of scopes and return it via promise
     * @param scopes Scopes for the endpoint
     * @param [claimsChallenge] Claims for getting the token in base64 encoded string
     * @param [silent] If true, the token will be acquired "silently" (without any user interaction like pop ups)
     * @returns Promise with access token
     */ async getAccessToken(scopes, claimsChallenge, silent) {
        const account = this.msalPca.getActiveAccount();
        if (!account) {
            this.loggers.traceLogger.logTrace(0x1e28f260 /* tag_4kpj6 */ , TraceLevel.Warning, "There is no account to get the token. Please ensure the user is logged in.");
        // Don't throw an error here as msal will throw the error below.
        // We have an event listened to handle the msal error.
        }
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const request = {
            account: account ?? undefined,
            scopes,
            claims,
            authority,
            redirectUri: this.silentRedirectUri
        };
        try {
            const response = await this.msalPca.acquireTokenSilent(request);
            return {
                accessToken: response.accessToken,
                expiry: response.expiresOn
            };
        } catch (acquireTokenSilentError) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e28f25f /* tag_4kpj5 */ , TraceLevel.Warning, this.constructErrorMessage({
                error: acquireTokenSilentError,
                hasClaims,
                scopes
            }));
            if (acquireTokenSilentError instanceof InteractionRequiredAuthError && this.options.handleInteractionRequiredOnSilentLogin) {
                if (!silent) {
                    return await this.acquireTokenPopup(scopes, claims);
                } else {
                    this.loggers.traceLogger.logTrace(0x1e259846 /* tag_4jz7g */ , TraceLevel.Info, "Popup auth skipped for silent request");
                }
            }
            return Promise.reject(acquireTokenSilentError);
        }
    }
    /**
     * Acquire the token for the set of specified scopes and return it via promise
     * @param scopes scopes for the endpoint
     * @param [claims] Claims for getting the token
     * @returns Promise with access token
     */ async acquireTokenPopup(scopes, claims) {
        return await this.acquireTokenPopupHelper(scopes, this.handlePopupBlockedError.bind(this), claims);
    }
    /**
     * Acquire the token for the set of specified scopes and return it via promise
     * @param scopes scopes for the endpoint
     * @param [claims] Claims for getting the token
     * @param popupBlockedErrorHandler Callback for handling popup blocked error
     * @returns Promise with access token
     */ async acquireTokenPopupHelper(scopes, popupBlockedErrorHandler, claims) {
        if (!this.options.useCachedPromises) {
            return this.acquireTokenPopupInternal(scopes, popupBlockedErrorHandler, claims);
        }
        // Return promise if already cached
        const key = JSON.stringify({
            scopes,
            claims
        });
        const cachedTokenPromise = this.cachedTokenPromises[key];
        if (cachedTokenPromise != null) {
            return cachedTokenPromise;
        }
        // Make new call to acquireTokenPopup and cache the promise
        const tokenPromise = this.acquireTokenPopupInternal(scopes, popupBlockedErrorHandler, claims);
        this.cachedTokenPromises[key] = tokenPromise;
        try {
            const token = await tokenPromise;
            delete this.cachedTokenPromises[key];
            return token;
        } catch (error) {
            delete this.cachedTokenPromises[key];
            return Promise.reject(error);
        }
    }
    /**
     * Acquire token using popup
     * @param scopes scopes for the endpoint
     * @param popupBlockedErrorHandler Callback for handling popup blocked error
     * @param claimsChallenge Base64 encoded claims for getting the token
     */ async acquireTokenPopupInternal(scopes, popupBlockedErrorHandler, claimsChallenge) {
        const account = this.msalPca.getActiveAccount();
        const claims = this.getClaimsParameter(claimsChallenge);
        const authority = this.getAuthorityUrl(account?.tenantId);
        const popupRequest = {
            account: account !== null ? account : undefined,
            scopes,
            claims,
            authority,
            redirectUri: this.redirectUri
        };
        try {
            const response = await this.msalPca.acquireTokenPopup(popupRequest);
            return {
                accessToken: response.accessToken,
                expiry: response.expiresOn
            };
        } catch (error) {
            const hasClaims = claims ? true : false;
            this.loggers.traceLogger.logTrace(0x1e28f25e /* tag_4kpj4 */ , TraceLevel.Warning, this.constructErrorMessage({
                error,
                hasClaims,
                scopes
            }));
            if (error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.popupWindowError) {
                popupBlockedErrorHandler(scopes, claimsChallenge);
            }
            throw error;
        }
    }
    constructor(authConfig, loggers, options){
        this.loggers = loggers;
        this.aadAuthorityHost = this.getAuthorityHost(authConfig.environment, loggers);
        this.silentRedirectUri = authConfig.silentRedirectUrl ? authConfig.silentRedirectUrl.toString() : undefined;
        this.redirectUri = authConfig.redirectUrl ? authConfig.redirectUrl.toString() : undefined;
        this.cachedTokenPromises = {};
        this.options = options ?? {
            useCachedPromises: true,
            handleInteractionRequiredOnSilentLogin: true,
            cp1ClientCapability: false
        };
    }
}
