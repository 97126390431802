/**
 * news id for news bar
 */ export const NewsBarNewsIds = {
    OcdiRedirectExperience: "OCDI_REDIRECT_EXPERIENCE"
};
/**
 * Map for news dismiss time saved in local storage
 */ export const NewsDismissUntilMap = {
    [NewsBarNewsIds.OcdiRedirectExperience]: {
        key: "uwredu",
        dismissTime: 7 * 24 * 60 * 60 * 1000
    }
};
