/** Id constants for elements throughout the codebase */ export var ElementIds;
(function(ElementIds) {
    /** Left nav element ids */ ElementIds["MyDayNavButton"] = "myDayNavButton";
    ElementIds["MyTasksNavButton"] = "myTasksNavButton";
    ElementIds["MyPlansNavButton"] = "myPlansNavButton";
    ElementIds["MyPortfoliosNavButton"] = "myPortfoliosNavButton";
    ElementIds["LeftNavNewPlanButton"] = "leftNavNewPlanButton";
    /** My Day element ids */ ElementIds["MyDayTaskSuggestionsButton"] = "myDayTaskSuggestionsButton";
    /** My Tasks element ids */ ElementIds["MyTasksAllPivot"] = "myTasksAllPivot";
    ElementIds["MyTasksPrivatePivot"] = "myTasksPrivatePivot";
    ElementIds["MyTasksAssignedToMePivot"] = "myTasksAssignedToMePivot";
    ElementIds["MyTasksFlaggedEmailsPivot"] = "myTasksFlaggedPivot";
    /** My Plans element ids */ ElementIds["MyPlansRecentPivot"] = "myPlansRecentPivot";
    ElementIds["MyPlansSharedPivot"] = "myPlansSharedPivot";
    ElementIds["MyPlansPersonalPivot"] = "myPlansPersonalPivot";
    ElementIds["MyPlansPinnedPivot"] = "myPlansPinnedPivot";
    ElementIds["MyPlansMyTeamsPivot"] = "myPlansMyTeamsPivot";
    ElementIds["MyPlansRecommendedPivot"] = "myPlansRecommendedPivot";
    ElementIds["MyPlansNewPlanButton"] = "myPlansNewPlanButton";
    /** Plan view element ids */ ElementIds["BoardViewPivot"] = "boardViewPivot";
    ElementIds["ScheduleViewPivot"] = "scheduleViewPivot";
    ElementIds["ChartsViewPivot"] = "chartsViewPivot";
    ElementIds["GridViewPivot"] = "gridViewPivot";
    ElementIds["HomeViewPivot"] = "homeViewPivot";
    ElementIds["TimelineViewPivot"] = "timelineViewPivot";
    ElementIds["GridViewSecondColumn"] = "gridViewSecondColumn";
    ElementIds["HeaderJoinButtonTooltip"] = "joinButtonTooltip";
    ElementIds["HeaderShareButton"] = "headerShareButton";
    ElementIds["HeaderMoreOptionsButton"] = "headerMoreOptionsButton";
    ElementIds["TaskFiltersButton"] = "taskFiltersButton";
    ElementIds["CopilotButton"] = "copilotButton";
    ElementIds["GsdGoalsSection"] = "gsdGoalsSection";
    ElementIds["GsdResourcesSection"] = "gsdResourcesSection";
    ElementIds["ViewOptionsButton"] = "viewOptionsCommand";
    /** Task details dialog element ids */ ElementIds["TaskDetailsMoreOptionsButton"] = "taskDetailsMoreOptionsButton";
    ElementIds["TaskDetailsStartDateCalendarIcon"] = "taskDetailsStartDateCalendarIcon";
    /** Share plan dialog */ ElementIds["SharePlanCreateGroupPivot"] = "sharePlanCreateGroupPivot";
    ElementIds["SharePlanExistingGroupPivot"] = "sharePlanExistingGroupPivot";
})(ElementIds || (ElementIds = {}));
export var ViewElementIds;
(function(ViewElementIds) {
    ViewElementIds["BasicPlanView"] = "basicPlanView";
    ViewElementIds["PremiumPlanView"] = "premiumPlanView";
    ViewElementIds["BasicTaskDetailsView"] = "basicTaskDetailsView";
    ViewElementIds["PremiumTaskDetailsView"] = "premiumTaskDetailsView";
    ViewElementIds["MyTasksView"] = "myTasksView";
    ViewElementIds["MyDayView"] = "myDayView";
})(ViewElementIds || (ViewElementIds = {}));
/** The left nav collapse threshold in px */ export const LeftNavCollapseThreshold = 1024;
