export class CopilotPlanExtensions {
    constructor(loopWorkspace, goals, loopWorkspaceCreationInProgress){
        this.loopWorkspace = loopWorkspace;
        this.goals = goals;
        this.loopWorkspaceCreationInProgress = loopWorkspaceCreationInProgress;
    }
}
export const CopilotPlanExtensionsKeys = new Set([
    "loopWorkspace",
    "goals",
    "loopWorkspaceCreationInProgress"
]);
