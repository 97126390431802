// Loader
import { createComponentProxy } from "../../utilities/loader/LoaderUtilities";
import { Worker } from "@ms/uno-workers/lib/local/workers/Worker";
import { ChildAppInstantiationWorker } from "@ms/uno-workers/lib/local/workers/childApp/ChildAppInstantiationWorker";
import { DataFetchWorker } from "@ms/uno-workers/lib/local/workers/dataFetch/DataFetchWorker";
import { BootObserverWorker } from "@ms/uno-workers/lib/local/workers/bootObserver/BootObserverWorker";
/**
 * Loader class for loading workers in the base app
 */
export class BaseWorkerLoader {
    // #endregion
    /**
     * Initialize the worker loader
     * @param acProviders Action creator providers
     * @param storeProviders Store providers
     * @param persistedStoreLoader persisted store loader
     * @param storesToHydrateLoader stores to hydrate Loader
     * @param loader Component loader
     * @param loggers Loggers for telemetry
     * @param telemetryLogger Context for telemetry logger
     * @param routePathPrefix Route path prefix
     * @param persistentAsyncStorage Persisted async storage
     * @param configProvider Config provider
     */
    constructor(acProviders, storeProviders, persistedStoreLoader, storesToHydrateLoader, loader, loggers, telemetryLogger, routePathPrefix, persistentAsyncStorage, configProvider, serviceProviders, logicModuleProviders, hostAdaptor, dispatcherEventManagement) {
        this.acProviders = acProviders;
        this.storeProviders = storeProviders;
        this.persistedStoreLoader = persistedStoreLoader;
        this.storesToHydrateLoader = storesToHydrateLoader;
        this.loader = loader;
        this.loggers = loggers;
        this.telemetryLogger = telemetryLogger;
        this.routePathPrefix = routePathPrefix;
        this.persistentAsyncStorage = persistentAsyncStorage;
        this.configProvider = configProvider;
        this.serviceProviders = serviceProviders;
        this.logicModuleProviders = logicModuleProviders;
        this.hostAdaptor = hostAdaptor;
        this.dispatcherEventManagement = dispatcherEventManagement;
        this.loadAugLoopTileSyncWorker = () => this.loader.load(this.getWorkerBundleKey("auglooptilesync"), () => import(
        /* webpackChunkName: "uno-auglooptilesyncworker" */ "@ms/uno-workers/lib/local/workers/augloop/AugloopTileSyncWorker"), (bundle) => new bundle.AugloopTileSyncWorker(this.acProviders, this.storeProviders, this.configProvider));
        this.loadBasicPlanContainerFetcherWorker = () => this.loader.load(this.getWorkerBundleKey("basicplancontainerfetcher"), () => import(
        /* webpackChunkName: "uno-basicplancontainerfetcherworker" */ "@ms/uno-workers/lib/local/workers/plan/BasicPlanContainerFetcherWorker"), (bundle) => new bundle.BasicPlanContainerFetcherWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadBasicPlanConversionTrackerWorker = () => this.loader.load(this.getWorkerBundleKey("basicplanconversiontracker"), () => import(
        /* webpackChunkName: "uno-basicplanconversiontrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/BasicPlanConversionTrackerWorker"), (bundle) => new bundle.BasicPlanConversionTrackerWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadBootObserverWorker = () => this.bootObserverWorker;
        this.loadCdsProvisioningWorker = () => this.loader.load(this.getWorkerBundleKey("cdsProvisioningStatus"), () => import(
        /* webpackChunkName: "uno-cdsProvisioningStatusWorker" */ "@ms/uno-workers/lib/local/workers/plan/CdsProvisioningStatusWorker"), (bundle) => new bundle.CdsProvisioningStatusWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadDeltaSyncWorker = () => this.loader.load(this.getWorkerBundleKey("deltasync"), () => Promise.all([
            import(/* webpackChunkName: "uno-deltasyncworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncWorker"),
            import(
            /* webpackChunkName: "uno-exponentialbackoffutility" */ "@ms/uno-workers/lib/local/utilities/backoff/ExponentialBackoffUtility"),
            import(
            /* webpackChunkName: "uno-pagevisibilitystatusprovider" */ "@ms/uno-workers/lib/local/utilities/pageVisibility/PageVisibilityStatusProvider"),
        ]), ([worker, backoffUtility, pageVisibilityStatusProvider]) => {
            const deltaSyncConfiguration = this.configProvider().settings.deltaSyncConfiguration;
            return new worker.DeltaSyncWorker(this.acProviders, this.storeProviders, this.configProvider, new backoffUtility.ExponentialBackoffUtility(deltaSyncConfiguration.baseInterval, deltaSyncConfiguration.cutoff, deltaSyncConfiguration.backoffThreshold), new pageVisibilityStatusProvider.PageVisibilityStatusProvider(), this.loggers);
        });
        this.loadDeltaSyncFeedProcessorWorker = () => this.loader.load(this.getWorkerBundleKey("deltasyncfeedprocessor"), () => import(
        /* webpackChunkName: "uno-deltasyncfeedprocessorworker" */ "@ms/uno-workers/lib/local/workers/deltaSync/DeltaSyncFeedProcessorWorker"), (bundle) => new bundle.DeltaSyncFeedProcessorWorker(this.acProviders, this.storeProviders));
        this.loadHistoryObserverWorker = () => this.loader.load(this.getWorkerBundleKey("historyobserver"), () => import(
        /* webpackChunkName: "uno-historyobserverWorker" */ "@ms/uno-workers/lib/local/workers/history/HistoryObserverWorker"), (bundle) => new bundle.HistoryObserverWorker(this.acProviders, this.storeProviders, this.routePathPrefix, () => document.location.pathname));
        this.loadNavigationWorker = () => this.loader.load(this.getWorkerBundleKey("nav"), () => import(/* webpackChunkName: "uno-navworker" */ "@ms/uno-workers/lib/local/workers/plan/NavigationWorker"), (bundle) => new bundle.NavigationWorker(this.acProviders, this.storeProviders, this.loggers));
        this.loadNetworkConnectivityWorker = () => this.loader.load(this.getWorkerBundleKey("networkconnectivity"), () => import(
        /* webpackChunkName: "uno-networkconnectivityworker" */ "@ms/uno-workers/lib/local/workers/network/NetworkConnectivityWorker"), (bundle) => new bundle.NetworkConnectivityWorker(this.loggers.logHandler));
        this.loadOperationTrackingWorker = () => this.loader.load(this.getWorkerBundleKey("operationtracking"), () => import(
        /* webpackChunkName: "uno-operationtrackingworker" */ "@ms/uno-workers/lib/local/workers/operation/OperationTrackingWorker"), (bundle) => new bundle.OperationTrackingWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadPerformanceObserverWorker = () => this.loader.load(this.getWorkerBundleKey("performanceobserver"), () => import(
        /* webpackChunkName: "uno-performanceobserverworker" */ "@ms/uno-workers/lib/local/workers/performance/WebPlatformPerformanceObserverWorker"), (bundle) => new bundle.WebPlatformPerformanceObserverWorker(this.telemetryLogger, this.configProvider));
        this.loadPlanConversionStatusTrackerWorker = () => this.loader.load(this.getWorkerBundleKey("planconversionstatustracker"), () => import(
        /* webpackChunkName: "uno-planconversionstatustrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/PlanConversionStatusTrackerWorker"), (bundle) => new bundle.PlanConversionStatusTrackerWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadPremiumPlanCreationStatusWorker = () => this.loader.load(this.getWorkerBundleKey("premiumplancreationstatus"), () => import(
        /* webpackChunkName: "uno-premiumplancreationstatusworker" */ "@ms/uno-workers/lib/local/workers/plan/PremiumPlanCreationStatusWorker"), (bundle) => new bundle.PremiumPlanCreationStatusWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadProjectTrialOperationTrackerWorker = () => this.loader.load(this.getWorkerBundleKey("projecttrialoperationtracker"), () => import(
        /* webpackChunkName: "uno-projecttrialoperationtrackerworker" */ "@ms/uno-workers/lib/local/workers/upsell/ProjectTrialOperationTrackerWorker"), (bundle) => new bundle.ProjectTrialOperationTrackerWorker(this.acProviders, this.storeProviders));
        this.loadRecommendedPlanUpdateWorker = () => this.loader.load(this.getWorkerBundleKey("recommendedplanupdate"), () => import(
        /* webpackChunkName: "uno-recommendedplanupdateworker" */ "@ms/uno-workers/lib/local/workers/plan/RecommendedPlansUpdateWorker"), (bundle) => new bundle.RecommendedPlansUpdateWorker(this.acProviders, this.storeProviders, this.loggers, this.configProvider));
        this.loadChildAppInstantiationWorker = () => this.childAppInstantiationWorker;
        this.loadDataFetchWorker = () => this.dataFetchWorker;
        this.loadCacheUpdateWorker = () => {
            return this.loader.load(this.getWorkerBundleKey("cacheupdate"), () => import(/* webpackChunkName: "uno-cacheupdateworker" */ "@ms/uno-workers/lib/local/workers/cache/CacheUpdateWorker"), (bundle) => {
                if (!this.persistentAsyncStorage) {
                    throw new Error("Persistent async storage is not available.");
                }
                return new bundle.CacheUpdateWorker(this.dispatcherEventManagement, this.persistedStoreLoader, this.persistentAsyncStorage, this.configProvider, this.telemetryLogger);
            });
        };
        this.loadCacheEvictionWorker = () => {
            return this.loader.load(this.getWorkerBundleKey("cacheeviction"), () => import(/* webpackChunkName: "uno-cacheevictionworker" */ "@ms/uno-workers/lib/local/workers/cache/CacheEvictionWorker"), (bundle) => {
                if (!this.persistentAsyncStorage) {
                    throw new Error("Persistent async storage is not available.");
                }
                return new bundle.CacheEvictionWorker(this.storeProviders, this.persistentAsyncStorage, this.configProvider, this.telemetryLogger);
            });
        };
        this.loadStoreHydrationWorker = () => {
            return this.loader.load(this.getWorkerBundleKey("storehydration"), () => import(/* webpackChunkName: "uno-storehydrationworker" */ "@ms/uno-workers/lib/local/workers/cache/StoreHydrationWorker"), (bundle) => {
                if (!this.persistentAsyncStorage) {
                    throw new Error("Persistent async storage is not available.");
                }
                return new bundle.StoreHydrationWorker(this.storeProviders, this.storesToHydrateLoader, this.configProvider, this.persistentAsyncStorage, this.telemetryLogger);
            });
        };
        this.loadWorkflowCopilotWorker = () => this.loader.load(this.getWorkerBundleKey("workflowcopilot"), () => import(
        /* webpackChunkName: "uno-workflowcopilotworker" */ "@ms/uno-workers/lib/local/workers/workflowCopilot/WorkflowCopilotWorker"), (bundle) => new bundle.WorkflowCopilotWorker(this.storeProviders, this.acProviders));
        this.loadMruWorker = () => this.loader.load(this.getWorkerBundleKey("mru"), () => import(/* webpackChunkName: "uno-mruworker" */ "@ms/uno-workers/lib/local/workers/mru/MruWorker"), (bundle) => new bundle.MruWorker(this.acProviders, this.storeProviders, this.configProvider));
        this.loadModalWorker = () => this.loader.load(this.getWorkerBundleKey("modal"), () => import(/* webpackChunkName: "uno-modalworker" */ "@ms/uno-workers/lib/local/workers/modal/ModalWorker"), (bundle) => new bundle.ModalWorker(this.acProviders, this.storeProviders, this.configProvider));
        /** Map of all functions that load a worker */
        this.workerLoaders = {
            [Worker.AugloopTileSync]: this.loadAugLoopTileSyncWorker,
            [Worker.BasicPlanContainerFetcher]: this.loadBasicPlanContainerFetcherWorker,
            [Worker.BasicPlanConversionTracker]: this.loadBasicPlanConversionTrackerWorker,
            [Worker.BootObserver]: this.loadBootObserverWorker,
            [Worker.CdsProvisioningStatus]: this.loadCdsProvisioningWorker,
            [Worker.ChildAppInstantiationWorker]: this.loadChildAppInstantiationWorker,
            [Worker.DeltaSync]: this.loadDeltaSyncWorker,
            [Worker.DeltaSyncFeedProcessor]: this.loadDeltaSyncFeedProcessorWorker,
            [Worker.HistoryObserver]: this.loadHistoryObserverWorker,
            [Worker.Navigation]: this.loadNavigationWorker,
            [Worker.NetworkConnectivity]: this.loadNetworkConnectivityWorker,
            [Worker.OperationTracking]: this.loadOperationTrackingWorker,
            [Worker.PerformanceObserver]: this.loadPerformanceObserverWorker,
            [Worker.PlanConversionStatusTracker]: this.loadPlanConversionStatusTrackerWorker,
            [Worker.PremiumPlanCreationStatus]: this.loadPremiumPlanCreationStatusWorker,
            [Worker.ProjectTrialOperationTracker]: this.loadProjectTrialOperationTrackerWorker,
            [Worker.RecommendedPlanUpdate]: this.loadRecommendedPlanUpdateWorker,
            [Worker.StoreHydration]: this.loadStoreHydrationWorker,
            [Worker.DataFetch]: this.loadDataFetchWorker,
            [Worker.CacheUpdate]: this.loadCacheUpdateWorker,
            [Worker.WorkflowCopilot]: this.loadWorkflowCopilotWorker,
            [Worker.Mru]: this.loadMruWorker,
            [Worker.Modal]: this.loadModalWorker,
            [Worker.CacheEviction]: this.loadCacheEvictionWorker,
        };
        this.workerSynchronousProxyLoaders = createProxyLoaders(this.workerLoaders, this.loggers);
        this.dataFetchWorker = new DataFetchWorker(acProviders, storeProviders, loggers, configProvider);
        this.bootObserverWorker = new BootObserverWorker(this.configProvider, this.storeProviders, this.loggers);
        this.childAppInstantiationWorker = new ChildAppInstantiationWorker(this.acProviders, this.storeProviders, this.configProvider, this.loggers, this.serviceProviders, this.logicModuleProviders, this.hostAdaptor, this.dispatcherEventManagement);
    }
    load(worker) {
        return this.workerSynchronousProxyLoaders[worker]();
    }
    /**
     * Get the worker bundle key for the given worker name
     * @param workerName
     */
    getWorkerBundleKey(workerName) {
        return `uno-${workerName}worker`;
    }
}
/**
 * Utility method to create proxy loaders from dynamic loaders
 * @param dynamicLoaders Dynamic loaders that load workers async
 * @param loggers Loggers for telemetry
 */
function createProxyLoaders(dynamicLoaders, loggers) {
    return Object.keys(dynamicLoaders).reduce((acc, componentName) => {
        acc[componentName] = () => createComponentProxy(componentName, dynamicLoaders[componentName], loggers);
        return acc;
    }, {});
}
