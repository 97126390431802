// Stores
// Contexts
import { StoreLoaderContext } from "../contexts/StoreLoaderContext";
import { StoreSubscriptionManagerContext } from "../contexts/StoreSubscriptionManagerContext";
// Utilities
import { useEffect, useState } from "react";
import { useNullSafeContext } from "./useNullSafeContext";
/**
 * Custom hook to subscribe to multiple stores and re-render when data contained within them changes
 * @param storeKeys Array of store keys to subscribe to
 * @returns An array of store instances
 */ export function useStores(storeKeys) {
    const storeLoader = useNullSafeContext(StoreLoaderContext);
    const storeSubscriptionManager = useNullSafeContext(StoreSubscriptionManagerContext);
    /**
     * Mechanism to schedule a re-render using a monotonically increasing key.
     * Instead of directly calling setRenderKey, we schedule a re-render to avoid
     * multiple re-renders in a single synchronous execution cycle.
     */ const [, setRenderKey] = useState(0);
    useEffect(()=>{
        let pendingRenderTimeout;
        // Helper function to schedule a re-render
        const scheduleRerender = ()=>{
            // NOTE: remove setTimeout after upgrading to React 18, which automatically batches updates
            pendingRenderTimeout ??= setTimeout(()=>{
                pendingRenderTimeout = undefined;
                setRenderKey((prevKey)=>prevKey + 1);
            });
        };
        storeSubscriptionManager.registerSubscription([
            ...storeKeys
        ], scheduleRerender);
        return ()=>{
            clearTimeout(pendingRenderTimeout);
            storeSubscriptionManager.unregisterSubscription([
                ...storeKeys
            ], scheduleRerender);
        };
    // We want to run this effect only once and hence no dependencies are needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return storeLoader.loadAll(storeKeys);
}
