// Actions
import { TypedAction } from "@ms/uno-fluxcore/lib/local/actions/Action";
import { ActionType } from "../ActionType";
export class UpdateChildViewStatusAction extends TypedAction(ActionType.UpdateChildViewStatusAction) {
    loggingData() {
        return {
            ...super.loggingData(),
            childView: this.childView,
            state: this.state
        };
    }
    constructor(childView, state, timestamp = Date.now()){
        super(), this.childView = childView, this.state = state, this.timestamp = timestamp;
    }
}
