// Action Creators
// Actions
import { AppStartAction, PageReadyAction } from "@ms/uno-actions/lib/local/performanceCollection/PerformanceCollectionAction";
import { ViewRenderState } from "@ms/uno-constants/lib/local/ViewConstants";
import { SubRouteTypeToViewNameMap } from "@ms/uno-constants/lib/local/RoutingConstants";
// Stores
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Utilities
import { checkIfNonNativeViewFromSubRouteType, extractPlanRoutingDetailsFromSubRoute } from "@ms/uno-routing/lib/local/utilities/RouteUtilities";
/**
 * ActionCreator class for anything pertaining to performance data collection like EUPL and PLT
 */ export class PerformanceCollectionActionCreator {
    appStart(pageName, appStartTime) {
        this.dispatcher.dispatchActionAsync(new AppStartAction(pageName, appStartTime));
    }
    pageReady(dataFetchStart, dataFetchEnd, exportName, metaTag, extraData) {
        this.dispatcher.dispatchActionAsync(new PageReadyAction(dataFetchStart, dataFetchEnd, exportName, metaTag, extraData));
        this.logViewRenderPerfMarkerForUnoNonNativeViews();
    }
    viewRender(viewName, state, extraData) {
        this.modules.performanceCollection().setViewRenderState(viewName, state, extraData);
    }
    viewDataFetch(viewName, state) {
        this.modules.performanceCollection().setViewDataFetchState(viewName, state);
    }
    /**
     * Logs view render performance marker for Uno non-native views  on page ready.
     */ logViewRenderPerfMarkerForUnoNonNativeViews() {
        const routeStore = this.stores.load(Store.Route);
        const currentSubRouteType = routeStore.getCurrentSubRouteType();
        // Don't log ViewRender marker on pageReady for native views
        if (!checkIfNonNativeViewFromSubRouteType(currentSubRouteType, this.configProvider)) {
            return;
        }
        this.viewRender(SubRouteTypeToViewNameMap[currentSubRouteType], ViewRenderState.Critical, {
            viewType: `${extractPlanRoutingDetailsFromSubRoute(routeStore.getCurrentSubRoute(), currentSubRouteType)?.viewType}`
        });
    }
    /**
     * Initializes a new instance of the performance collector action creator
     * @param dispatcher The dispatcher the SPA uses to dispatch actions
     * @param stores The store providers the SPA uses to get stores
     * @param modules The logic module providers the SPA uses to get logic modules
     * @param configProvider The configuration provider for the app
     */ constructor(dispatcher, stores, modules, configProvider){
        this.dispatcher = dispatcher;
        this.stores = stores;
        this.modules = modules;
        this.configProvider = configProvider;
    }
}
