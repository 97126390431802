/**
 * Store names
 */ export var Store;
(function(Store) {
    Store["Activity"] = "activity";
    Store["Aggregates"] = "aggregates";
    Store["AppContext"] = "appContext";
    Store["Auth"] = "auth";
    Store["BasicBucket"] = "basicBucket";
    Store["BasicPlan"] = "basicPlan";
    Store["BasicPlanConversionStatus"] = "basicPlanConversionStatus";
    Store["BasicPermissions"] = "basicPermissions";
    Store["BasicTask"] = "basicTask";
    Store["Campaign"] = "campaign";
    Store["Capabilities"] = "capabilities";
    Store["Container"] = "container";
    Store["Conversations"] = "conversations";
    Store["CreateTaskFromMessage"] = "createTaskFromMessage";
    Store["DeltaSync"] = "deltaSync";
    Store["DriveItem"] = "driveItem";
    Store["Groups"] = "groups";
    Store["Intelligence"] = "intelligence";
    Store["Modal"] = "modal";
    Store["Mru"] = "mru";
    Store["Navigation"] = "navigation";
    Store["NewsBar"] = "newsBar";
    Store["Notification"] = "notification";
    Store["OperationTracking"] = "operationTracking";
    Store["Org"] = "org";
    Store["PerformanceCollection"] = "performanceCollection";
    Store["PlanConversion"] = "planConversion";
    Store["Portfolio"] = "portfolio";
    Store["PremiumPlan"] = "premiumPlan";
    Store["PremiumPlanPermissions"] = "premiumPlanPermissions";
    Store["ProjectHomeResource"] = "projectHomeResource";
    Store["Publishing"] = "publishing";
    Store["RecommendedPlans"] = "recommendedPlans";
    Store["Route"] = "route";
    Store["StatusReport"] = "statusReport";
    Store["SuiteNav"] = "suiteNav";
    Store["Tasks"] = "tasks";
    Store["Teams"] = "teams";
    Store["Templates"] = "templates";
    Store["Tenant"] = "tenant";
    Store["Upsell"] = "upsell";
    Store["UnoShell"] = "unoShell";
    Store["User"] = "user";
    Store["UserMembership"] = "userMembership";
    Store["View"] = "view";
    Store["WorkflowCopilot"] = "workflowCopilot";
})(Store || (Store = {}));
