// Entitycore
export var TaskKind;
(function(TaskKind) {
    /** Shared Planner task. This type of task can be assigned to people and have more than one user id on it*/ TaskKind[TaskKind["Shared"] = 1] = "Shared";
    /** Todo task */ TaskKind[TaskKind["Todo"] = 2] = "Todo";
    /** Publishable template task from Teams Publishing */ TaskKind[TaskKind["Template"] = 3] = "Template";
    /** Shared Task for Teams consumer (MSA - TFL). This is the MSA equivalent of the shared task */ TaskKind[TaskKind["SharedConsumer"] = 4] = "SharedConsumer";
})(TaskKind || (TaskKind = {}));
export var TaskCreationSourceType;
(function(TaskCreationSourceType) {
    /** Regular IW Task */ TaskCreationSourceType["Regular"] = "Planner";
    /** A task created via a publishing process */ TaskCreationSourceType["Publication"] = "Publication";
    /** A task created via sync from office document */ TaskCreationSourceType["Document"] = "Document";
    /** A task created via Project app */ TaskCreationSourceType["Project"] = "Project";
    /** A task created via an external system */ TaskCreationSourceType["External"] = "External";
})(TaskCreationSourceType || (TaskCreationSourceType = {}));
export var PriorityName;
(function(PriorityName) {
    /**
     * Urgent state (0-1)
     */ PriorityName["Urgent"] = "Urgent";
    /**
     * Important state (2-4)
     */ PriorityName["Important"] = "Important";
    /**
     * Default medium state (5-7)
     */ PriorityName["Medium"] = "Medium";
    /**
     * Low state (8-10)
     */ PriorityName["Low"] = "Low";
})(PriorityName || (PriorityName = {}));
