// Constants
import { SubRouteType, ViewType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { MyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utils
import { createRouteFromDetails } from "@ms/uno-routing";
import { DeepLinkSubRouteKeys } from "@ms/uno-routing/lib/local/utilities/DeepLinkUtils";
/**
 * Legacy planner web links for the following scenarios:
 * Url based link to Planner Hub
 * Url based link to My Tasks
 * Url based link to Plan
 * DeepLink to Plan
 * DeepLink to Task
 */ const legacyHubUrlLinkRegex = /^.*\/Home\/Planner\/\#\/planhub$/i;
const legacyMyTasksUrlLinkRegex = RegExp("^.*\\/Home\\/Planner\\/#\\/(user(?<view>grid|schedule|charts|board)|mytasks)$", "i");
const legacyPlanUrlLinkRegex = RegExp("^.*\\/Home\\/Planner\\/\\#\\/plan(?<view>grid|schedule|charts|taskboard|board)\\?groupId=(?<containerId>[A-Za-z0-9-_]{28,36})&planId=(?<planId>[A-Za-z0-9-_]{28})$", "i");
const legacyPlanDeepLinkRegex = RegExp("^.*\\/Home\\/PlanViews\\/(?<planId>[A-Za-z0-9-_]{28})\\?Type=(?<type>[A-Za-z]{1,32})&Channel=(?<channel>[A-Za-z]{1,32})&CreatedTime=(?<createdTime>[0-9]{18})$", "i");
const legacyTaskDeepLinkRegex = RegExp("^.*\\/Home\\/Task\\/(?<taskId>[A-Za-z0-9-_]{28})\\?Type=(?<type>[A-Za-z]{1,32})&Channel=(?<channel>[A-Za-z]{1,32})&CreatedTime=(?<createdTime>[0-9]{18})$", "i");
/**
 * Parses the specified deepLink and returns its corresponding Route (or null, if no valid route parsed)
 * @param deepLink The deepLink to parse
 * @param loggers Loggers to use for logging
 */ export function generateRouteFromLegacyDeepLink(deepLink, loggers) {
    if (!deepLink) {
        return null;
    }
    let linkView;
    let linkPlanId;
    let linkTaskId;
    let linkType;
    let linkChannel;
    let linkCreatedTime;
    let regexResult = null;
    try {
        // Case 1: Planner Web Link to Planner Hub
        regexResult = deepLink.match(legacyHubUrlLinkRegex);
        if (regexResult) {
            loggers?.traceLogger.logTrace(0x1e30b505 /* tag_4mluf */ , TraceLevel.Info, `Parsed Planner web link to Planner Hub`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyPlans
            });
        }
        // Case 2: Planner Web Link to My Tasks
        regexResult = deepLink.match(legacyMyTasksUrlLinkRegex);
        if (regexResult) {
            linkView = regexResult.groups?.view;
            // Update mapped view based on linkView when we support other view types in ATM
            const mappedView = ViewType.Grid;
            loggers?.traceLogger.logTrace(0x1e30b504 /* tag_4mlue */ , TraceLevel.Info, `Parsed Planner web link to My Tasks [ParsedView=${linkView}][MappedView=${mappedView}]`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyTasks,
                myTasksPivot: MyTasksViewPivotType.AssignedToMe,
                view: mappedView
            });
        }
        // Case 3: Planner Web Link to Plan
        regexResult = deepLink.match(legacyPlanUrlLinkRegex);
        if (regexResult) {
            // The following must exist for the regex to match. hence the type assertion
            linkView = regexResult.groups.view;
            linkPlanId = regexResult.groups.planId;
            const mappedView = getViewTypeFromLinkView(linkView);
            loggers?.traceLogger.logTrace(0x1e30b502 /* tag_4mluc */ , TraceLevel.Info, `Parsed legacy planner web link to Plan [ParsedView=${linkView}][MappedView=${mappedView}]`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.BasicPlan,
                planId: linkPlanId,
                view: mappedView
            });
        }
        // Case 4: Planner DeepLink to Plan
        regexResult = deepLink.match(legacyPlanDeepLinkRegex);
        if (regexResult) {
            // The following must exist for the regex to match. hence the type assertion
            linkPlanId = regexResult.groups.planId;
            linkType = regexResult.groups.type;
            linkChannel = regexResult.groups.channel;
            linkCreatedTime = regexResult.groups.createdTime;
            loggers?.traceLogger.logTrace(0x1e30b500 /* tag_4mlua */ , TraceLevel.Info, `Parsed legacy planner deepLink to Plan[LinkType=${linkType}][LinkChannel=${linkChannel}][LinkCreatedTime=${linkCreatedTime}]`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.BasicPlan,
                planId: linkPlanId
            });
        }
        // Case 5: Planner DeepLink to Task
        regexResult = deepLink.match(legacyTaskDeepLinkRegex);
        if (regexResult) {
            linkTaskId = regexResult.groups.taskId;
            linkType = regexResult.groups?.type;
            linkChannel = regexResult.groups?.channel;
            linkCreatedTime = regexResult.groups?.createdTime;
            loggers?.traceLogger.logTrace(0x1e30b4e2 /* tag_4mlt8 */ , TraceLevel.Info, `Parsed legacy planner deepLink to Task[LinkType=${linkType}][LinkChannel=${linkChannel}][LinkCreatedTime=${linkCreatedTime}]`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyPlans,
                taskId: linkTaskId
            });
        }
        // No match case
        loggers?.traceLogger.logTrace(0x1e30b4e1 /* tag_4mlt7 */ , TraceLevel.Warning, "generateRouteFromLegacyDeepLink - No routeParams parsed from deepLink");
    } catch (e) {
        loggers?.traceLogger.logTrace(0x1e30b4e0 /* tag_4mlt6 */ , TraceLevel.Warning, "generateRouteFromLegacyDeepLink - Error parsing deepLink");
    }
    return null;
}
/**
 * Parses the specified transformed deepLink and returns its corresponding Route (or null, if no valid route parsed)
 * Note: Legacy Planner Web (todoweb) might turn a link like
 * https://planner.cloud.microsoft/microsoft.onmicrosoft.com/Home/Task/yAx0fYgzok-_0llLXp0IIJUAIJmO
 * into
 * https://planner.cloud.microsoft/microsoft.onmicrosoft.com/en-US/Home/Planner/#/plantaskboard?groupId=95432f65-b837-46f1-9743-439019d647f2&planId=6_Sw5Rux70CzRLkMI6TfL5UAHr_j&taskId=yAx0fYgzok-_0llLXp0IIJUAIJmO"
 * @param deepLinkPath The deepLinkPath to parse
 * @param deepLinkHash The deepLinkHash to parse
 * @param loggers Loggers to use for logging
 */ export function generateRouteFromTransformedLegacyDeepLink(deepLinkPath, deepLinkHash, loggers) {
    if (!deepLinkPath || !deepLinkHash) {
        return null;
    }
    try {
        // If path starts with "/v1/" this is new UNO Web DeepLink schema, skip
        // If path doesn't contain "/Home/Planner" this is not a Legacy Planner Transformed DeepLink, skip
        if (deepLinkPath.startsWith(`/${DeepLinkSubRouteKeys.V1}/`) || !deepLinkPath.toLowerCase().includes("/Home/Planner".toLowerCase())) {
            return null;
        }
        // If hash doesn't start with "#/" then it's not a legacy transformed deepLink to parse
        if (!deepLinkHash.startsWith("#/")) {
            return null;
        }
        // Remove the leading "#/" from the hash
        deepLinkHash = deepLinkHash.substring(2);
        // Split deepLinkHash into the pageType and query parameters (if present)
        const deepLinkHashSegments = deepLinkHash.split("?");
        const pageType = deepLinkHashSegments[0]?.toLowerCase() ?? "";
        const queryParams = deepLinkHashSegments.length > 0 ? deepLinkHashSegments[1] : "";
        // [Case 1] Planner Hub
        if (pageType === "planhub") {
            loggers?.traceLogger.logTrace(0x1e2da258 /* tag_4l0jy */ , TraceLevel.Info, `Parsed Planner web link to Planner Hub`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyPlans
            });
        }
        // [Case 2] My Tasks
        const myTasksPageTypes = [
            "userboard",
            "usergrid",
            "usercharts",
            "userschedule"
        ];
        if (myTasksPageTypes.includes(pageType)) {
            loggers?.traceLogger.logTrace(0x1e2da257 /* tag_4l0jx */ , TraceLevel.Info, `Parsed Planner web link to My Tasks [ParsedView=${pageType}]`);
            return createRouteFromDetails({
                subRouteType: SubRouteType.MyTasks,
                myTasksPivot: MyTasksViewPivotType.AssignedToMe
            });
        }
        // [Case 3] Plan
        const myPlanPageTypes = [
            "plantaskboard",
            "plangrid",
            "plancharts",
            "planschedule",
            "planboard"
        ];
        if (myPlanPageTypes.includes(pageType)) {
            const pageTypeView = pageType.replace(/^plan/, "");
            const view = getViewTypeFromLinkView(pageTypeView);
            const searchParams = new URLSearchParams(queryParams);
            const planId = searchParams.get("planId");
            const taskId = searchParams.get("taskId");
            if (planId) {
                loggers?.traceLogger.logTrace(0x1e2da256 /* tag_4l0jw */ , TraceLevel.Info, `Parsed legacy planner web link to Plan [ParsedView=${pageType}][HasTask=${!!taskId}]`);
                const basicPlanRouteCreateDetails = {
                    subRouteType: SubRouteType.BasicPlan,
                    planId: planId,
                    view: view
                };
                if (taskId) {
                    basicPlanRouteCreateDetails.taskId = taskId;
                }
                return createRouteFromDetails(basicPlanRouteCreateDetails);
            }
        }
        // Nothing parsed
        loggers?.traceLogger.logTrace(0x1e2da255 /* tag_4l0jv */ , TraceLevel.Warning, "generateRouteFromTransformedLegacyDeepLink - No routeParams parsed from deepLink");
    } catch (e) {
        loggers?.traceLogger.logTrace(0x1e2da254 /* tag_4l0ju */ , TraceLevel.Warning, "generateRouteFromTransformedLegacyDeepLink - Error parsing deepLink");
    }
    return null;
}
/**
 * Gets the ViewType from the specified linkView
 * @param linkView The linkView to parse
 */ function getViewTypeFromLinkView(linkView) {
    switch(linkView){
        case "taskboard":
        case "board":
            return ViewType.Board;
        case "charts":
            return ViewType.Charts;
        case "schedule":
            return ViewType.Schedule;
        case "grid":
        default:
            return ViewType.Grid;
    }
}
