// Builder
import { PlanDetailsBuilder } from "./PlanDetailsBuilder";
// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { TaskServiceEntity } from "../TaskServiceEntity";
import { PlanContextDetails } from "./PlanContextDetails";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import reduce from "lodash/reduce";
import { applyDiffMomentCustomizer, getDiffMomentCustomizer, getDiff } from "@ms/uno-utilities/lib/local/ObjectUtilities";
/**
 * Represents a PlanDetails entity in the client
 */ export class PlanDetails extends TaskServiceEntity {
    /**
     * Builder for Plan Details objects
     */ static builder() {
        return new PlanDetailsBuilder();
    }
    /**
     * Customizer for handling diffs & updates. Used by mergeWith
     * @param source Original value
     * @param target Updated value
     * @param key Key for value
     */ static mergeCustomizer(source, target, key) {
        if (key === "contextDetails") {
            if (isEqual(target, DeltaApiConstants.CollectionCleanupValue)) {
                // All removed workaround
                return {};
            }
            return target;
        } else if (isArray(source) || isArray(target) || key === "background") {
            // Categories or shared with, return the target array, since we don't actually want to merge them if we remove items
            // Background, return the target background object
            return target;
        } else {
            return applyDiffMomentCustomizer(source, target, key);
        }
    }
    get entityType() {
        return TaskServiceEntityType.PlanDetails;
    }
    toGraphSerializable() {
        const sharedWith = reduce(this.sharedWith, (result, value)=>{
            result[value] = true;
            return result;
        }, {});
        const background = {
            baseColor: this.background.baseColor,
            overlayImageUrl: this.background.overlayImageUrl
        };
        const categoryDescriptions = {
            category1: this.categories[0],
            category2: this.categories[1],
            category3: this.categories[2],
            category4: this.categories[3],
            category5: this.categories[4],
            category6: this.categories[5]
        };
        for(let i = 6; i < this.categories.length; i++){
            categoryDescriptions["category" + (i + 1)] = this.categories[i];
        }
        return {
            "@odata.etag": this.itemVersion,
            categoryDescriptions,
            id: this.id,
            background: background,
            sharedWith: sharedWith,
            contextDetails: PlanContextDetails.getGraphContextResources(this.contextDetails),
            allowICalendarAccess: this.iCalendarPublishEnabled,
            iCalendarAccessUrl: this.iCalendarPublishUrl
        };
    }
    forCreate() {
        const planDetails = this.toGraphSerializable();
        return {
            categoryDescriptions: planDetails.categoryDescriptions,
            sharedWith: planDetails.sharedWith,
            contextDetails: planDetails.contextDetails
        };
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (key === "id" || key === "itemVersion" || key === "iCalendarPublishUrl") {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    addUserToSharedWith(userId, removeFirstUser = false) {
        const sharedWithClone = cloneDeep(this.sharedWith);
        if (removeFirstUser && sharedWithClone.length > 0) {
            sharedWithClone.splice(0, 1);
        }
        sharedWithClone.push(userId);
        return this.setProperty("sharedWith", sharedWithClone);
    }
    getClone() {
        return PlanDetails.builder().withPropertyBag({
            categories: this.categories.slice(),
            id: this.id,
            itemVersion: this.itemVersion,
            notifyWhen: cloneDeep(this.notifyWhen),
            sharedWith: this.sharedWith.slice(),
            iCalendarPublishEnabled: this.iCalendarPublishEnabled,
            iCalendarPublishUrl: this.iCalendarPublishUrl || null,
            contextDetails: cloneDeep(this.contextDetails),
            background: cloneDeep(this.background)
        }).build();
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === "contextDetails") {
                if (!isEqual(source, target)) {
                    if (isEqual(target, {})) {
                        // Remove all contextDetails workaround. if {} is returned it treats it as no diff
                        return DeltaApiConstants.CollectionCleanupValue;
                    }
                    return target;
                } else {
                    return {};
                }
            } else if (isArray(source) || isArray(target) || key === "background") {
                if (!isEqual(source, target)) {
                    // Categories or shared with, return the target array, since we don't actually want to merge them if we remove items
                    // Background, return the target background object
                    return target;
                } else {
                    // Equal
                    return {};
                }
            }
            if (key === "iCalendarPublishEnabled") {
                if (!isEqual(source, target)) {
                    return target;
                } else {
                    return {};
                }
            } else {
                return getDiffMomentCustomizer(source, target, key);
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    applyDiffs(...diffs) {
        if (!every(diffs, (diff)=>diff != null)) {
            throw new Error("ArgumentException: diffs - Diffs array contains null elements");
        }
        if (diffs.length > 0) {
            return mergeWith(PlanDetails.builder().build(), this, ...diffs, PlanDetails.mergeCustomizer);
        }
        return this;
    }
    applyDifferentialUpdate(update) {
        if (update.id !== this.id) {
            throw new Error("ArgumentException: update.id must match this.id");
        }
        const diffData = update.getUpdateDiffData(this);
        return this.applyDiffs(diffData);
    }
    /**
     * Initializes a new instance of the "Plan Details" entity.
     * @param planDetailsBuilder Builder with the initialization data
     */ constructor(planDetailsBuilder){
        super(planDetailsBuilder.id, planDetailsBuilder.itemVersion);
        this.categories = cloneDeep(planDetailsBuilder.categories);
        this.notifyWhen = planDetailsBuilder.notifyWhen;
        this.sharedWith = cloneDeep(planDetailsBuilder.sharedWith);
        this.iCalendarPublishEnabled = planDetailsBuilder.iCalendarPublishEnabled;
        this.iCalendarPublishUrl = planDetailsBuilder.iCalendarPublishUrl;
        this.contextDetails = cloneDeep(planDetailsBuilder.contextDetails);
        this.background = cloneDeep(planDetailsBuilder.background);
    }
}
/**
     * Maximum allowed size of task's category name.
     * This is from todoserver\Interfaces\Internal\CategoriesFieldChange.cs
     */ PlanDetails.MaxCategoryNameLength = 100;
