import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for PerformanceCollectionStore, for use in the registry */ export const PerformanceCollectionStoreActionTypes = [
    ActionType.AppStartAction,
    ActionType.PageReadyAction,
    ActionType.NavigateToView,
    ActionType.PushSubRoute,
    ActionType.UpdateChildViewStatusAction,
    ActionType.ViewRenderAction,
    ActionType.ViewDataFetchAction
];
