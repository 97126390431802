export var ActionCreator;
(function(ActionCreator) {
    ActionCreator["Activity"] = "activity";
    ActionCreator["AppContext"] = "appContext";
    ActionCreator["BasicBucket"] = "basicBucket";
    ActionCreator["BasicPlan"] = "basicPlan";
    ActionCreator["BasicTask"] = "basicTask";
    ActionCreator["Campaign"] = "campaign";
    ActionCreator["Capabilities"] = "capabilities";
    ActionCreator["Container"] = "container";
    ActionCreator["CreateTaskFromTeamsMessage"] = "createTaskFromTeamsMessage";
    ActionCreator["DataPreFetch"] = "dataPreFetch";
    ActionCreator["DeltaSync"] = "deltaSync";
    ActionCreator["DriveItem"] = "driveItem";
    ActionCreator["Export"] = "export";
    ActionCreator["Groups"] = "groups";
    ActionCreator["Intelligence"] = "intelligence";
    ActionCreator["Links"] = "links";
    ActionCreator["Modal"] = "modal";
    ActionCreator["Mru"] = "mru";
    ActionCreator["NewsBar"] = "newsBar";
    ActionCreator["Notification"] = "notification";
    ActionCreator["OCV"] = "OCV";
    ActionCreator["OperationTracking"] = "operationTracking";
    ActionCreator["Org"] = "org";
    ActionCreator["PerformanceCollection"] = "performanceCollection";
    ActionCreator["PlanAi"] = "planAi";
    ActionCreator["PlanConversion"] = "planConversion";
    ActionCreator["Portfolio"] = "portfolio";
    ActionCreator["Publishing"] = "publishing";
    ActionCreator["PremiumPlan"] = "premiumPlan";
    ActionCreator["ProjectHomeResource"] = "projectHomeResource";
    ActionCreator["RecommendedPlans"] = "recommendedPlans";
    ActionCreator["Routing"] = "routing";
    ActionCreator["Teams"] = "teams";
    ActionCreator["TeamsTab"] = "teamsTab";
    ActionCreator["Template"] = "template";
    ActionCreator["Tenant"] = "tenant";
    ActionCreator["UnoShell"] = "unoShell";
    ActionCreator["Upsell"] = "upsell";
    ActionCreator["User"] = "user";
    ActionCreator["ViewTransforms"] = "viewTransforms";
    ActionCreator["BasicPlanView"] = "basicPlanView";
    ActionCreator["CampaignCalloutView"] = "campaignCalloutView";
    ActionCreator["CreatePortfolioView"] = "createPortfolioView";
    ActionCreator["CreatePlanView"] = "createPlanView";
    ActionCreator["CreateTaskView"] = "createTaskView";
    ActionCreator["CreateTeamsTabView"] = "createTeamsTabView";
    ActionCreator["CopyPlanView"] = "copyPlanView";
    ActionCreator["LeftNavView"] = "leftNavView";
    ActionCreator["MyDayView"] = "myDayView";
    ActionCreator["MyTasksView"] = "myTasksView";
    ActionCreator["MyPlansView"] = "myPlansView";
    ActionCreator["MyPortfoliosView"] = "myPortfoliosView";
    ActionCreator["UnoShellView"] = "unoShellView";
    ActionCreator["ConvertPlanView"] = "convertPlanView";
    ActionCreator["SharePlanView"] = "sharePlanView";
    ActionCreator["PublishingLeftNavView"] = "publishingLeftNavView";
    ActionCreator["SuiteNavView"] = "suiteNavView";
})(ActionCreator || (ActionCreator = {}));
