// Actions
import { ClearAccessTokenAction, FetchAccessTokenAction, FetchAccessTokenFailedAction, FetchAccessTokenSucceededAction } from "@ms/uno-actions/lib/local/auth/AccessTokenActions";
// Modules
import { BaseLogicModule } from "../BaseLogicModule";
import { isTokenExpiryWithinBufferPeriod } from "@ms/uno-utilities/lib/local/AuthUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
export class AuthModule extends BaseLogicModule {
    get name() {
        return "AuthModule";
    }
    async fetchAccessTokenAsync(resource, claims, silent) {
        this.loggers.traceLogger.logTrace(0x1e45d557 /* tag_4r3vx */ , TraceLevel.Verbose, `Fetch Access Token [Resource=${resource}]`);
        const tokenInfo = this.authStore.getAccessToken(resource, claims);
        if (tokenInfo.data !== null) {
            const expiryBuffer = this.config.settings.auth.authTokenExpiryBufferSeconds;
            const validationResult = !isTokenExpiryWithinBufferPeriod(tokenInfo.data.expiry, expiryBuffer);
            // If the token is not expired or within expiry buffer period, return it
            if (validationResult) {
                this.loggers.traceLogger.logTrace(0x1e45d556 /* tag_4r3vw */ , TraceLevel.Verbose, `Fetch Access Token Success [Resource=${resource}][Source=Store][Expiry=${tokenInfo.data.expiry?.toISOString()}][Buffer=${expiryBuffer}][Valid=${validationResult}]`);
                return Promise.resolve(tokenInfo.data);
            }
            this.loggers.traceLogger.logTrace(0x1e45d555 /* tag_4r3vv */ , TraceLevel.Info, `Fetch Access Token Validation Failure [Resource=${resource}][Source=Store][Expiry=${tokenInfo.data.expiry?.toISOString()}][Buffer=${expiryBuffer}][Valid=${validationResult}]`);
        }
        // If the token is expired or within expiry buffer period, fetch a new one
        return this.fetchAccessTokenInternal(resource, claims, silent);
    }
    async refreshAccessTokenAsync(resource, claims, silent) {
        this.loggers.traceLogger.logTrace(0x1e45d554 /* tag_4r3vu */ , TraceLevel.Verbose, `Refresh Access Token Started for [Resource=${resource}]`);
        const action = new ClearAccessTokenAction(resource);
        await this.dispatcher.dispatchActionAsync(action);
        return this.fetchAccessTokenInternal(resource, claims, silent);
    }
    async fetchAccessTokenInternal(resource, claims, silent) {
        const actionId = LocalIdGenerator.generate();
        const action = new FetchAccessTokenAction(actionId, resource, claims);
        await this.dispatcher.dispatchActionAsync(action);
        this.loggers.traceLogger.logTrace(0x1e259847 /* tag_4jz7h */ , TraceLevel.Verbose, `Fetch Access Token started for [Resource=${resource}][Silent=${silent}]`);
        try {
            const params = {
                resource: resource,
                claims: claims,
                silent
            };
            const accessTokenWithExpiry = await this.authService.fetchAccessTokenAsync(params);
            this.loggers.traceLogger.logTrace(0x1e45d553 /* tag_4r3vt */ , TraceLevel.Verbose, `Fetch Access Token Success [Resource=${resource}][Source=AuthService]`);
            const action = new FetchAccessTokenSucceededAction(actionId, resource, accessTokenWithExpiry.accessToken, accessTokenWithExpiry.expiry, claims);
            await this.dispatcher.dispatchActionAsync(action);
            return Promise.resolve(accessTokenWithExpiry);
        } catch (error) {
            const failedRequestResult = error;
            this.loggers.traceLogger.logTrace(0x1e45d552 /* tag_4r3vs */ , TraceLevel.Error, `Fetch Access token Failure [Resource=${resource}][Source=AuthService][Subsource=${failedRequestResult.response == null ? "Host" : "OBOService"}]`);
            const action = new FetchAccessTokenFailedAction(actionId, resource, claims, failedRequestResult.error ?? undefined);
            await this.dispatcher.dispatchActionAsync(action);
            return Promise.reject(failedRequestResult);
        }
    }
    /**
     * Initializes an instance of an Entity Module
     * @param dispatcher The dispatcher the SPA uses to dispatch actions
     * @param services Providers for the services
     * @param stores Providers for the stores
     * @param loggers Loggers for telemetry
     * @param configProvider Delegate to retrieve the app configuration
     */ constructor(dispatcher, authService, authStore, loggers, configProvider){
        super(dispatcher, loggers, configProvider);
        this.authStore = authStore;
        this.authService = authService;
    }
}
