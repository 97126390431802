// Entity
/**
 * Upsert a versioned baseline into a table
 * @param table The table to upsert the baseline into
 * @param id Id of the entity
 * @param newBaseline New baseline to upsert
 */ export function upsertVersionedBaseline(table, id, newBaseline) {
    const current = table.get(id);
    if (!current) {
        table.add(id, newBaseline);
        return true;
    } else if (current.baseline.itemVersion < newBaseline.itemVersion) {
        current.updateBaseline(newBaseline);
        return true;
    }
    return false;
}
/**
 * Type guard to check if a store implements IPersistedStore
 * @param store The store to check
 */ export function isPersistedStore(store) {
    return store != null && typeof store === "object" && typeof store.getPersistedTables === "function";
}
