// Action
import { isTrackableAction } from "../actions/ITrackableAction";
// Errors
import { NotSupportedError } from "@ms/uno-errors/lib/local/errors/NotSupportedError";
// Utilities
import remove from "lodash/remove";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
export class AsyncDispatcher {
    dispatchActionAsync(action) {
        return this.batchDispatchActionsAsync(action);
    }
    async batchDispatchActionsAsync(...actions) {
        if (this.enableAsyncDispatchV2) {
            return this.batchDispatchActionsInternalAsync(...actions);
        } else {
            return this.legacyBatchDispatchActionsInternalAsync(...actions);
        }
    }
    async batchDispatchActionsInternalAsync(...actions) {
        await Promise.all(actions.map(async (action)=>{
            if (action.isLoggingEnabled()) {
                this.logAction({
                    name: action.type,
                    exportName: action.getExportName(),
                    payload: action.loggingData()
                });
            }
            const storeTypes = this.registry.getStoreTypesForAction(action.type);
            await Promise.all(storeTypes.map(async (storeType)=>{
                try {
                    const store = await this.storeProvider(storeType)();
                    this.logTrace(0x1e2e0660 /* tag_4l6z6 */ , TraceLevel.Verbose, `Dispatching action [ActionType=${action.type}][Store=${store.name}]`);
                    // If there are pending subscriptions for this store, register them now
                    this.applyPendingSubscriptions(storeType);
                    store.handleAction(action);
                } catch (e) {
                    this.logTrace(0x1e2e0661 /* tag_4l6z7 */ , TraceLevel.Error, `Error while dispatching action [ActionType=${action.type}][Store=${storeType}][Error=${ErrorUtilities.getMessage(e)}]`);
                }
            }));
        }));
        for (const callback of this.onActionDispatchCompleteSubscribers.slice()){
            callback();
        }
    }
    async legacyBatchDispatchActionsInternalAsync(...actions) {
        const renderMeasurementActionInfo = [];
        const trackableActions = [];
        for (const action of actions){
            if (action.isLoggingEnabled()) {
                this.logAction({
                    name: action.type,
                    exportName: action.getExportName(),
                    payload: action.loggingData()
                });
            }
            if (isTrackableAction(action)) {
                renderMeasurementActionInfo.push({
                    actionId: action.actionId,
                    actionName: action.type
                });
                trackableActions.push(action);
            }
            const storeTypes = this.registry.getStoreTypesForAction(action.type);
            const storePromises = storeTypes.map(async (storeType)=>{
                try {
                    const store = await this.storeProvider(storeType)();
                    // If there are pending subscriptions for this store, register them now
                    this.applyPendingSubscriptions(storeType);
                    return store;
                } catch (e) {
                    if (e instanceof NotSupportedError) {
                        this.logTrace(0x1e2e0662 /* tag_4l6z8 */ , TraceLevel.Verbose, `Unsupported store found. Ignoring. [ActionType=${action.type}][StoreType=${storeType}]`);
                    } else {
                        throw e;
                    }
                }
            });
            const stores = await Promise.all(storePromises).catch((e)=>{
                this.logTrace(0x1e25a854 /* tag_4j07u */ , TraceLevel.Error, `Error while dispatching action [ActionType=${action.type}][Error=${ErrorUtilities.getMessage(e)}]`);
                throw e;
            });
            for (const store of stores){
                if (store) {
                    try {
                        this.logTrace(0x1e25a855 /* tag_4j07v */ , TraceLevel.Verbose, `Dispatching action [ActionType=${action.type}][Store=${store.name}]`);
                        store.handleAction(action);
                    } catch (e) {
                        this.logTrace(0x1e2e065f /* tag_4l6z5 */ , TraceLevel.Warning, `Error while dispatching action [ActionType=${action.type}][Store=${store.name}][Error=${ErrorUtilities.getMessage(e)}]`);
                    }
                }
            }
        }
        // Clone the subscribers in case a callback removes a subscriber mid-iteration
        const cloneSubscribers = this.onActionDispatchCompleteSubscribers.slice();
        for (const callback of cloneSubscribers){
            callback(renderMeasurementActionInfo);
        }
        const cloneSubscribersForTrackableActions = this.onTrackableActiOnDispatchCompleteSubscribers.slice();
        for (const callback of cloneSubscribersForTrackableActions){
            callback(trackableActions);
        }
    }
    addOnActionDispatchComplete(callback) {
        this.onActionDispatchCompleteSubscribers.push(callback);
    }
    removeOnActionDispatchComplete(callback) {
        remove(this.onActionDispatchCompleteSubscribers, (cb)=>{
            return cb === callback;
        });
    }
    subscribeOnActionDispatchComplete(callback) {
        this.onTrackableActiOnDispatchCompleteSubscribers.push(callback);
    }
    registerSubscription(stores, callback) {
        for (const store of stores){
            const storeReference = this.storeReferenceProvider(store)();
            if (storeReference) {
                // If the store is available subscribe to it directly
                storeReference.subscribeToStore(callback);
            } else {
                // Otherwise, mark it as pending so that we can subscribe when the store is made available
                if (!this.pendingStoreSubscriptions[store]) {
                    this.pendingStoreSubscriptions[store] = [];
                }
                // Add callback to the pending subscriptions if not already added
                if (!this.pendingStoreSubscriptions[store].includes(callback)) {
                    this.pendingStoreSubscriptions[store].push(callback);
                }
            }
        }
    }
    unregisterSubscription(stores, callback) {
        for (const store of stores){
            // Cleanup the subscriptions from the store if it is available
            const storeReference = this.storeReferenceProvider(store)();
            if (storeReference) {
                storeReference.unsubscribeFromStore(callback);
            }
            // Cleanup the pending subscriptions
            if (this.pendingStoreSubscriptions[store]) {
                remove(this.pendingStoreSubscriptions[store], (cb)=>cb === callback);
            }
        }
    }
    /**
     * Applies the pending subscriptions for the given store type
     * @param storeType The store type
     */ applyPendingSubscriptions(storeType) {
        const storeReference = this.storeReferenceProvider(storeType)();
        if (storeReference && this.pendingStoreSubscriptions[storeType]) {
            this.pendingStoreSubscriptions[storeType].forEach((subscriber)=>{
                storeReference.subscribeToStore(subscriber);
            });
            delete this.pendingStoreSubscriptions[storeType];
        }
    }
    /**
     * Creates an instance of the AsyncDispatcher
     * @param registry The registry to use for store lookups
     * @param storeProvider The store provider to use for store lookups (download the store if not available)
     * @param storeReferenceProvider The store reference provider to use for store lookups (get the store reference if loaded)
     * @param logAction The action logging function
     * @param logTrace The trace logging function
     * @param enableAsyncDispatchV2 Flag to enable the new async dispatch implementation
     */ constructor(registry, storeProvider, storeReferenceProvider, logAction, logTrace, enableAsyncDispatchV2){
        this.registry = registry;
        this.storeProvider = storeProvider;
        this.storeReferenceProvider = storeReferenceProvider;
        this.logAction = logAction;
        this.logTrace = logTrace;
        this.enableAsyncDispatchV2 = enableAsyncDispatchV2;
        this.onActionDispatchCompleteSubscribers = [];
        this.onTrackableActiOnDispatchCompleteSubscribers = [];
        this.pendingStoreSubscriptions = {};
    }
}
