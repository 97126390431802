import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for TenantStore, for use in the registry */ export const TenantStoreActionTypes = [
    ActionType.CheckCurrentUserToGroupsMembershipsSucceededAction,
    ActionType.FetchOrganizationInfoAction,
    ActionType.FetchOrganizationInfoFailedAction,
    ActionType.FetchOrganizationInfoSucceededAction,
    ActionType.FetchTenantFeatureSettingsAction,
    ActionType.FetchTenantFeatureSettingsFailedAction,
    ActionType.FetchTenantFeatureSettingsSucceededAction,
    ActionType.FetchTenantFeedbackSettingsAction,
    ActionType.FetchTenantFeedbackSettingsFailedAction,
    ActionType.FetchTenantFeedbackSettingsSucceededAction,
    ActionType.FetchTenantGroupSettingsAction,
    ActionType.FetchTenantGroupSettingsFailedAction,
    ActionType.FetchTenantGroupSettingsSucceededAction,
    ActionType.FetchTenantPortfoliosEnabledSettingAction,
    ActionType.FetchTenantPortfoliosEnabledSettingFailedAction,
    ActionType.FetchTenantPortfoliosEnabledSettingSucceededAction,
    ActionType.FetchTenantSensitivityLabelsAction,
    ActionType.FetchTenantSensitivityLabelsFailedAction,
    ActionType.FetchTenantSensitivityLabelsSucceededAction,
    ActionType.FetchTenantSensitivityPolicySettingsAction,
    ActionType.FetchTenantSensitivityPolicySettingsFailedAction,
    ActionType.FetchTenantSensitivityPolicySettingsSucceededAction,
    ActionType.SetGroupNamePrefixSuffixAction
];
