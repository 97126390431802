import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for StatusReportStore, for use in the registry */ export const StatusReportStoreActionTypes = [
    ActionType.GenerateStatusReportAction,
    ActionType.GenerateStatusReportSucceededAction,
    ActionType.GenerateStatusReportFailedAction,
    ActionType.SetStatusReportActiveAction,
    ActionType.SetStatusReportInactiveAction,
    ActionType.FetchPastReportsAction,
    ActionType.FetchPastReportsSucceededAction,
    ActionType.FetchPastReportsFailedAction
];
