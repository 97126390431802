// Constants
import { HttpStatus, HttpStatusText } from "@ms/uno-constants/lib/local/HttpStatusConstants";
// Utilities
import { isAuthenticationInsuficientClaimsResponse, extractClaimsFromResponse } from "@ms/uno-utilities/lib/local/auth/AuthUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/** Ajax client that can detect the authentication failure, refresh authentication token and retry the request*/ export class TokenRefreshAndRetryAjaxClient {
    async executeRequest(url, options) {
        try {
            return await this.innerAjaxClient.executeRequest(url, options);
        } catch (e) {
            const errorResponse = e?.response;
            if (errorResponse && errorResponse instanceof Response) {
                if (await isAuthenticationInsuficientClaimsResponse(errorResponse, this.loggers.traceLogger)) {
                    this.loggers.traceLogger.logTrace(0x1e440385 /* tag_4raof */ , TraceLevel.Warning, "executeRequest - Insufficient claims error");
                    const claims = await extractClaimsFromResponse(this.loggers.traceLogger, errorResponse);
                    return this.refreshTokenAndRetry(url, options, claims);
                } else if (errorResponse.status === HttpStatus.Unauthorized) {
                    return this.refreshTokenAndRetry(url, options);
                }
            }
            throw e;
        }
    }
    async refreshTokenAndRetry(url, options, claims) {
        try {
            await this.refreshAccessToken(this.authResource, claims, this.silentAuth);
        } catch (err) {
            const errorText = "Token refresh failed after 401";
            const error = err;
            const response = new Response(errorText, {
                status: HttpStatus.Unauthorized,
                statusText: HttpStatusText.Unauthorized
            });
            error.response = response;
            this.loggers.traceLogger.logTrace(0x1e4455db /* tag_4rfx1 */ , TraceLevel.Warning, "refreshTokenAndRetry - " + errorText);
            throw error;
        }
        return await this.innerAjaxClient.executeRequest(url, options);
    }
    /**
     * Initializes a new AuthRefreshAndRetryClient instance
     * @param innerAjaxClient Ajax client
     * @param authResource Resource to be used for authentication
     * @param refreshAccessToken Delegate function that returns a promise to refresh access token
     * @param silentAuth If true, the token will be acquired "silently" (without any user interaction like pop ups)
     */ constructor(innerAjaxClient, authResource, refreshAccessToken, loggers, silentAuth){
        this.innerAjaxClient = innerAjaxClient;
        this.authResource = authResource;
        this.refreshAccessToken = refreshAccessToken;
        this.loggers = loggers;
        this.silentAuth = silentAuth;
    }
}
