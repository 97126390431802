/** Planner browser cache async storage prefix */ export const PlannerAsyncStoragePrefix = "Planner";
/** Planner browser cache async storage schema version V1
 * This version is used for shipping Mru store persistence - mru items and collection status
 */ export const PlannerAsyncStorageSchemaVersionV1 = 1;
/** Planner browser cache async storage schema version V2
 * This version will be used for shipping persistence for required stores for myDay, myTasks, BasicPlan and myPlans pages
 */ export const PlannerAsyncStorageSchemaVersionV2 = 2;
export var PersistedObjectStoreKeys;
(function(PersistedObjectStoreKeys) {
    PersistedObjectStoreKeys["Mru"] = "server_doc_id";
    PersistedObjectStoreKeys["Plans"] = "id";
    PersistedObjectStoreKeys["Buckets"] = "id";
    PersistedObjectStoreKeys["Tasks"] = "id";
    PersistedObjectStoreKeys["Status"] = "key";
})(PersistedObjectStoreKeys || (PersistedObjectStoreKeys = {}));
export var PersistedObjectStoreIndexNames;
(function(PersistedObjectStoreIndexNames) {
    PersistedObjectStoreIndexNames["MruPinnedIndex"] = "isPinnedString";
    PersistedObjectStoreIndexNames["BucketsPlanIndex"] = "planId";
    PersistedObjectStoreIndexNames["TasksPlanIndex"] = "planId";
    PersistedObjectStoreIndexNames["TasksMyDayDateIndex"] = "myDayDate";
    PersistedObjectStoreIndexNames["TasksAssignmentsIndex"] = "assigneeIds";
})(PersistedObjectStoreIndexNames || (PersistedObjectStoreIndexNames = {}));
