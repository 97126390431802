// Stores
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
import { ActivityStoreActionTypes } from "@ms/uno-stores/lib/local/stores/activity/ActivityStoreActions";
import { AggregatesStoreActionTypes } from "@ms/uno-stores/lib/local/stores/aggregates/AggregatesStoreActions";
import { AppContextStoreActionTypes } from "@ms/uno-stores/lib/local/stores/appContext/AppContextStoreActions";
import { AuthStoreActionTypes } from "@ms/uno-stores/lib/local/stores/auth/AuthStoreActions";
import { CampaignStoreActionTypes } from "@ms/uno-stores/lib/local/stores/campaign/CampaignStoreActions";
import { CapabilitiesStoreActionTypes } from "@ms/uno-stores/lib/local/stores/capabilities/CapabilitiesStoreActions";
import { ContainerStoreActionTypes } from "@ms/uno-stores/lib/local/stores/container/ContainerStoreActions";
import { ConversationsStoreActionTypes } from "@ms/uno-stores/lib/local/stores/conversations/ConversationsStoreActions";
import { CreateTaskFromMessageStoreActionTypes } from "@ms/uno-stores/lib/local/stores/createTaskFromMessage/CreateTaskFromMessageStoreActions";
import { DriveItemStoreActionTypes } from "@ms/uno-stores/lib/local/stores/driveItem/DriveItemStoreActions";
import { GroupsStoreActionTypes } from "@ms/uno-stores/lib/local/stores/groups/GroupsStoreActions";
import { IntelligenceStoreActionTypes } from "@ms/uno-stores/lib/local/stores/intelligence/IntelligenceStoreActions";
import { ModalStoreActionTypes } from "@ms/uno-stores/lib/local/stores/modal/ModalStoreActions";
import { MruStoreActionTypes } from "@ms/uno-stores/lib/local/stores/mru/MruStoreActions";
import { NotificationStoreActionTypes } from "@ms/uno-stores/lib/local/stores/notification/NotificationStoreActions";
import { OperationTrackingStoreActionTypes } from "@ms/uno-stores/lib/local/stores/operationTracking/OperationTrackingStoreActions";
import { OrgStoreActionTypes } from "@ms/uno-stores/lib/local/stores/org/OrgStoreActions";
import { PerformanceCollectionStoreActionTypes } from "@ms/uno-stores/lib/local/stores/performanceCollection/PerformanceCollectionStoreActions";
import { BasicPermissionsStoreActionTypes } from "@ms/uno-stores/lib/local/stores/permissions/BasicPermissionsStoreActions";
import { PremiumPlanPermissionsStoreActionTypes } from "@ms/uno-stores/lib/local/stores/permissions/PremiumPlanPermissionsStoreActions";
import { BasicBucketStoreActionTypes } from "@ms/uno-stores/lib/local/stores/legacyPlanner/bucket/BasicBucketStoreActions";
import { DeltaSyncStoreActionTypes } from "@ms/uno-stores/lib/local/stores/legacyPlanner/deltaSync/DeltaSyncStoreActions";
import { BasicPlanStoreActionTypes } from "@ms/uno-stores/lib/local/stores/legacyPlanner/plan/BasicPlanStoreActions";
import { BasicTaskStoreActionTypes } from "@ms/uno-stores/lib/local/stores/legacyPlanner/task/BasicTaskStoreActions";
import { PortfolioStoreActionTypes } from "@ms/uno-stores/lib/local/stores/portfolio/PortfolioStoreActions";
import { PremiumPlanStoreActionTypes } from "@ms/uno-stores/lib/local/stores/project/premiumPlan/PremiumPlanStoreActions";
import { NewsBarStoreActionTypes } from "@ms/uno-stores/lib/local/stores/newsBar/NewsBarStoreActions";
import { NavigationStoreActionTypes } from "@ms/uno-stores/lib/local/stores/route/NavigationStoreActions";
import { RouteStoreActionTypes } from "@ms/uno-stores/lib/local/stores/route/RouteStoreActions";
import { SuiteNavStoreActionTypes } from "@ms/uno-stores/lib/local/stores/suiteNav/SuiteNavStoreActions";
import { TaskStoreActionTypes } from "@ms/uno-stores/lib/local/stores/task/TaskStoreActions";
import { TeamsStoreActionTypes } from "@ms/uno-stores/lib/local/stores/teams/TeamsStoreActions";
import { TemplateStoreActionTypes } from "@ms/uno-stores/lib/local/stores/templates/TemplateStoreActions";
import { TenantStoreActionTypes } from "@ms/uno-stores/lib/local/stores/tenant/TenantStoreActions";
import { UnoShellStoreActionTypes } from "@ms/uno-stores/lib/local/stores/unoShell/UnoShellStoreActions";
import { BasicPlanConversionStatusStoreActionTypes } from "@ms/uno-stores/lib/local/stores/upsell/BasicPlanConversionStatusStoreActions";
import { PlanConversionStoreActionTypes } from "@ms/uno-stores/lib/local/stores/upsell/PlanConversionStoreActions";
import { UpsellStoreActionTypes } from "@ms/uno-stores/lib/local/stores/upsell/UpsellStoreActions";
import { UserMembershipStoreActionTypes } from "@ms/uno-stores/lib/local/stores/user/UserMembershipStoreActions";
import { UserStoreActionTypes } from "@ms/uno-stores/lib/local/stores/user/UserStoreActions";
import { ViewStoreActionTypes } from "@ms/uno-stores/lib/local/stores/view/ViewStoreActions";
import { RecommendedPlansStoreActionTypes } from "@ms/uno-stores/lib/local/stores/recommendedPlan/RecommendedPlansStoreActions";
import { ProjectHomeResourceStoreActionTypes } from "@ms/uno-stores/lib/local/stores/projectHome/ProjectHomeResourceStoreActions";
import { PublishingStoreActionTypes } from "@ms/uno-stores/lib/local/stores/publishing/PublishingStoreActions";
import { StatusReportStoreActionTypes } from "@ms/uno-stores/lib/local/stores/statusReport/StatusReportStoreActions";
import { WorkflowCopilotStoreActionTypes } from "@ms/uno-stores/lib/local/stores/workflowCopilot/WorkflowCopilotStoreActions";
/**
 * Registry descriptor of actions that are handled by Uno stores
 */
export const UnoRegistryDescriptor = {
    [Store.Activity]: ActivityStoreActionTypes,
    [Store.Aggregates]: AggregatesStoreActionTypes,
    [Store.AppContext]: AppContextStoreActionTypes,
    [Store.Auth]: AuthStoreActionTypes,
    [Store.BasicBucket]: BasicBucketStoreActionTypes,
    [Store.BasicPlan]: BasicPlanStoreActionTypes,
    [Store.BasicPlanConversionStatus]: BasicPlanConversionStatusStoreActionTypes,
    [Store.BasicPermissions]: BasicPermissionsStoreActionTypes,
    [Store.BasicTask]: BasicTaskStoreActionTypes,
    [Store.Campaign]: CampaignStoreActionTypes,
    [Store.Capabilities]: CapabilitiesStoreActionTypes,
    [Store.Container]: ContainerStoreActionTypes,
    [Store.Conversations]: ConversationsStoreActionTypes,
    [Store.CreateTaskFromMessage]: CreateTaskFromMessageStoreActionTypes,
    [Store.DeltaSync]: DeltaSyncStoreActionTypes,
    [Store.DriveItem]: DriveItemStoreActionTypes,
    [Store.Groups]: GroupsStoreActionTypes,
    [Store.Intelligence]: IntelligenceStoreActionTypes,
    [Store.Modal]: ModalStoreActionTypes,
    [Store.Mru]: MruStoreActionTypes,
    [Store.NewsBar]: NewsBarStoreActionTypes,
    [Store.Navigation]: NavigationStoreActionTypes,
    [Store.Notification]: NotificationStoreActionTypes,
    [Store.OperationTracking]: OperationTrackingStoreActionTypes,
    [Store.Org]: OrgStoreActionTypes,
    [Store.PerformanceCollection]: PerformanceCollectionStoreActionTypes,
    [Store.PlanConversion]: PlanConversionStoreActionTypes,
    [Store.Portfolio]: PortfolioStoreActionTypes,
    [Store.PremiumPlan]: PremiumPlanStoreActionTypes,
    [Store.PremiumPlanPermissions]: PremiumPlanPermissionsStoreActionTypes,
    [Store.Publishing]: PublishingStoreActionTypes,
    [Store.RecommendedPlans]: RecommendedPlansStoreActionTypes,
    [Store.ProjectHomeResource]: ProjectHomeResourceStoreActionTypes,
    [Store.Route]: RouteStoreActionTypes,
    [Store.StatusReport]: StatusReportStoreActionTypes,
    [Store.SuiteNav]: SuiteNavStoreActionTypes,
    [Store.Teams]: TeamsStoreActionTypes,
    [Store.Tasks]: TaskStoreActionTypes,
    [Store.Templates]: TemplateStoreActionTypes,
    [Store.Tenant]: TenantStoreActionTypes,
    [Store.UnoShell]: UnoShellStoreActionTypes,
    [Store.Upsell]: UpsellStoreActionTypes,
    [Store.User]: UserStoreActionTypes,
    [Store.UserMembership]: UserMembershipStoreActionTypes,
    [Store.View]: ViewStoreActionTypes,
    [Store.WorkflowCopilot]: WorkflowCopilotStoreActionTypes,
};
