// Actions
import { PushSubRouteAction } from "@ms/uno-actions/lib/local/route/PushSubRouteAction";
import { ReplaceSubRouteAction } from "@ms/uno-actions/lib/local/route/ReplaceSubRouteAction";
import { NavigateToViewAction } from "@ms/uno-actions/lib/local/route/NavigateToViewAction";
// Constants
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { NotificationId } from "@ms/uno-constants/lib/local/NotificationConstants";
import { PlanType } from "@ms/uno-constants/lib/local/AppConstants";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { generateLogStringFromAjaxClientError } from "@ms/uno-services/lib/local/utilities/ServiceUtilities";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
// Utilities
import { generateRouteDescriptorFromRoutingDetailsForPlan, generateRouteDescriptorFromRoutingDetailsForPortfolio } from "@ms/uno-routing";
import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
import { getOrgUrlFromExternalProjectUrl } from "@ms/uno-utilities/lib/local/UrlUtilities";
/**
 * Action creator dealing with routing
 */ export class RoutingActionCreator {
    async navigate(routeChangeDescriptor, skipHistoryPushState) {
        await this.dispatcher.dispatchActionAsync(new NavigateToViewAction(routeChangeDescriptor, Date.now(), skipHistoryPushState));
        // Announce the navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        let pageString = null;
        switch(routeChangeDescriptor.subRouteType){
            case SubRouteType.MyDay:
                pageString = Strings.Views_MyDayTitle;
                break;
            case SubRouteType.MyTasks:
                pageString = Strings.Views_MyTasksTitle;
                break;
            case SubRouteType.MyPlans:
                pageString = Strings.Views_MyPlansTitle;
                break;
            default:
                break;
        }
        if (pageString) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_ChangeViewNotificationToastTitle", [
                pageString
            ]);
        }
    }
    async navigateToPlan(routingDetails, queryParams) {
        const planStore = await this.stores.basicPlan();
        const orgStore = await this.stores.org();
        const mruStore = await this.stores.mru();
        let updatedRoutingDetails = routingDetails;
        let isNamedOrg = false;
        if (routingDetails.planType === PlanType.Premium && routingDetails.orgId === "") {
            const defaultOrgUrl = orgStore?.getDefaultCdsInstance().data?.cdsUrl ?? null;
            const mruItem = mruStore.getMruItemByServerDocId(routingDetails.planId);
            // TODO: #853025 - Mapping navigation failures to common notification id in Routing AC
            if (!mruItem) {
                this.loggers.traceLogger.logTrace(0x1e24221d /* tag_4jci3 */ , TraceLevel.Error, `mruItem is null.`);
                return;
            }
            const orgUrl = getOrgUrlFromExternalProjectUrl(mruItem.url); // for named org plans, mru item's url host name is the org url
            if (orgUrl !== defaultOrgUrl) {
                isNamedOrg = true;
                // checking if default org url is different from the org url in the plan (if true: named org plan)
                try {
                    updatedRoutingDetails = await this.getUpdatedRoutingDetailsForNamedOrgPlan(routingDetails.planId, orgUrl);
                } catch (error) {
                    const failedResult = error;
                    this.loggers.traceLogger.logTrace(0x1e256659 /* tag_4jwzz */ , TraceLevel.Error, `Failed to navigate to plan - ${generateLogStringFromAjaxClientError(failedResult)}`);
                    const notificationModule = await this.logicModuleProviders.notification();
                    await notificationModule.showRequestFailuresAsync(error, NotificationId.GetOrgInfoForPlanError, routingDetails.planId);
                    return;
                }
            }
        }
        const defaultPlanId = planStore.getDefaultPlanId();
        const flaggedEmailPlanId = planStore.getFlaggedEmailPlanId();
        await this.navigate(generateRouteDescriptorFromRoutingDetailsForPlan(updatedRoutingDetails, queryParams, defaultPlanId ?? undefined, flaggedEmailPlanId ?? undefined));
        // Announce the plan navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        if (routingDetails.planName) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPlanNotificationToastTitle", [
                routingDetails.planName
            ]);
        } else {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPlanNotificationNoNameToastTitle");
        }
        // Log engagement telemetry for opening a plan
        this.loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.RoutingActionCreator, EngagementTelemetryConstants.OpenPlan, {
            PlanType: routingDetails.planType,
            isNamedOrgPlan: isNamedOrg.toString()
        });
    }
    /**
     * Gets updated the routing details in case of named org plan
     * @param planId Plan Id
     * @param orgUrl org url of named org plan
     */ async getUpdatedRoutingDetailsForNamedOrgPlan(planId, orgUrl) {
        const orgModule = await this.logicModuleProviders.org();
        const orgInfo = await orgModule.fetchOrgInfo(orgUrl);
        return {
            planId: planId,
            planType: PlanType.Premium,
            orgId: orgInfo.orgId
        };
    }
    async navigateToPortfolio(routingDetails, queryParams) {
        await this.navigate(generateRouteDescriptorFromRoutingDetailsForPortfolio(routingDetails, queryParams));
        // Announce the portfolio navigation to the screen reader
        const notificationModule = await this.logicModuleProviders.notification();
        if (routingDetails.portfolioName) {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPortfolioNotificationToastTitle", [
                routingDetails.portfolioName
            ]);
        } else {
            await notificationModule.enqueueHiddenToastAsync("Toasts_OpeningPortfolioNotificationNoNameToastTitle");
        }
        // Log engagement telemetry for opening a portfolio
        this.loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.RoutingActionCreator, EngagementTelemetryConstants.OpenPortfolio);
    }
    async pushSubRoute(subRoute) {
        await this.dispatcher.dispatchActionAsync(new PushSubRouteAction(subRoute));
    }
    async replaceSubRoute(subRoute) {
        await this.dispatcher.dispatchActionAsync(new ReplaceSubRouteAction(subRoute));
    }
    /**
     * Initializes a new instance of the Router action creator.
     * @param dispatcher The dispatcher the SPA uses to dispatch actions.
     * @param logicModuleProviders Set of providers for logic modules
     * @param loggers Loggers for telemetry
     */ constructor(dispatcher, logicModuleProviders, stores, loggers){
        this.dispatcher = dispatcher;
        this.logicModuleProviders = logicModuleProviders;
        this.stores = stores;
        this.loggers = loggers;
    }
}
