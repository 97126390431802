// Actions
import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
import { legacyTaskListTypePrefix, QueryParameterKeys, SubRouteKeys, SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
// Models
import { ModalType } from "@ms/uno-models/lib/local/client/modal/ModalType";
import { fillSubRouteDefaults } from "@ms/uno-routing";
// Stores
import { EventPublishingStore } from "@ms/uno-fluxcore/lib/local/stores/EventPublishingStore";
// Utilities
import { getValueForKey, removeKeyFromSubRoute, serializeRouteDescriptor, setSubRouteKeyValue } from "@ms/uno-routing";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { stringOrdinalCaseInsensitiveCompare } from "@ms/uno-utilities/lib/local/Comparators";
import { stringToEnum } from "@ms/uno-utilities/lib/local/EnumUtilities";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { logUnreachableAction } from "../../utilities/LoggingUtilities";
/**
 * Routing Store for navigation purposes
 */ export class RouteStore extends EventPublishingStore {
    get name() {
        return "RouteStore";
    }
    getCurrentPath() {
        return this.formRoutePath(this.currentRoute);
    }
    getCurrentSubRouteType() {
        return this.currentRoute.subRouteType;
    }
    getCurrentSubRoute() {
        return this.currentRoute.subRoute;
    }
    getCurrentViewId() {
        return this.generateCacheKey(this.currentRoute.subRouteType, this.currentRoute.subRoute);
    }
    getCurrentQueryParameters() {
        return this.currentRoute.queryParameters ?? {};
    }
    handleAction(action) {
        let storeChanged = false;
        switch(action.type){
            case ActionType.NavigateToView:
                storeChanged = this.handleNavigateToView(action);
                break;
            case ActionType.PushSubRoute:
                storeChanged = this.handlePushSubRoute(action);
                break;
            case ActionType.ReplaceSubRoute:
                storeChanged = this.handleReplaceSubRoute(action);
                break;
            case ActionType.OpenModalAction:
                if (this.configProvider().flights.EnableTaskEditorRouting) {
                    storeChanged = this.handleOpenModal(action);
                }
                break;
            case ActionType.CloseModalAction:
                storeChanged = this.handleCloseModal(action);
                break;
            default:
                logUnreachableAction(this, this.loggers, action);
                break;
        }
        if (storeChanged) {
            this.publishStoreChangedEvent();
        }
    }
    /**
     * Handles the navigate to view action
     * @param action the NavigateToViewAction action containing the navigation URL
     * @returns storeChanged - true if navigation route has changed, false otherwise
     */ handleNavigateToView(action) {
        const { routeChangeDescriptor } = action;
        const currentPath = this.formRoutePath(this.currentRoute);
        if (this.shouldUseCachedSubRoute(routeChangeDescriptor.subRouteType, routeChangeDescriptor.subRoute)) {
            const cachedSubRoute = this.subRouteCache[this.generateCacheKey(routeChangeDescriptor.subRouteType, routeChangeDescriptor.subRoute)] ?? [];
            // Use the cached sub route if available for the new route or else use the passed sub route
            this.currentRoute.subRoute = cachedSubRoute.length === 0 ? routeChangeDescriptor.subRoute : cachedSubRoute;
        } else {
            this.currentRoute.subRoute = routeChangeDescriptor.subRoute;
        }
        // Fill in sub route defaults if needed
        this.currentRoute.subRoute = fillSubRouteDefaults(this.currentRoute.subRoute, routeChangeDescriptor.subRouteType);
        this.currentRoute.subRouteType = routeChangeDescriptor.subRouteType;
        this.currentRoute.queryParameters = routeChangeDescriptor.queryParameters;
        // Write through and persist to persistent storage if supported
        this.persistRoute();
        // Determine the new path and if there was any actual change
        const newPath = this.formRoutePath(this.currentRoute);
        if (stringOrdinalCaseInsensitiveCompare(currentPath, newPath) === 0) {
            return false;
        }
        // Push the new path to the browser history if there was a change, and we aren't skipping history push state
        if (!action.skipHistoryPushState) {
            history.pushState(null, "", newPath);
        }
        return true;
    }
    /**
     * Handles the push sub route action
     * @param action the PushSubRouteAction action containing the sub route
     * @returns storeChanged - true if the sub route changed, false otherwise
     */ handlePushSubRoute(action) {
        return this.changeSubRoute(action.subRoute, (newPath)=>{
            history.pushState(null, "", newPath);
        });
    }
    /**
     * Handles the replace sub route action
     * @param action the ReplaceSubRouteAction action containing the sub route
     * @returns storeChanged - true if the sub route changed, false otherwise
     */ handleReplaceSubRoute(action) {
        if (isEqual(this.currentRoute?.subRoute, action.subRoute)) {
            return false;
        }
        this.changeSubRoute(action.subRoute, (newPath)=>{
            history.replaceState(null, "", newPath);
        });
        return true;
    }
    /**
     * Change the sub route to a new one
     * @param newSubRoute New sub route
     * @param historyCallback History callback to call after the sub route has been changed
     * @returns Boolean indicating whether there was any actual store change
     */ changeSubRoute(newSubRoute, historyCallback) {
        if (isEqual(this.currentRoute?.subRoute, newSubRoute)) {
            return false;
        }
        this.currentRoute.subRoute = newSubRoute;
        const newPath = this.formRoutePath(this.currentRoute);
        if (newPath) {
            // Persist the sub route in cache
            this.subRouteCache[this.generateCacheKey(this.currentRoute.subRouteType, newSubRoute)] = newSubRoute;
            historyCallback(newPath);
        }
        // Write through and persist to persistent storage if supported
        this.persistRoute();
        return true;
    }
    /**
     * Change the route based on the open modal
     */ handleOpenModal(action) {
        let storeChanged = false;
        if (action.modalData.modalType === ModalType.TaskEditor) {
            switch(this.currentRoute.subRouteType){
                case SubRouteType.MyTasks:
                case SubRouteType.MyDay:
                case SubRouteType.TodoList:
                case SubRouteType.BasicPlan:
                    // Change route and push to history so that user can navigate back to view
                    const newSubRoute = setSubRouteKeyValue(this.currentRoute.subRoute, SubRouteKeys.Task, action.modalData.taskId);
                    storeChanged = this.changeSubRoute(newSubRoute, (newPath)=>{
                        history.pushState(null, "", newPath);
                    });
                    break;
                default:
            }
        }
        return storeChanged;
    }
    /**
     * Change the route when a modal is closed
     */ handleCloseModal(action) {
        let storeChanged = false;
        if (this.configProvider().flights.EnableTaskEditorRouting) {
            if (action.modalData.modalType === ModalType.TaskEditor) {
                switch(this.currentRoute.subRouteType){
                    case SubRouteType.MyTasks:
                    case SubRouteType.MyDay:
                    case SubRouteType.TodoList:
                    case SubRouteType.BasicPlan:
                        // Change route and push to history so that user can navigate back to modal
                        const newSubRoute = removeKeyFromSubRoute(this.currentRoute.subRoute, SubRouteKeys.Task);
                        storeChanged = this.changeSubRoute(newSubRoute, (newPath)=>{
                        // Do nothing, history is pushed once below
                        });
                        break;
                    default:
                }
            }
        }
        // Remove the modalType from query parameters, if it exists and matches the modalType of the closed modal
        if (this.currentRoute.queryParameters?.[QueryParameterKeys.Modal] === action.modalData.modalType) {
            delete this.currentRoute.queryParameters[QueryParameterKeys.Modal];
            // Remove the templateType from query parameters, if it exists and modal was CreatePlan
            if (action.modalData.modalType === ModalType.CreatePlan && this.currentRoute.queryParameters?.[QueryParameterKeys.TemplateType]) {
                delete this.currentRoute.queryParameters[QueryParameterKeys.TemplateType];
            }
            storeChanged = true;
        }
        // If the store changed, push the new route to the browser history & persist to storage
        if (storeChanged) {
            history.pushState(null, "", this.formRoutePath(this.currentRoute));
            this.persistRoute();
        }
        return storeChanged;
    }
    /**
     * Forms the route path by serializing the route descriptor in the format of a URL
     * @param routeDescriptor Route descriptor
     */ formRoutePath(routeDescriptor) {
        let routeToSerialize = routeDescriptor;
        if (this.defaultQueryParameters) {
            const clonedRouteDescriptor = cloneDeep(routeDescriptor);
            const clonedQueryParameters = clonedRouteDescriptor.queryParameters ?? {};
            clonedRouteDescriptor.queryParameters = {
                ...this.defaultQueryParameters,
                ...clonedQueryParameters
            };
            routeToSerialize = clonedRouteDescriptor;
        }
        return serializeRouteDescriptor(routeToSerialize, this.routePathPrefix);
    }
    /**
     * Generate a cache key for the subroute cache
     * @param subRouteType Type of subroute
     * @param subRoute Subroute array
     */ generateCacheKey(subRouteType, subRoute) {
        if (subRouteType === SubRouteType.BasicPlan) {
            return `${subRouteType}${subRoute[0]}`;
        } else if (subRouteType === SubRouteType.PremiumPlan) {
            const orgId = getValueForKey(subRoute, SubRouteKeys.Org);
            return `${subRouteType}${subRoute[0]}${orgId}`; // ProjectId + OrgId
        } else if (subRouteType === SubRouteType.TodoList) {
            return `${subRouteType}${subRoute[0]}`;
        } else if (subRouteType === SubRouteType.Portfolio) {
            return `${subRouteType}${subRoute[0]}`;
        } else {
            return subRouteType;
        }
    }
    /**
     * When we navigateToView, we typically want to use the cached subroute (if it exists)
     * e.g., For "My Tasks", use the pivot that was last selected
     * However, if newSubRoute specifically wants to target a particular view, we want to adhere to it (and not use cached subRoute)
     * This is especially true for Basic Plan navigation (if we are deep linking to a Task, we want go to that view and not cached subRoute)
     */ shouldUseCachedSubRoute(newSubRouteType, newSubRoute) {
        switch(newSubRouteType){
            case SubRouteType.BasicPlan:
            case SubRouteType.TodoList:
                // More than 1 element (planId) means we have intended view (e.g., Task) so don't use cached subRoute
                return newSubRoute.length === 1;
            case SubRouteType.PremiumPlan:
                // More than 3 elements [<PlanId>, "Org", <OrgId>] means we have intended view (e.g., Task/View) so don't use cached subRoute
                return newSubRoute.length === 3;
            case SubRouteType.Portfolio:
                return newSubRoute.length === 1;
            case SubRouteType.MyPlans:
            case SubRouteType.MyTasks:
            case SubRouteType.Publishing:
                // Any element means we have intended view (e.g., AssignedToMe) so don't use cached subRoute
                return newSubRoute.length === 0;
            default:
                return true;
        }
    }
    /**
     * Read the current route from persistent storage if supported
     */ readRouteFromStorage() {
        // Three cases to handle here:
        // 1. Route is unrecognized or corrupted
        // 2. Route is TT and we're on native
        // 3. Route was native and we rolled back to TT because of a bug (shouldn't happen but who knows)
        let result = null;
        const serializedRoute = this.persistentStorage?.getItem(RouteStore.RouteKey);
        if (serializedRoute == null) {
            return null;
        }
        try {
            result = JSON.parse(serializedRoute);
        } catch (e) {
            this.loggers.traceLogger.logTrace(0x1e3d3204 /* tag_4ptie */ , TraceLevel.Warning, `Route decoding error. Using Default [Store=${this.name}][Context=RouteKey]`);
        }
        if (!result) {
            return null;
        }
        // Check if what we got is the right shape
        if (!result.subRouteType || stringToEnum(result.subRouteType, SubRouteType) == null) {
            this.loggers.traceLogger.logTrace(0x1e3cc61c /* tag_4pmy2 */ , TraceLevel.Warning, `Route shape is incorrect. Using Default [Store=${this.name}]`);
            // In this case we don't recognize what's stored, but newly stored results should be correct so we will heal
            return null;
        }
        // Check if subRoute format changed, and if so, we need to ensure
        // that we don't use persisted route if it's in legacy TT format
        if (this.subRouteFormatChangedWithPersonalViews(result.subRouteType)) {
            // All TT subroutes start with ["personalApp", "alltasklists", "taskListType"]
            const isTT = result.subRoute.length > 2 && isEqual(result.subRoute.slice(0, 3), legacyTaskListTypePrefix);
            if (isTT) {
                // Stored route was TT, but we're on native. Return null to ignore this case
                return null;
            }
        }
        return result;
    }
    /**
     * Check if the SubRoute format specified by SubRouteType changed
     * @param subRouteType Sub route type
     */ subRouteFormatChangedWithPersonalViews(subRouteType) {
        const subRouteTypesChanged = [
            SubRouteType.MyDay,
            SubRouteType.MyTasks,
            SubRouteType.TodoList
        ];
        return subRouteTypesChanged.includes(subRouteType);
    }
    /**
     * Persist the current route to persistent storage if supported
     */ persistRoute() {
        const isRoutePersistenceFeatureEnabled = this.configProvider().settings.routePersistenceEnabled;
        if (isRoutePersistenceFeatureEnabled && this.persistRoutes) {
            try {
                this.persistentStorage?.setItem(RouteStore.RouteKey, JSON.stringify(this.currentRoute));
            } catch (e) {
                this.loggers.traceLogger.logTrace(0x1e3d10c0 /* tag_4prda */ , TraceLevel.Warning, `Persistence failure. [Store=${this.name}][IsRoutePersistenceEnabled=${isRoutePersistenceFeatureEnabled}][ShouldPersist=${this.persistRoutes}]`);
            }
        }
    }
    /**
     * Initializes a new instance of the RouteStore
     * @param initialRoute Initial route descriptor to set
     * @param loggers Logger context
     * @param persistentStorage Persistent storage to use for route persistence
     * @param configProvider Configuration provider
     * @param persistRoutes Flag to indicate if routes should be persisted
     * @param preferCache Flag to indicate if cache should be preferred over local storage for hydration
     * @param routePathPrefix If specified, this is the prefix to use when forming the route path
     * @param defaultQueryParameters If specified, these are default query parameters when serializing route
     */ constructor(initialRoute, loggers, persistentStorage, configProvider, persistRoutes, preferCache, routePathPrefix, defaultQueryParameters){
        super(), this.loggers = loggers, this.persistentStorage = persistentStorage, this.configProvider = configProvider, this.persistRoutes = persistRoutes, this.preferCache = preferCache, this.routePathPrefix = routePathPrefix, this.defaultQueryParameters = defaultQueryParameters;
        // Hydrate from local storage if supported and enabled
        const shouldHydrateFromStorage = this.configProvider().settings.routePersistenceEnabled && this.preferCache;
        this.currentRoute = shouldHydrateFromStorage ? this.readRouteFromStorage() ?? initialRoute : initialRoute;
        // Persist the initial route if supported (helpful in case of deep-links)
        const didHydrateFromStorage = shouldHydrateFromStorage && this.readRouteFromStorage() != null;
        if (this.configProvider().flights.EnableTaskEditorRouting && !didHydrateFromStorage) {
            this.persistRoute();
        }
        this.subRouteCache = {};
        const path = this.formRoutePath(this.currentRoute);
        history.replaceState(null, "", path);
    }
}
/** Key to persist the current route in local storage */ RouteStore.RouteKey = "rstore-croute";
