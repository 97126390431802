/* Represents the scenario in which Loop telemetry is being logged. */ export var LoopTelemetryScenario;
(function(LoopTelemetryScenario) {
    /* When Loop component is rendered in TaskDetailsEditor. */ LoopTelemetryScenario["TaskDetailsEditor"] = "TaskDetailsEditor";
    /* When Whiteboard Loop component is rendered in Whiteboard tab. */ LoopTelemetryScenario["WhiteboardTab"] = "WhiteboardTab";
    /* When Loop component is rendered in Basic Plan TaskEditor. */ LoopTelemetryScenario["BasicPlanTaskEditor"] = "BasicPlanTaskEditor";
})(LoopTelemetryScenario || (LoopTelemetryScenario = {}));
/* Represents the Host App Name required for creating a telemetry context for Loop. */ export const LoopHostAppName = "Microsoft Planner";
/* Represents the Namespace of the Telemetry Logger used in Loop integration. */ export const LoopTelemetryNamespace = "MicrosoftPlanner";
/* Loop default link */ export const LoopDefaultLink = "https://loop.cloud.microsoft";
export var LinkTitlesInLoop;
(function(LinkTitlesInLoop) {
    /* Represents the title of link to Planner in Loop workspace */ LinkTitlesInLoop["MicrosoftPlanner"] = "Planner";
    /* Represents the title of link to Whiteboard in Loop workspace */ LinkTitlesInLoop["MicrosoftWhiteboard"] = "Whiteboard";
})(LinkTitlesInLoop || (LinkTitlesInLoop = {}));
/** Name of the param used to denote Site Path in a decoded ODSP URL */ export const SitePathParamName = "s";
/** Name of the param used to denote Drive Id in a decoded ODSP URL */ export const DriveIdParamName = "d";
/** Name of the param used to denote Item Id in a decoded ODSP URL */ export const ItemIdParamName = "f";
/** Name of the param used to denote Data Store Path in a decoded ODSP URL */ export const DataStorePathParamName = "c";
/** Name of the param used to denote Fluid URL in a decoded ODSP URL */ export const FluidSignatureParamName = "fluid";
