/**
 * Enumeration of the possible statuses of a store entity.
 */ export var StoreEntityStatus;
(function(StoreEntityStatus) {
    /** Entity has not been fetched and its status is unknown */ StoreEntityStatus["Unfetched"] = "U";
    /** Entity is being fetched */ StoreEntityStatus["Fetching"] = "F";
    /** Entity is present in the store */ StoreEntityStatus["Present"] = "P";
    /** Entity is present in the store but is soft deleted */ StoreEntityStatus["SoftDeleted"] = "SD";
    /** Fetch for the entity failed */ StoreEntityStatus["UnableToFetch"] = "UF";
    /** Fetch for the entity failed due to it being inaccessible for the user */ StoreEntityStatus["Inaccessible"] = "I";
    /** Fetch for the entity failed due to it not being found */ StoreEntityStatus["NotFound"] = "NF";
    /** Entity is stale and expired */ StoreEntityStatus["Stale"] = "S";
})(StoreEntityStatus || (StoreEntityStatus = {}));
export const failedStoreEntityStatuses = [
    "I",
    "NF",
    "SD",
    "UF"
];
export var MruStoreCollectionKey;
(function(MruStoreCollectionKey) {
    MruStoreCollectionKey["MRU_COLLECTION"] = "MRU_COLLECTION";
})(MruStoreCollectionKey || (MruStoreCollectionKey = {}));
export var MyDayUser;
(function(MyDayUser) {
    MyDayUser["CURRENT_USER"] = "CURRENT_USER";
})(MyDayUser || (MyDayUser = {}));
export var StoreTableNames;
(function(StoreTableNames) {
    /** Mru Store tables */ StoreTableNames["Mru"] = "mru";
    StoreTableNames["MruCollectionStatus"] = "mruCollectionStatus";
    /** Plan Store tables */ StoreTableNames["Plans"] = "plans";
    StoreTableNames["PlanDetails"] = "planDetails";
    StoreTableNames["PlanStatus"] = "planStatus";
    StoreTableNames["MoveBasicPlanOperationStatus"] = "moveBasicPlanOperationStatus";
    StoreTableNames["ContainerStatus"] = "containerStatus";
    StoreTableNames["ContainerFailureStatus"] = "containerFailureStatus";
    StoreTableNames["PlansInContainerStatus"] = "plansInContainerStatus";
    StoreTableNames["PlansInContainerLastPageStatus"] = "plansInContainerLastPageStatus";
    /** Bucket store tables */ StoreTableNames["Buckets"] = "buckets";
    StoreTableNames["BucketStatus"] = "bucketStatus";
    StoreTableNames["BucketsInPlanStatus"] = "bucketsInPlanStatus";
    /** Task store tables */ StoreTableNames["Tasks"] = "tasks";
    StoreTableNames["TaskDetails"] = "taskDetails";
    StoreTableNames["TaskApprovalDetails"] = "taskApprovalDetails";
    StoreTableNames["TaskboardTaskFormatForAssignedTo"] = "taskboardTaskFormatForAssignedTo";
    StoreTableNames["TaskboardTaskFormatForBucket"] = "taskboardTaskFormatForBucket";
    StoreTableNames["TaskboardTaskFormatForProgress"] = "taskboardTaskFormatForProgress";
    StoreTableNames["IsApprovalOperationInProgress"] = "isApprovalOperationInProgress";
    StoreTableNames["TaskApprovalDetailsStatus"] = "taskApprovalDetailsStatus";
    StoreTableNames["TaskStatus"] = "taskStatus";
    StoreTableNames["TasksInPlanStatus"] = "tasksInPlanStatus";
    StoreTableNames["TasksByAssigneeStatus"] = "tasksByAssigneeStatus";
    StoreTableNames["TasksInMyDayStatusTable"] = "tasksInMyDayStatusTable";
})(StoreTableNames || (StoreTableNames = {}));
