// Models
import { TaskboardTaskFormattingCollectionBuilder } from "./TaskboardTaskFormattingCollectionBuilder";
// Utilities
import isEmpty from "lodash/isEmpty";
/**
 * Represents a collection of the taskboard task formats
 */ export class TaskboardTaskFormattingCollection {
    /**
     * Helper function to get the correct order hint from a formatting collection
     * @param taskFormat The format collection
     * @param pivotValue Pivot value to use in getting the correct order hint
     */ static getAssignedToOrderHint(taskFormat, pivotValue) {
        if (taskFormat.assignmentBoardFormat == null) {
            return null;
        }
        if (pivotValue != null && !isEmpty(pivotValue)) {
            return taskFormat.assignmentBoardFormat.orderHintsByAssignee[pivotValue];
        } else {
            return taskFormat.assignmentBoardFormat.unassignedOrderHint;
        }
    }
    /**
     * Helper function to set the correct order hint in a formatting collection
     * @param originalTaskFormat The format collection
     * @param newOrderHint The new order hint value
     * @param pivotValue Pivot value to use in setting the correct order hint
     */ static setAssignedToOrderHint(originalTaskFormat, newOrderHint, pivotValue) {
        if (originalTaskFormat.assignmentBoardFormat == null) {
            return originalTaskFormat;
        }
        let updatedAssignmentBoardFormat = originalTaskFormat.assignmentBoardFormat.getClone();
        if (isEmpty(pivotValue)) {
            updatedAssignmentBoardFormat = updatedAssignmentBoardFormat.setProperty("unassignedOrderHint", newOrderHint);
        } else {
            const orderHintsByAssignee = updatedAssignmentBoardFormat.orderHintsByAssignee;
            if (newOrderHint == null) {
                delete orderHintsByAssignee[pivotValue];
            } else {
                orderHintsByAssignee[pivotValue] = newOrderHint;
            }
            updatedAssignmentBoardFormat = updatedAssignmentBoardFormat.setProperty("orderHintsByAssignee", orderHintsByAssignee);
        }
        return originalTaskFormat.setProperty("assignmentBoardFormat", updatedAssignmentBoardFormat);
    }
    /**
     * Helper function to get the correct order hint from a formatting collection
     * @param taskFormat The format collection
     * @param pivotValue Pivot value to use in getting the correct order hint
     */ static getBucketOrderHint(taskFormat, pivotValue) {
        if (taskFormat.bucketBoardFormat == null) {
            return null;
        }
        return taskFormat.bucketBoardFormat.orderHint;
    }
    /**
     * Helper function to set the correct order hint in a formatting collection
     * @param originalTaskFormat The format collection
     * @param newOrderHint The new order hint value
     * @param pivotValue Pivot value to use in setting the correct order hint
     */ static setBucketOrderHint(originalTaskFormat, newOrderHint, pivotValue) {
        if (originalTaskFormat.bucketBoardFormat == null) {
            return originalTaskFormat;
        }
        const updatedBucketBoardFormat = originalTaskFormat.bucketBoardFormat.setProperty("orderHint", newOrderHint);
        return originalTaskFormat.setProperty("bucketBoardFormat", updatedBucketBoardFormat);
    }
    /**
     * Helper function to get the correct order hint from a formatting collection
     * @param taskFormat The format collection
     * @param pivotValue Pivot value to use in getting the correct order hint
     */ static getProgressOrderHint(taskFormat, pivotValue) {
        if (taskFormat.progressBoardFormat == null) {
            return null;
        }
        return taskFormat.progressBoardFormat.orderHint;
    }
    /**
     * Helper function to set the correct order hint in a formatting collection
     * @param originalTaskFormat The format collection
     * @param newOrderHint The new order hint value
     * @param pivotValue Pivot value to use in setting the correct order hint
     */ static setProgressOrderHint(originalTaskFormat, newOrderHint, pivotValue) {
        if (originalTaskFormat.progressBoardFormat == null) {
            return originalTaskFormat;
        }
        const updatedProgressBoardFormat = originalTaskFormat.progressBoardFormat.setProperty("orderHint", newOrderHint);
        return originalTaskFormat.setProperty("progressBoardFormat", updatedProgressBoardFormat);
    }
    setProperty(key, value) {
        // Check if a read-only property is being passed in
        if (!(key === "assignmentBoardFormat" || key === "bucketBoardFormat" || key === "progressBoardFormat")) {
            throw new Error("ReadOnlyException: " + key);
        }
        const clone = this.getClone();
        clone[key] = value;
        return clone;
    }
    /**
     * Returns a builder for objects of type TaskboardTaskFormattingCollection
     */ static get builder() {
        return new TaskboardTaskFormattingCollectionBuilder();
    }
    toGraphSerializable() {
        return {
            assignedToTaskBoardFormat: this.assignmentBoardFormat != null ? this.assignmentBoardFormat.toGraphSerializable() : undefined,
            bucketTaskBoardFormat: this.bucketBoardFormat != null ? this.bucketBoardFormat.toGraphSerializable() : undefined,
            progressTaskBoardFormat: this.progressBoardFormat != null ? this.progressBoardFormat.toGraphSerializable() : undefined
        };
    }
    getClone() {
        const taskFormatResource = this.toGraphSerializable();
        return TaskboardTaskFormattingCollection.builder.fromGraphTaskBoardTaskFormattingCollectionResource(taskFormatResource).build();
    }
    /**
     * Initialize a new instance of the TaskBoardTaskFormatting collection
     * @param builder The task format collection builder
     */ constructor(builder){
        this.assignmentBoardFormat = builder.assignedBoardFormat;
        this.bucketBoardFormat = builder.bucketBoardFormat;
        this.progressBoardFormat = builder.progressBoardFormat;
    }
}
