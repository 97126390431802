import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for TaskStore, for use in the registry */ export const TaskStoreActionTypes = [
    ActionType.CreateTaskAction,
    ActionType.CreateTaskFailedAction,
    ActionType.CreateTaskSucceededAction,
    ActionType.DeleteTaskAction,
    ActionType.DeleteTaskFailedAction,
    ActionType.DeleteTaskSucceededAction,
    ActionType.DeltaSyncCreateTaskAction,
    ActionType.DeltaSyncDeleteTaskAction,
    ActionType.DeltaSyncUpdateTaskAction,
    ActionType.FetchTaskAction,
    ActionType.FetchTaskFailedAction,
    ActionType.FetchTasksForMyDayAction,
    ActionType.FetchTasksForMyDayFailedAction,
    ActionType.FetchTasksForMyDaySucceededAction,
    ActionType.FetchTasksForPlanAction,
    ActionType.FetchTasksForPlanFailedAction,
    ActionType.FetchTasksForPlanSucceededAction,
    ActionType.FetchTaskSucceededAction,
    ActionType.UpdateTaskAction,
    ActionType.UpdateTaskFailedAction,
    ActionType.UpdateTaskSucceededAction
];
