/**
 * Copied from: /odsp-utilities/src/local/uri/Uri.ts
 * https://onedrive.visualstudio.com/OneDriveWeb/_git/odsp-common?path=/odsp-utilities/src/local/uri/Uri.ts&_a=contents&version=GBSP2019
 * Refed to any comments with CHANGED - made a few changes to allow for no compile errors
 */ const DELIMITERS = /[;\/?:@&=$,]/;
const AUTHORITY_TERMINATORS = /[\/?]/;
class Uri {
    getScheme() {
        return this._scheme;
    }
    getPath(trimTrailingSlash) {
        let retPath = this._path;
        if (Boolean(trimTrailingSlash)) {
            // If the last character is a slash
            if (retPath !== null && retPath.lastIndexOf("/") === retPath.length - 1) {
                retPath = retPath.slice(0, -1); //trim last character
            }
        }
        return retPath;
    }
    getPathSegments() {
        return this._pathSegments;
    }
    getHost() {
        return this._host;
    }
    getPort() {
        return this._port;
    }
    getFragment() {
        return this._fragment;
    }
    setScheme(scheme) {
        this._scheme = scheme;
    }
    setFragment(fragment) {
        if (fragment[0] === "#") {
            fragment = fragment.substring(1);
        }
        // Treat the fragment as a query string (decode + as space) because we pass in
        // query parameters using the fragment on page load.
        this._fragment = this._decodeQueryString(fragment);
    }
    setPath(path) {
        if (path && path[0] !== "/") {
            path = "/" + path;
        }
        this._parsePath(path);
    }
    setAuthority(authority) {
        this._parseAuthority(authority);
    }
    getAuthority() {
        return this._getAuthority(false);
    }
    /**
     * Query is not well-defined but is commonly formatted as key=value and delimited with & or ;
     * (http://www.w3.org/TR/REC-html40/appendix/notes.html#h-B.2.2)
     *  - URI with query "a=1&b=2" or "a=1;b=2" will return {a: "1", b: "2"}
     *  - Mixed-mode will also work: "a=1&b=2;c=3" will return {a: "1", b: "2", c: "3"}
     *  - Assumes that parameters will be unique (i.e. "a=1&a=2" is not allowed and will produce unexpected results)
     */ setQuery(query) {
        this.setQueryFromObject(this._deserializeQuery(query));
    }
    getQueryAsObject() {
        return this._query;
    }
    setQueryFromObject(queryObj) {
        this._query = {};
        for(const queryKey in queryObj){
            if (queryObj.hasOwnProperty(queryKey)) {
                this.setQueryParameter(queryKey, queryObj[queryKey]);
            }
        }
    }
    /**
     * Adds query parameter to the end if queryKey does not exist, or
     * overwrites existing query value if queryKey already exists.
     */ setQueryParameter(queryKey, queryValue, ignoreEmptyValues = true) {
        const queryValueDecoded = this._decodeQueryString(queryValue);
        // there is no point adding undefined or modifying existing values to undefined or null.
        if (!!queryValueDecoded || ignoreEmptyValues) {
            this._query[this._decodeQueryString(queryKey)] = queryValueDecoded;
        }
    }
    getQuery(encoded) {
        return this._serializeQuery(encoded);
    }
    /**
     * Note that this returns the URL encoded/escaped while the getXXX() methods
     * for the individual components return the unescaped strings. Returning a
     * concatenation of the decoded components would change the semantics of the
     * URL. See section 2.4.2 of RFC 2396 (http://www.ietf.org/rfc/rfc2396.txt).
     *
     * Use doNotPercentEncodeHost to indicate that the output should not have a
     * percent-encoded host, such as when passing to the url parameter of
     * XmlHttpRequest.open(). Section 3.2.2 of RFC 2396 only allows alphanumeric
     * characters and hyphen in the host of a URL, so percent-encoded hosts are
     * not allowed. While section 3.2.2 of RFC 3986 does not restrict the host
     * character set anymore, not all browsers can handle a percent-encoded host
     * - DNS lookup fails.  But, they will convert the unencoded Unicode to the
     * IDNA encoding (punycode), so leaving the host as-is in this case is preferred.
     */ toString(outputOptions) {
        return this._getStringInternal(/*encoded*/ true, outputOptions);
    }
    _serializeQuery(encoded) {
        let queryStr = "";
        for(const queryKey in this._query){
            if (this._query.hasOwnProperty(queryKey)) {
                let key = queryKey;
                let value = this._query[queryKey];
                if (encoded) {
                    key = encodeURIComponent(key);
                    value = encodeURIComponent(value);
                }
                if (value === null || value === "") {
                    queryStr += key + "=&";
                } else {
                    queryStr += key + "=" + value + "&";
                }
            }
        }
        if (queryStr !== "") {
            queryStr = queryStr.slice(0, -1); //trim extra & at the end
        }
        return queryStr;
    }
    _getStringInternal(encoded, outputOptions) {
        let ret = this._getStringWithoutQueryAndFragmentInternal(encoded, outputOptions);
        const query = this.getQuery(encoded);
        if (query) {
            ret += "?" + query;
        }
        if (this._fragment) {
            ret += "#" + (encoded ? encodeURIComponent(this._fragment) : this._fragment);
        }
        return ret;
    }
    _getStringWithoutQueryAndFragmentInternal(encoded, outputOptions) {
        let ret = "";
        if (this._scheme) {
            ret += (encoded ? encodeURIComponent(this._scheme) : this._scheme) + ":";
        }
        // Authority includes user, host, and port
        const authority = this._getAuthority(/*encoded=*/ encoded, outputOptions);
        if (authority) {
            ret += "//" + authority;
        }
        if (this._pathEncoded) {
            ret += encoded ? this._pathEncoded : this._path;
        }
        return ret;
    }
    _parseURI(uriString) {
        let remainingString = uriString;
        // Find fragment
        const fragmentBeginPos = remainingString.indexOf("#");
        if (fragmentBeginPos >= 0) {
            const fragment = remainingString.substring(fragmentBeginPos + 1);
            this.setFragment(fragment);
            remainingString = remainingString.substring(0, fragmentBeginPos); //remove fragment
        }
        // Find scheme
        const schemeEndPos = remainingString.search(DELIMITERS);
        if (schemeEndPos >= 0) {
            if (remainingString[schemeEndPos] === ":") {
                this.setScheme(remainingString.substring(0, schemeEndPos));
                remainingString = remainingString.substring(schemeEndPos + 1); //remove scheme
            }
        } else {
            this.setPath(remainingString);
            return;
        }
        // Find authority
        let authority = "";
        const doubleSlashPos = remainingString.indexOf("//");
        if (doubleSlashPos === 0) {
            remainingString = remainingString.substring(2); //skip the //
            const authorityEndPos = remainingString.search(AUTHORITY_TERMINATORS);
            if (authorityEndPos >= 0) {
                authority = remainingString.substring(0, authorityEndPos);
                remainingString = remainingString.substring(authorityEndPos); //remove authority
            } else {
                authority = remainingString;
                remainingString = "";
            }
            this.setAuthority(authority);
            if (!remainingString) {
                this.setPath("");
                return;
            }
        }
        // Find query
        const queryBeginPos = remainingString.indexOf("?");
        if (queryBeginPos >= 0) {
            this.setQuery(remainingString.substring(queryBeginPos + 1));
            remainingString = remainingString.substring(0, queryBeginPos);
        }
        this.setPath(remainingString);
    }
    _parseAuthority(authority) {
        this._host = authority;
        const userNameEndPos = authority.lastIndexOf("@");
        if (userNameEndPos >= 0) {
            this._host = this._host.substring(userNameEndPos + 1);
        }
        const hostPortSeparatorPos = this._host.indexOf(":");
        if (userNameEndPos < 0 && hostPortSeparatorPos < 0) {
            return;
        }
        const authorityComponents = authority;
        if (userNameEndPos < 0) {
            this._host = authorityComponents;
        } else {
            this._user = authorityComponents.substring(0, userNameEndPos);
            this._host = authorityComponents.substring(userNameEndPos + 1);
        }
        if (hostPortSeparatorPos >= 0) {
            this._port = this._host.substring(hostPortSeparatorPos + 1);
            this._host = this._host.substring(0, hostPortSeparatorPos);
        }
        this._user = decodeURIComponent(this._user);
        this._host = decodeURIComponent(this._host);
    }
    _deserializeQuery(queryStr) {
        const queryObj = {};
        if (queryStr.indexOf("?") === 0) {
            queryStr = queryStr.substring(1);
        }
        for (const queryPart of queryStr.split(/[;&]+/)){
            let keyEndIndex = queryPart.indexOf("=");
            // "foo" is a legal query string equivalent to "foo="
            if (keyEndIndex < 0) {
                keyEndIndex = queryPart.length;
            }
            if (keyEndIndex > 0) {
                queryObj[queryPart.substr(0, keyEndIndex)] = queryPart.substr(keyEndIndex + 1);
            }
        }
        return queryObj;
    }
    _parsePath(remainingString) {
        this._path = decodeURIComponent(remainingString);
        const pathSegments = this._pathSegments = []; // CHANGED: set type to any to avoid TS error
        this._pathEncoded = remainingString; // CHANGED: removed this line for now, not sure if we'll need it later.
        // We have to split the path BEFORE decoding so that encoded / characters
        // don't get interpreted as path separators.
        const encodedPathSegments = remainingString.split("/");
        for(let i = 0; i < encodedPathSegments.length; ++i){
            pathSegments[i] = decodeURIComponent(encodedPathSegments[i]);
        }
        // Trims first/last element if empty
        if (pathSegments[0] === "") {
            pathSegments.shift(); // remove first element
        }
        if (pathSegments[pathSegments.length - 1] === "") {
            pathSegments.pop(); // remove last element
        }
    }
    _getAuthority(encoded, outputOptions = {}) {
        // Note that if encoded is false, doNotPercentEncodeHost doesn't matter - the whole URI (including host) will not be encoded.
        const doNotPercentEncodeHost = outputOptions?.doNotPercentEncodeHost;
        let authority = "";
        let user;
        let host;
        let port;
        if (encoded) {
            // While technically a reserved character, ':' is commonly used in the
            // username to denote username:password, so we special case not encoding
            // the first occurence of this character.
            user = encodeURIComponent(this._user).replace("%3A", ":");
            if (doNotPercentEncodeHost) {
                host = this._host;
            } else {
                host = encodeURIComponent(this._host);
            }
            port = encodeURIComponent(this._port);
        } else {
            user = this._user;
            host = this._host;
            port = this._port;
        }
        if (user !== "") {
            authority = user + "@";
        }
        if (this._host !== "") {
            authority += host;
        }
        if (this._port !== "") {
            authority += ":" + port;
        }
        return authority;
    }
    _decodeQueryString(component) {
        // For query strings only, "+" is a valid substitute for a space, but decodeURIComponent
        // doesn't take this into account. (Note that replace("+", " ") only replaces one +.)
        let result = component;
        try {
            result = decodeURIComponent(component.replace(/\+/g, " "));
        } catch (e) {
        // %1 (or anything with a % that is not a result of calling encodeURIComponent)
        // would make decodeURIComponent throw a URI malformed exception.
        // Return the original value in these cases.
        }
        return result;
    }
    constructor(uriString){
        // All of these are decoded (if relevant) unless specified as encoded.
        this._scheme = "";
        this._user = "";
        this._host = "";
        this._port = "";
        this._path = "";
        this._pathSegments = [];
        this._query = {};
        this._fragment = "";
        this._pathEncoded = "";
        this._parseURI(uriString);
    }
}
/**
 * Partial port of groove\Misc\URI.cpp, which was based on RFC2396 and RFC3986 (http://www.ietf.org/rfc/rfc2396.txt).
 * There are a few differences between this implementation and the RFC:
 *  - Implementation does not support parameters (we don't use them, and partial implementation was incorrect)
 *  - Implementation supports some relative URIs at a glance but more investigation required
 *
 *   foo://example.com:8042/over/there?name=ferret#nose
 *   \_/   \______________/\_________/ \_________/ \__/
 *    |           |            |            |        |
 * scheme     authority       path        query   fragment
 *
 * Possible improvements:
 *  - Support path parameters
 *  - Fully support and test relative URLs based on RFC
 *  - Allow changing/removing remaining URI components (i.e. user, host, port, parameters)
 *  - URI.equals could allow ?foo=1&bar=2 equals ?bar=2&foo=1
 *  - URI.parseURI should have better error handling rather than just setting it as path
 *  - URI.getQueryAsObject should have better error handling for query of "a=1&a=2"
 */ export { Uri as default };
