// Models
import { CopilotExtensions } from "./CopilotExtensions";
// Utilities
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import { getDiff } from "@ms/uno-utilities/lib/local/ObjectUtilities";
// Constants
import { TaskExtensionProperties } from "@ms/uno-constants/lib/local/TaskExtensionProperties";
/**
 * Internal properties (extension fields) of a task
 */ export class TaskInternalProperties {
    toGraphSerializable() {
        const result = {};
        if (this.copilot) {
            try {
                result[TaskExtensionProperties.copilot] = JSON.stringify(this.copilot);
            } catch (error) {
                throw new Error("Error while serializing copilot extensions: " + error);
            }
        }
        return result;
    }
    static fromResource(resource) {
        let copilotExtensions = {};
        if (resource[TaskExtensionProperties.copilot]) {
            try {
                const copilotExtensionData = JSON.parse(resource[TaskExtensionProperties.copilot]);
                copilotExtensions = new CopilotExtensions(copilotExtensionData.loopPageUrl);
            } catch (error) {
                throw new Error("Error while deserializing copilot extensions: " + error);
            }
        }
        return new TaskInternalProperties(copilotExtensions);
    }
    getDiff(target) {
        const getDiffCustomizer = (source, target, key)=>{
            if (key === TaskExtensionProperties.copilot) {
                if (!isEqual(source, target)) {
                    return target;
                } else {
                    return {};
                }
            }
        };
        return getDiff(this, target, getDiffCustomizer);
    }
    applyDiffs(...diff) {
        const mergeCustomizer = (source, target, key)=>{
            if (key === TaskExtensionProperties.copilot) {
                return target;
            }
        };
        if (diff.length > 0) {
            return mergeWith(this.getClone(), ...diff, mergeCustomizer);
        }
        return this;
    }
    getClone() {
        return new TaskInternalProperties(this.copilot);
    }
    constructor(copilot){
        if (copilot) {
            this.copilot = copilot;
        }
    }
}
