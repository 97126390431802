/**
 * Describes the current state of an MruItem. Normal or Deleted.
 * Primarily only used by clients that use Incremental Sync that need to know when items are deleted. For now, this defaults to 'Normal'.
 * Reference: https://www.owiki.ms/wiki/ICE_Services/Recent_Documents/MRU_V2_API#MRU_Applications
 */ export var MruState;
(function(MruState) {
    /**
     * Normal, default state of an MruItem.
     */ MruState["Normal"] = "Normal";
    /**
     * Indicates that the item is deleted. This is primarily used by clients that use Incremental Sync.
     */ MruState["Deleted"] = "Deleted";
})(MruState || (MruState = {}));
