import { ArgumentNullError } from "@ms/uno-errors/lib/local/errors";
// Utilities
import endsWith from "lodash/endsWith";
import parseInt from "lodash/parseInt";
import reverse from "lodash/reverse";
import startsWith from "lodash/startsWith";
import { generateNumberWithLength } from "./MathUtilities";
import { getRTL } from "@fluentui/utilities";
import { EmptyRange } from "./types/Range";
/**
 * Retrieve the initials from the name
 * Examples:
 * Blank -> BL
 * Joe Smith -> JS
 * Jane Meredith Smith -> JM
 * Contoso Project -> CP
 * @param displayName The name to create initials for
 */ export function parseInitialsFromName(displayName) {
    if (!displayName) {
        return "";
    }
    displayName = displayName.replace(/[&#.*;]/g, "").trim();
    if (!displayName) {
        return "";
    }
    let retVal = "";
    // Split on whitespace that at least matches once to unlimited times. eg " Planner   Web" => ["Planner", "Web"]
    const displayWords = displayName.split(/[\s]+/);
    // No spaces
    if (displayWords.length === 1) {
        // Elements in titleChars can include emojis (compound emojis may not render properly)
        const titleChars = Array.from(displayWords[0]);
        if (titleChars.length === 1) {
            retVal = titleChars[0].toLocaleUpperCase();
        } else {
            retVal = titleChars[0].toLocaleUpperCase() + titleChars[1].toLocaleUpperCase();
        }
    } else {
        // firstInitial and secondInitial may be emojis (compund emojis may not render properly)
        const firstInitial = Array.from(displayWords[0])[0].toLocaleUpperCase();
        const secondInitial = Array.from(displayWords[1])[0].toLocaleUpperCase();
        retVal = firstInitial + secondInitial;
    }
    // Reverse string if we are in RTL mode
    if (getRTL()) {
        retVal = reverse(retVal.split("")).join("");
    }
    return retVal;
}
/**
 * Compare strings in ordinal manner, without case sensitivity. Returns less than 0, 0, or greater than 0 based on the comparison.
 * @param left The left side of the comparison
 * @param right The right side of the comparison
 */ export function stringCompareOrdinalCaseInsensitive(left, right) {
    return stringCompareOrdinal(left.toLowerCase(), right.toLowerCase());
}
/**
 * Compare strings in ordinal manner, with case sensitivity. Returns less than 0, 0, or greater than 0 based on the comparison.
 * @param left The left side of the comparison
 * @param right The right side of the comparison
 */ export function stringCompareOrdinal(left, right) {
    return left < right ? -1 : left > right ? 1 : 0;
}
/**
 * Reduces string into a number between 0 and @hashTableSize
 * @param inputString String to be hashed
 * @param hashTableSize Number of hash buckets
 */ export function hashNumberFromString(inputString, hashTableSize) {
    if (inputString == null) {
        return -1;
    }
    if (inputString.length === 0) {
        return -1;
    }
    if (hashTableSize <= 0) {
        return -1;
    }
    const hashedName = inputString.split("").map((s)=>s.charCodeAt(0)).reduce((prev, current, index, array)=>prev + (current << index + 1));
    return Math.abs(hashedName) % hashTableSize;
}
/**
 * Get the local date or time given an input date
 * Returns time in users local time if inputDate is same day as today, if not show the date in short date format
 * @param inputDate - The date for which we want the local date or time to be returned
 */ export function parseLocalDateOrTimeFromDate(inputDate) {
    if (!inputDate) {
        return "";
    }
    const validDateObject = new Date(inputDate.toString());
    if (validDateObject.toString() !== "Invalid Date") {
        const currentDate = new Date();
        // If the date is same day as today return time else date
        return validDateObject.toLocaleDateString() === currentDate.toLocaleDateString() ? validDateObject.toLocaleTimeString() : validDateObject.toLocaleDateString();
    }
    return "";
}
/**
 * Replaces escaped unicode characters encoded in the form _xHHHH_ with the corresponding unicode string characters
 * Used for unescaping unicode name strings, in XML returned from Project server CSOM API calls
 * The encoding logic is defined in: https://msdn.microsoft.com/en-us/library/35577sxd(v=vs.100).aspx
 * The decoding logic is defined in: https://msdn.microsoft.com/en-us/library/system.xml.xmlconvert.decodename(v=vs.100).aspx
 * Example: Custom_x005f_d26058ba4e50e61180fc00155d049e10 -> Custom_d26058ba4e50e61180fc00155d049e10
 * @param inputString - The string containing escaped unicode characters
 */ export function unescapeUnicodeFromString(inputString) {
    if (inputString == null) {
        throw new ArgumentNullError("inputString");
    }
    function getCharFromUnicode(matched, charCodeStr) {
        const charCode = parseInt(charCodeStr || "", 16);
        return String.fromCharCode(charCode);
    }
    const unicodeRe = /_x([0-9a-fA-F]{4})_/g;
    return inputString.replace(unicodeRe, getCharFromUnicode);
}
/**
 * Return a copy of the original string with the optional prefix and suffix added
 * @param baseString Original string to start with
 * @param [prefix] Prefix to add
 * @param [suffix] Suffix to add
 */ export function concatPrefixSuffix(baseString, prefix, suffix) {
    if (baseString == null) {
        throw new ArgumentNullError("baseString");
    }
    let finalString = baseString;
    if (prefix && !startsWith(baseString, prefix)) {
        finalString = `${prefix}${finalString}`;
    }
    if (suffix && !endsWith(baseString, suffix)) {
        finalString = `${finalString}${suffix}`;
    }
    return finalString;
}
/**
 * Generates a new group mail nickname (the local-part of a group email address) by appending a random number of a given length.
 * @param proposedMailNickname This is the local-part of the fully qualified email address
 * @param length Length of a random number to be generated
 * @param maxLength Max length of the name
 * @param prefix Required prefix for the mail nickname.
 * @param suffix Required suffix for the mail nickname.
 */ export function generateNewMailNickname(proposedMailNickname, length, maxLength, prefix = "", suffix = "") {
    // Take only letters, numbers, underscores and dashes. Ignore everything else.
    proposedMailNickname = proposedMailNickname.replace(/([^a-zA-Z0-9_-])/g, "");
    // Trim the proposed mail nickname so that it doesn't exceed the maximum length allowed after manipulation.
    proposedMailNickname = proposedMailNickname.substring(0, maxLength - length - prefix.length - suffix.length);
    // Generate a random number.
    const randomNumber = length > 0 ? generateNumberWithLength(length).toString() : "";
    // Append random number to it, and add the required prefix and suffix.
    return prefix + proposedMailNickname + randomNumber + suffix;
}
/**
 * Takes a list of items and converts that into a lt/gt separated string
 * @param items The breadcrumb items
 */ export function getBreadcrumbString(items) {
    if (items == null) {
        return "";
    }
    const joinKey = getRTL() ? " < " : " > ";
    return items.join(joinKey);
}
/**
 * @param token substring text to be searched in the text
 * @param value text that needs to be searched
 * @returns range of the portion of the text where the token is present
 */ export function findLastTokenInText(token, value) {
    let range = EmptyRange;
    if (value && value !== "") {
        const ind = value.lastIndexOf(token);
        if (ind >= 0) {
            range = {
                startPosition: ind,
                endPosition: ind - 1 + token.length
            };
        }
    }
    return range;
}
/**
 * Generates a composite post id from group id, thread id and post id
 * @param groupId Id of the group
 * @param threadId Id of the thread
 * @param postId Id of the post
 */ export function getCompositePostIdFromGroupIdThreadIdAndPostId(groupId, threadId, postId) {
    return `${groupId}_${threadId}_${postId}`;
}
/**
 * Generates a composite permission set id from publication id and container id
 * @param publicationId Id of the publication
 * @param containerId Id of the container
 */ export function getCompositePermissionSetIdFromPublicationIdAndContainerId(publicationId, containerId) {
    return `${publicationId}_${containerId}`;
}
/**
 * Splits the string by separator and trims the substrings
 * If value is empty, returns an empty array instead of an array with an empty string
 * @param value The value to split
 * @param separator The separator to split by
 */ export function splitAndTrimString(value, separator) {
    if (value === "") {
        return [];
    }
    return value.split(separator).map((v)=>v.trim());
}
