import { ActionType } from "@ms/uno-actions/lib/local/ActionType";
/** A tuple of the supported ActionType values for ViewStore, for use in the registry */ export const ViewStoreActionTypes = [
    ActionType.CreateBasicTaskAction,
    ActionType.CreateBasicTaskFailedAction,
    ActionType.CreateBasicTaskSucceededAction,
    ActionType.DeleteBasicBucketSucceededAction,
    ActionType.DeltaSyncDeleteBasicBucketAction,
    ActionType.NavigateToView,
    ActionType.SetCategoryLabelModeAction,
    ActionType.SetLeftNavExpandedAction,
    ActionType.SetPlanFilterAction,
    ActionType.SetSortCriteriaAction,
    ActionType.SetTaskFilterAction,
    ActionType.SetTaskGroupingStateAction,
    ActionType.SetGridColumnWidthAction,
    ActionType.SetTeamsExpandedStateAction,
    ActionType.UpdateBasicTaskAction,
    ActionType.UpdateBasicTaskFailedAction,
    ActionType.FetchRoamingUserDataSucceededAction,
    ActionType.UpdateRoamingUserDataAction,
    ActionType.UpdateRoamingUserDataSucceededAction,
    ActionType.UpdateRoamingUserDataFailedAction,
    ActionType.FetchBasicPlanSucceededAction,
    ActionType.UpdateBasicPlanAction,
    ActionType.UpdateBasicPlanSucceededAction,
    ActionType.UpdateBasicPlanFailedAction
];
