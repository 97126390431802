// Constants
import { HttpMethods } from "@ms/uno-constants/lib/local/HttpConstants";
// Utilities
import { generateAjaxClientError } from "../../utilities/ServiceUtilities";
import moment from "moment";
const TokenProviderOverride = {
    "https://graph.microsoft.com": "graph",
    "https://augloop.office.com/v2": "augloop"
};
/**
 * Implementation of the Auth Service using the retail services token exchange endpoint
 */ export class RetailServicesTokenExchangeService {
    async fetchAccessTokenAsync(parameters) {
        const { resource, claims, silent } = parameters;
        let response = null;
        try {
            // TODO #8474561 [Uno][Auth] When the OBO auth service mechanism needed to support different resources is done, remove the prod graph resource workaround
            // For Prod MS Graph resource, we can utilize the retailservices graph token OBO endpoint
            const tokenProviderPath = TokenProviderOverride[resource];
            // Remove shouldCallService entirely when EnableCopilotMyDayMyTasks is graduated
            const shouldCallService = this.configProvider().flights.EnableCopilotMyDayMyTasks || tokenProviderPath === "graph";
            if (tokenProviderPath && shouldCallService) {
                const path = `${this.baseUrl}/api/rest/v1/tokenprovider/${tokenProviderPath}`;
                response = await this.ajaxClient.executeRequest(path, {
                    method: HttpMethods.Get,
                    telemetryConfig: this.getDefaultRequestTelemetryConfig("fetchAccessToken")
                });
                const data = await response.clone().json();
                return {
                    accessToken: data.accessToken,
                    expiry: moment(data.expiresIn).toDate()
                };
            } else {
                // Retail services does not support a generic token exchange mechanic, so use the host token for everything else as a stopgap
                return await this.hostAdaptor.getAccessToken(resource, claims, silent);
            }
        } catch (error) {
            throw generateAjaxClientError(response, error);
        }
    }
    /**
     * Get the default telemetry config for requests
     */ getDefaultRequestTelemetryConfig(methodName) {
        return {
            apiName: this.name,
            methodName: methodName,
            requestHeadersToLog: [],
            responseHeadersToLog: []
        };
    }
    constructor(ajaxClient, hostAdaptor, configProvider){
        this.ajaxClient = ajaxClient;
        this.hostAdaptor = hostAdaptor;
        this.configProvider = configProvider;
        this.name = "RetailServicesTokenExchangeService";
        const { hostname } = this.configProvider().settings.serviceConfigurations.auth;
        this.baseUrl = hostname;
    }
}
