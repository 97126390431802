// Styles
import { concatStyleSets, memoizeFunction, mergeStyles } from "@fluentui/react";
// Types
import { flexParent } from "../../../utilities/Mixin.styles";
const shimmerWidth = "288px";
const shimmerMargin = "17px";
/**
 * Returns the styles for the TaskBoardViewPlaceHolder component.
 * @param {Partial<ITaskBoardViewPlaceHolderStyles>} customStyles - Custom styles to override the default styles.
 */ export const getStyles = memoizeFunction((customStyles)=>{
    const root = [
        flexParent("row"),
        {
            margin: "20px"
        }
    ];
    const containerShimmer = [
        flexParent("column"),
        {
            margin: "0px 13px",
            width: shimmerWidth
        }
    ];
    return concatStyleSets({
        root,
        containerShimmer
    }, customStyles);
});
/**
 * Returns the class names for the TaskBoardViewPlaceHolder component.
 * @param {Partial<ITaskBoardViewPlaceHolderStyles>} styles - Styles to generate class names from.
 */ export const getClassNames = memoizeFunction((styles)=>{
    return {
        root: mergeStyles("taskBoardViewPlaceHolder", styles.root),
        containerShimmer: mergeStyles("shimmer-container", styles.containerShimmer)
    };
});
/**
 * Returns the styles for the column header shimmer.
 */ export const getColumnHeaderShimmerStyles = memoizeFunction(()=>{
    return {
        root: {
            marginBottom: `calc(${shimmerMargin} - 4px)`
        }
    };
});
/**
 * Returns the styles for the add task shimmer.
 */ export const getAddTaskShimmerStyles = memoizeFunction(()=>{
    return {
        root: {
            marginBottom: shimmerMargin
        }
    };
});
/**
 * Returns the styles for the task card shimmer.
 */ export const getTaskCardShimmerStyles = memoizeFunction(()=>{
    return {
        root: {
            margin: "4px 0px"
        }
    };
});
