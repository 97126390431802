// Constants
// Actions
import { ViewDataFetchAction, ViewRenderAction } from "@ms/uno-actions/lib/local/performanceCollection/PerformanceCollectionAction";
export class PerformanceCollectionModule {
    setViewRenderState(viewName, state, extraData) {
        this.dispatcher.dispatchActionAsync(new ViewRenderAction(viewName, state, extraData));
    }
    setViewDataFetchState(viewName, state, extraData) {
        this.dispatcher.dispatchActionAsync(new ViewDataFetchAction(viewName, state, extraData));
    }
    constructor(dispatcher){
        this.dispatcher = dispatcher;
    }
}
