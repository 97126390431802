// Constants
/**
 * Implementation of the Auth Service that simply relies on the host adaptor to provide the access token
 */ export class HostAdaptorAuthService {
    async fetchAccessTokenAsync(parameters) {
        const { resource, claims, silent } = parameters;
        const hostToken = await this.hostAdaptor.getAccessToken(resource, claims, silent);
        return hostToken;
    }
    constructor(hostAdaptor, configProvider){
        this.hostAdaptor = hostAdaptor;
        this.configProvider = configProvider;
    }
}
