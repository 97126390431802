/**
 * The query parameters used by UNO Web
 */ export var WebQueryParams;
(function(WebQueryParams) {
    /** Called after logout */ WebQueryParams["Logout"] = "logout";
    /** AAD User ID */ WebQueryParams["UserId"] = "userObjectId";
    /** AAD UPN (UserPrincipalName) */ WebQueryParams["Upn"] = "auth_upn";
    /** Tenant ID */ WebQueryParams["TenantId"] = "tid";
    /** Locale Override */ WebQueryParams["Mkt"] = "mkt";
    /** Query param from a ocdi redirect */ WebQueryParams["OcdiRedirect"] = "ocdiRedir";
    /** Web redirect indication parameter */ WebQueryParams["UnowebRedirect"] = "unowebRedir";
})(WebQueryParams || (WebQueryParams = {}));
export var WebUrlSegments;
(function(WebUrlSegments) {
    /** Web UI prefix */ WebUrlSegments["WebUI"] = "webui";
    /** Gcc specific sub route path */ WebUrlSegments["GccPath"] = "gcc";
    /** Blank msal redirect path */ WebUrlSegments["BlankMsalRedirect"] = "blankmsalredirect";
})(WebUrlSegments || (WebUrlSegments = {}));
export var WebAuthEventNames;
(function(WebAuthEventNames) {
    WebAuthEventNames["SuiteNavLogoutEventName"] = "suiteNavLogout";
})(WebAuthEventNames || (WebAuthEventNames = {}));
/** The value of query parameter logout on success */ export const LogoutSuccessfulQueryParamValue = "1";
