// Utilities
import { TraceLevel } from "@ms/uno-constants/lib/local/configuration/ISettings";
import { ErrorUtilities } from "@ms/uno-errors/lib/local/utilities/ErrorUtilities";
/**
 * Utility to create a proxy for a dynamically loaded component (like an action creator or worker)
 * This is used for calling methods on the component
 *
 * Example:
 *
 * interface ISampleComponent {
 *     doSomething(): void;
 * }
 *
 * Dynamic usage:
 * const component = await loader();
 * component.doSomething();
 *
 * Proxy:
 * const proxy: IComponent = {
 *     doSomething: async () => {
 *         const component = await loader();
 *         component.doSomething();
 *     }
 * }
 *
 *
 * Proxy usage (More concise):
 * proxy.doSomething();
 *
 * IMPORTANT: Absorbing the promise inside the proxy ensures that the caller can call it synchronously.
 * And given that the inner promise is never returned, the caller cannot forcefully await on it either.
 * This ensures that subsequent operations that don't (and should never) depend on the component's result
 * are also executed immediately ensuring optimal performance.
 *
 * @param name Name of the component
 * @param loader Loader that loads the component async (It optionally allows synchronous loading for completeness)
 * @param loggers Loggers
 */
export function createComponentProxy(name, loader, loggers) {
    return new Proxy({}, {
        get(_, methodName) {
            if (typeof methodName === "string") {
                // IMPORTANT: The proxy does not return anything to ensure callers cannot depend upon the result
                return (...args) => {
                    Promise.resolve(loader())
                        .then((component) => component[methodName].bind(component).apply(component, args))
                        .catch((error) => {
                        loggers.traceLogger.logTrace(0x1e25a851 /* tag_4j07r */, TraceLevel.Error, `[Component=${name}][Method=${methodName}][Error=${ErrorUtilities.getMessage(error)}]`);
                    });
                };
            }
        },
    });
}
