/**
 * Names of all the workers
 */ export var Worker;
(function(Worker) {
    Worker["AugloopTileSync"] = "AugloopTileSync";
    Worker["BasicPlanContainerFetcher"] = "BasicPlanContainerFetcher";
    Worker["BasicPlanConversionTracker"] = "BasicPlanConversionTracker";
    Worker["BootObserver"] = "BootObserver";
    Worker["CdsProvisioningStatus"] = "CdsProvisioningStatus";
    Worker["ChildAppInstantiationWorker"] = "ChildAppInstantiationWorker";
    Worker["DeltaSync"] = "DeltaSync";
    Worker["DeltaSyncFeedProcessor"] = "DeltaSyncFeedProcessor";
    Worker["HistoryObserver"] = "HistoryObserver";
    Worker["Navigation"] = "Navigation";
    Worker["NetworkConnectivity"] = "NetworkConnectivity";
    Worker["OperationTracking"] = "OperationTracking";
    Worker["PerformanceObserver"] = "PerformanceObserver";
    Worker["PlanConversionStatusTracker"] = "PlanConversionStatusTracker";
    Worker["PremiumPlanCreationStatus"] = "PremiumPlanCreationStatus";
    Worker["ProjectTrialOperationTracker"] = "ProjectTrialOperationTracker";
    Worker["RecommendedPlanUpdate"] = "RecommendedPlanUpdate";
    Worker["StoreHydration"] = "StoreHydration";
    Worker["DataFetch"] = "DataFetch";
    Worker["CacheUpdate"] = "CacheUpdate";
    Worker["Mru"] = "Mru";
    Worker["Modal"] = "Modal";
    Worker["WorkflowCopilot"] = "WorkflowCopilot";
    Worker["CacheEviction"] = "CacheEviction";
})(Worker || (Worker = {}));
