// Base pre-fetcher
import { DataPreFetcher } from "../../base/preFetcher/DataPreFetcher";
/**
 * Data pre-fetcher that is used to prefetch data for Web app
 */
export class WebAppDataPreFetcher extends DataPreFetcher {
    /**
     * Constructor for WebAppDataPreFetcher
     * @param deeplinkRoute The deeplink route to prefetch data for if any
     * @param userId The user ID to prefetch data for
     * @param getAccessTokenFromMSAL Function to get access token from MSAL
     */
    constructor(deeplinkRoute, userId, getAccessTokenFromMSAL) {
        super();
        this.deeplinkRoute = deeplinkRoute;
        this.userId = userId;
        this.getAccessTokenFromMSAL = getAccessTokenFromMSAL;
    }
    setupHostDependencies() {
        // No op. For the web app data prefetcher, the pre-fetch process is triggered from the app bootstrap itself after all critical dependencies are set up.
        // Therefore, no additional setup is required in the Prefetcher.
        return Promise.resolve();
    }
    getInitialDeeplinkRoute() {
        return Promise.resolve(this.deeplinkRoute);
    }
    async getAccessToken(resource) {
        return await this.getAccessTokenFromMSAL(resource);
    }
    getUserId() {
        return Promise.resolve(this.userId);
    }
}
