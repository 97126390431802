// Actions
import { UpdateChildViewStatusAction } from "@ms/uno-actions/lib/local/unoshell/UpdateChildViewStatusAction";
/**
 * Action creator dealing with uno shell
 */ export class UnoShellActionCreator {
    updateChildViewInstantiationStatus(childView, status) {
        this.dispatcher.dispatchActionAsync(new UpdateChildViewStatusAction(childView, status));
    }
    /**
     * Initializes a new instance of the uno shell action creator
     * @param dispatcher The dispatcher the SPA uses to dispatch actions.
     */ constructor(dispatcher){
        this.dispatcher = dispatcher;
    }
}
