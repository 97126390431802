// Controls
import { BaseComponent } from "./BaseComponent";
// Constants
import { ViewRenderState } from "@ms/uno-constants/lib/local/ViewConstants";
/**
 * A 'SmartComponent' is one that has access to one or more Stores, and therefore needs to listen to change events from them
 * and update data after receiving those events. This class handles the logic of subscribing and unsubscribing to those Stores.
 */ export class SmartComponent extends BaseComponent {
    /**
     * React lifecycle method called after component mounts. At this point, we want to subscribe to all Stores.
     */ componentDidMount() {
        this.props.dispatcherEventManagement.registerSubscription(this.getStoresToSubscribeTo(), this.scheduleRender);
        this.tryMarkingComponentRender();
    }
    /**
     * React lifecycle method called before component unmounts. At this point, we want to unsubscribe from all Stores.
     */ componentWillUnmount() {
        this.props.dispatcherEventManagement.unregisterSubscription(this.getStoresToSubscribeTo(), this.scheduleRender);
        // Unsubscribe from all stores
        for (const store of this.subscribedStores){
            store.unsubscribeFromStore(this.scheduleRender);
        }
    }
    /**
     * When the component updates, set the title of the page
     * @param prevProps The previous props
     * @param prevState The previous state
     */ componentDidUpdate(prevProps, prevState) {
        if (this.shouldResetMarkedComponentRender(prevProps, prevState)) {
            this.isComponentRenderMarked = false;
        }
        this.tryMarkingComponentRender();
    }
    /**
     * Get stores to which the component should subscribe
     * @param stores - Array of Stores to subscribe to
     */ getStoresToSubscribeTo() {
        return [];
    }
    /**
     * Add stores to our list of stores to subscribe to. Note that Stores in the component's props will be
     * automatically added and therefore you should not call this method with them.
     * @param stores - Array of Stores to subscribe to
     * @deprecated Use getStoresToSubscribeTo instead
     */ addStoresToSubscribeTo(stores) {
        for (const store of stores){
            store.subscribeToStore(this.scheduleRender);
        }
        this.subscribedStores = stores;
    }
    /**
     * Executed when a store publishes an update event.
     *
     * @deprecated The views should only do one thing in response to a store update, which is to schedule a render.
     * Any resulting state update can make our render cycle complicated and hard to reason about.
     */ storeDidUpdate() {}
    /**
     * Deriving classes can override this method to provide custom logic for determining whether or not the component should render on the store update.
     * @returns true if the component should render in future, false otherwise
     *
     * @deprecated The render passes should be efficient enough that this method should not be needed
     */ shouldRenderComponent() {
        return true;
    }
    /**
     * Determine whether marked component render state needs to be reset on ComponentDidUpdate in the React Lifecycle
     * @param prevProps Previous control properties
     * @param prevState Previous control state
     */ shouldResetMarkedComponentRender(prevProps, prevState) {
        // Default to false. Deriving classes should override this as needed
        return false;
    }
    /**
     * Mark the view render state if it is not marked yet
     */ tryMarkingComponentRender() {
        if (!this.isComponentRenderMarked) {
            this.isComponentRenderMarked = true;
            this.props.actionCreatorProviders.performanceCollection().viewRender(this.name(), ViewRenderState.None);
        }
    }
    constructor(...args){
        super(...args), /** Flag indicating if component render marked. */ this.isComponentRenderMarked = false, /** Flag indicating whether a force update is pending */ this.isForceUpdateScheduled = false, /** Array of Stores to which the component is subscribed */ this.subscribedStores = [], /**
     * Schedule a render of the component
     * Note: Instead of directly calling forceUpdate this method checks if a forceUpdate
     * is already scheduled, and only schedules one if required. This ensures that we do
     * not have back-to-back synchronous render requests.
     */ this.scheduleRender = ()=>{
            // Schedule a force update if not already scheduled
            if (!this.isForceUpdateScheduled) {
                this.isForceUpdateScheduled = true;
                // Schedule a force update instead of calling it synchronously
                // to avoid unnecessary back-to-back synchronous render requests.
                setTimeout(()=>{
                    // Reset the flag and force update
                    this.isForceUpdateScheduled = false;
                    this.forceUpdate();
                    this.storeDidUpdate();
                });
            }
        };
    }
}
