// Supported cultures for different libraries the app uses e.g. Moment
/**
 * List of ll-cc or just ll cultures that are rtl. Entries will be in the ll-cc format or ll format
 * https://lingohub.com/academy/best-practices/rtl-language-list
 */ export const rtlCultures = [
    "ar",
    "he",
    "ur",
    "ku",
    "fa"
];
/**
 * A list of the currently supported cultures by the moment library
 */ export const momentCultures = [
    "ar",
    "ar-ly",
    "ar-ma",
    "ar-sa",
    "ar-tn",
    "bg",
    "ca",
    "cs",
    "cy",
    "da",
    "de",
    "de-at",
    "el",
    "en",
    "en-au",
    "en-ca",
    "en-gb",
    "en-ie",
    "en-in",
    "en-nz",
    "es",
    "es-do",
    "et",
    "eu",
    "fi",
    "fil",
    "fr",
    "fr-ca",
    "fr-ch",
    "gl",
    "he",
    "hi",
    "hr",
    "hu",
    "id",
    "is",
    "it",
    "ja",
    "ka",
    "kk",
    "ko",
    "lt",
    "lv",
    "ml",
    "ms-my",
    "nb",
    "nl",
    "nn",
    "no",
    "pl",
    "pt",
    "pt-br",
    "ro",
    "ru",
    "sr-cyrl",
    "sr",
    "sk",
    "sl",
    "sv",
    "th",
    "tr",
    "uk",
    "vi",
    "zh-cn",
    "zh-hk",
    "zh-tw"
];
// The default moment culture
export const defaultMomentCulture = "en";
/**
 * -------------------------------------------------------------------------
 * Temporal Locale Constants
 * These constants are used for Temporal.js date handling and locale-specific
 * week calculations. They define which regions/languages use different
 * first days of the week:
 * - Saturday: Common in Middle East
 * - Sunday: Common in North America and parts of Asia
 * - Monday: Common in Europe (default)
 * -------------------------------------------------------------------------
 */ // locales that start the week on Saturday
export const RegionSaturday = [
    "ae",
    "af",
    "bh",
    "dj",
    "dz",
    "eg",
    "iq",
    "ir",
    "jo",
    "kw",
    "ly",
    "om",
    "qa",
    "sd",
    "sy"
];
// locales that start the week on Sunday
export const RegionSunday = [
    "ag",
    "ar",
    "as",
    "au",
    "bd",
    "br",
    "bs",
    "bt",
    "bw",
    "bz",
    "ca",
    "cn",
    "co",
    "dm",
    "do",
    "et",
    "gt",
    "gu",
    "hk",
    "hn",
    "id",
    "il",
    "in",
    "jm",
    "jp",
    "ke",
    "kh",
    "kr",
    "la",
    "mh",
    "mm",
    "mo",
    "mt",
    "mx",
    "mz",
    "ni",
    "np",
    "pa",
    "pe",
    "ph",
    "pk",
    "pr",
    "pt",
    "py",
    "sa",
    "sg",
    "sv",
    "th",
    "tt",
    "tw",
    "um",
    "us",
    "ve",
    "vi",
    "ws",
    "ye",
    "za",
    "zw"
];
// Languages codes that start the week on Saturday
export const LanguageSaturday = [
    "ar",
    "arq",
    "arz",
    "fa"
];
// Languages codes that start the week on Sunday
export const LanguageSunday = [
    "am",
    "as",
    "bn",
    "dz",
    "en",
    "gn",
    "gu",
    "he",
    "hi",
    "id",
    "ja",
    "jv",
    "km",
    "kn",
    "ko",
    "lo",
    "mh",
    "ml",
    "mr",
    "mt",
    "my",
    "ne",
    "om",
    "or",
    "pa",
    "ps",
    "sd",
    "sm",
    "sn",
    "su",
    "ta",
    "te",
    "th",
    "tn",
    "ur",
    "zh",
    "zu",
    "vi",
    "fil",
    "quz"
];
// Regex to parse locale string it will produce an array of 5 elements as the 2nd element be the language code and the 5th be the region code
// eslint-disable-next-line security/detect-unsafe-regex
export const LocaleRegex = /^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i;
/**
 * Culture configuration including supported cultures, fallbacks, and default culture
 */ export const culturesMap = {
    cultures: [
        "af",
        "am-et",
        "ar",
        "as-in",
        "az-latn-az",
        "bg",
        "bn-in",
        "bs-latn-ba",
        "ca",
        "ca-es-valencia",
        "cs",
        "cy-gb",
        "da",
        "de",
        "el",
        "en-gb",
        "en-us",
        "es",
        "es-mx",
        "et",
        "eu",
        "fa",
        "fi",
        "fil-ph",
        "fr",
        "fr-ca",
        "ga-ie",
        "gd",
        "gl",
        "gu",
        "he",
        "hi",
        "hr",
        "hu",
        "id",
        "is",
        "it",
        "ja",
        "ka",
        "kk",
        "km-kh",
        "kn",
        "ko",
        "kok",
        "lb-lu",
        "lo",
        "lt",
        "lv",
        "mi-nz",
        "mk",
        "ml",
        "mr",
        "ms",
        "mt-mt",
        "nb-no",
        "ne-np",
        "nn-no",
        "nl",
        "or-in",
        "pa",
        "pl",
        "pt-br",
        "pt-pt",
        "quz-pe",
        "ro",
        "ru",
        "sk",
        "sl",
        "sq",
        "sr-cyrl-ba",
        "sr-cyrl-rs",
        "sr-latn-rs",
        "sv",
        "ta",
        "te",
        "th",
        "tr",
        "tt",
        "ug",
        "uk",
        "ur",
        "uz-latn-uz",
        "vi",
        "zh-cn",
        "zh-tw"
    ],
    fallbacks: {
        cy: "cy-gb",
        nn: "nn-no",
        en: "en-us",
        fil: "fil-ph",
        nb: "nb-no",
        pt: "pt-pt",
        zh: "zh-cn"
    },
    defaultCulture: "en-gb"
};
