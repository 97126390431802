// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
import { NotificationEvents } from "../notificationEvents/NotificationEvents";
import { PlanContextDetails } from "./PlanContextDetails";
import { Background } from "../background/Background";
import { PlanDetails } from "./PlanDetails";
import { DeltaTranslationMap } from "../deltaSync/DeltaTranslationMap";
// Utility
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import cloneDeep from "lodash/cloneDeep";
import pickBy from "lodash/pickBy";
import keys from "lodash/keys";
/**
 * Configuration and building of plan details objects
 */ export class PlanDetailsBuilder {
    /**
     * Build out the plan details
     */ build() {
        return new PlanDetails(this);
    }
    /**
     * Build from a plan details resource entity as initialization data.
     * @param planDetailsResource Plan resource entity to use for initializing the plan.
     */ fromGraphResource(planDetailsResource) {
        const categories = [];
        let i = 0;
        let categoryName = null;
        while(categoryName !== undefined){
            categoryName = planDetailsResource.categoryDescriptions["category" + (i + 1)];
            if (categoryName !== undefined) {
                categories[i] = categoryName;
            }
            i++;
        }
        // Extract keys with value true
        const sharedWith = keys(pickBy(planDetailsResource.sharedWith, (value)=>{
            return value;
        }));
        // TODO: Odata doesn't currently return anything for notify when
        return this.withPropertyBag({
            categories: categories,
            id: planDetailsResource.id,
            background: new Background(planDetailsResource.background.baseColor, planDetailsResource.background.overlayImageUrl),
            itemVersion: planDetailsResource["@odata.etag"],
            sharedWith: sharedWith,
            contextDetails: PlanContextDetails.getContextsFromGraphResource(planDetailsResource.contextDetails || {}),
            iCalendarPublishEnabled: planDetailsResource.allowICalendarAccess,
            iCalendarPublishUrl: planDetailsResource.iCalendarAccessUrl
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        this.categories = propertyBag.categories || this.categories;
        this.id = propertyBag.id;
        this.itemVersion = propertyBag.itemVersion || this.itemVersion;
        this.notifyWhen = propertyBag.notifyWhen || this.notifyWhen;
        this.sharedWith = propertyBag.sharedWith || this.sharedWith;
        this.iCalendarPublishEnabled = propertyBag.iCalendarPublishEnabled || this.iCalendarPublishEnabled;
        this.iCalendarPublishUrl = propertyBag.iCalendarPublishUrl || this.iCalendarPublishUrl;
        this.contextDetails = propertyBag.contextDetails || this.contextDetails;
        this.background = propertyBag.background || this.background;
        return this;
    }
    /**
     * Set the id of the plan details
     * @param id Id to set
     */ withId(id) {
        this.id = id;
        return this;
    }
    /**
     * Add a locally unique id to the plan
     */ withLocallyUniqueId() {
        this.id = LocalIdGenerator.generate();
        return this;
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const translatePlanContextDetailsUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const contextDetails = storeCopy?.contextDetails ? cloneDeep(storeCopy.contextDetails) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete contextDetails[key];
                } else if (key in contextDetails) {
                    // Update Item values - may include partial properties
                    if ("url" in graphUpdates[key]) {
                        contextDetails[key] = contextDetails[key].setProperty("url", graphUpdates[key].url);
                    }
                    if ("state" in graphUpdates[key]) {
                        contextDetails[key] = contextDetails[key].setProperty("status", graphUpdates[key].state);
                    }
                } else {
                    // Insert item
                    contextDetails[key] = PlanContextDetails.getPlanContextDetailsFromGraph(graphUpdates[key]);
                }
            }
            return contextDetails;
        };
        const translateCategoryUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const categories = storeCopy?.categories ? cloneDeep(storeCopy.categories) : [];
            for(const key in graphUpdates){
                if (key.indexOf("category") === 0) {
                    const categoryIndex = parseInt(key.replace("category", ""), 10) - 1;
                    categories[categoryIndex] = graphUpdates[key] || "";
                }
            }
            return categories;
        };
        const translateSharedWithUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const sharedWith = storeCopy?.sharedWith ? cloneDeep(storeCopy.sharedWith) : [];
            for(const userId in graphUpdates){
                const idx = sharedWith.indexOf(userId);
                if ((graphUpdates[userId] == null || graphUpdates[userId] === false) && idx !== -1) {
                    // Remove Item
                    sharedWith.splice(idx, 1);
                } else if (graphUpdates[userId] === true && idx === -1) {
                    // Add Item
                    sharedWith.push(userId);
                }
            }
            return sharedWith;
        };
        const translateICalendarAccessUrl = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            return graphUpdates;
        };
        const translateICalendarPublishEnabled = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            return graphUpdates;
        };
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("sharedWith", "sharedWith", translateSharedWithUpdates);
        graphTranslationMap.addMapping("categoryDescriptions", "categories", translateCategoryUpdates);
        graphTranslationMap.addMapping("contextDetails", "contextDetails", translatePlanContextDetailsUpdates);
        graphTranslationMap.addMapping("iCalendarAccessUrl", "iCalendarPublishUrl", translateICalendarAccessUrl);
        graphTranslationMap.addMapping("allowICalendarAccess", "iCalendarPublishEnabled", translateICalendarPublishEnabled);
        return graphTranslationMap.translate(update);
    }
    /**
     * Initialize an instance of the plan details builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.itemVersion = "";
        this.categories = [];
        this.sharedWith = [];
        this.sharedWith = [];
        this.notifyWhen = NotificationEvents.None;
        this.iCalendarPublishUrl = null;
        this.iCalendarPublishEnabled = false;
        this.contextDetails = {};
        this.background = new Background(null, null);
    }
}
