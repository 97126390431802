// App Bootstrap
import { getActionCreatorProviders, getAsyncDispatcher, getAuthServiceProvider, getContextAndInitializeCultureData, getLogicModuleProviders, getRequestPrioritizer, getServiceProviders, getStoreProviders, getTelemetryContext, loadAndInitializeWorkers, logPlanViewSubRouteType, setupAppContext, getAgentProviders, getPersistentAsyncStorage, } from "../utilities/AppBootstrapUtils";
import { getInitialRoute } from "../utilities/AppBootstrapRouteUtils";
import { addGccPathToURL, removeGccPathFromBrowserUrl, removeGccPathFromURL } from "../utilities/WebAppBootstrapUtils";
import { WebAppDataPreFetcher } from "./preFetcher/WebAppDataPreFetcher";
import { AppRootElementId, ClientAppId, Environment, TenantRegion } from "@ms/uno-constants/lib/local/AppConstants";
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { LoggingStrings } from "@ms/uno-constants/lib/local/LoggingConstants";
import { TelemetryPlatform } from "@ms/uno-constants/lib/local/TelemetryConstants";
import { LogoutSuccessfulQueryParamValue, WebAuthEventNames, WebQueryParams, WebUrlSegments, } from "@ms/uno-constants/lib/local/WebConstants";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
import { NewsBarNewsIds } from "@ms/uno-constants/lib/local/NewsBarConstants";
// Controls
import { LazyGeneralErrorView } from "@ms/uno-controls/lib/local/controls/presentational/generalErrorView/LazyGeneralErrorView";
import { applyUnoGlobalStyleOverrides } from "@ms/uno-shell/lib/local/controls/unoShell/Global.styles";
import { UnoShell } from "@ms/uno-shell/lib/local/controls/unoShell/UnoShell";
import { LandingPage } from "./LandingPage";
import { ProductionServiceOrchestrator } from "../base/factories/ProductionServiceOrchestrator";
import { WebActionCreatorFactory } from "./factories/WebActionCreatorFactory";
import { WebLogicModuleFactory } from "./factories/WebLogicModuleFactory";
import { WebServiceFactory } from "./factories/WebServiceFactory";
import { WebStoreFactory } from "./factories/WebStoreFactory";
import { AgentFactory } from "../base/factories/AgentFactory";
import { FluxRegistry } from "@ms/uno-fluxcore/lib/local/registry";
import { UnoRegistryDescriptor } from "../UnoRegistryDescriptor";
import { DynamicImportFailureAction } from "@ms/uno-actions/lib/local/app/DynamicImportFailureAction";
// Host Adaptor
import { FailedReason } from "@ms/uno-hostadaptors/lib/local/IUnoHostAdaptor";
import { WebAdaptor } from "@ms/uno-hostadaptors/lib/local/WebAdaptor";
import { logNotificationInfo } from "@ms/uno-routing";
import { WebWorkerLoader } from "./loaders/WebWorkerLoader";
import { User } from "@ms/uno-models/lib/local/client/user/User";
import { LoggerManager } from "@ms/uno-telemetry/lib/local/LoggerManager";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { initializeEngagementLogger, resetLogHandler, setCurrentRouteInfoForLogs, } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
import { WebPlatformPerformanceMarker } from "@ms/uno-telemetry/lib/local/performance/WebPlatformPerformanceMarker";
// Utilities
import { generateGuid } from "@ms/uno-utilities/lib/local/Guid";
import { MsalCacheLocation, MsalHelper } from "@ms/uno-utilities/lib/local/auth/msal/MsalHelper";
import { getLocalStorage } from "@ms/uno-utilities/lib/local/storage/StorageUtilities";
import { ComponentLoader } from "../utilities/loader/ComponentLoader";
import { createDefaultPolicy } from "@ms/uno-utilities/lib/local/TrustedTypeUtilities";
import React, { Suspense } from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";
import { getLocale } from "@ms/uno-hostadaptors/lib/local/utils/WebAdaptorUtils";
import { initializeCultureData } from "@ms/uno-shell/lib/local/utilities/CultureUtilities";
/**
 * The web bootstrap function
 */
export async function bootstrap() {
    // Set up performance Marker and mark bootstrap
    const performanceMarker = new WebPlatformPerformanceMarker();
    performanceMarker.mark(MarkerTag.AppBootstrap);
    // Localize translations
    const locale = getLocale();
    await initializeCultureData(locale);
    // Set up AppContext and make UNO Web PPE adjustment below
    try {
        setupAppContext();
    }
    catch (error) {
        handleInfraSetupError(error);
        throw error;
    }
    // For UNO Web PPE, we still want to call PROD project service (rather than PPE)
    if (AppContext.appConfiguration.sessionMetaData.environment === Environment.Ppe) {
        // Note: The default project settings point to PROD
        const defaultProjectSettings = AppContext.getDefaultSettings().serviceConfigurations.project;
        AppContext.appConfiguration.settings.serviceConfigurations.project.authResourceURI = defaultProjectSettings.authResourceURI;
        AppContext.appConfiguration.settings.serviceConfigurations.project.hostname = defaultProjectSettings.hostname;
    }
    const configProvider = () => AppContext.appConfiguration;
    const queryParams = new URLSearchParams(window.location.search);
    // Grab userId/tenantId from query params
    const userId = queryParams.get(WebQueryParams.UserId) ?? undefined;
    const tenantId = queryParams.get(WebQueryParams.TenantId) ?? undefined;
    const logoutValue = queryParams.get(WebQueryParams.Logout) ?? "0";
    const loginHint = queryParams.get(WebQueryParams.Upn) ?? undefined;
    const isOcdiRedirectSession = !!queryParams.get(WebQueryParams.OcdiRedirect);
    const isUnowebRedirectSession = !!queryParams.get(WebQueryParams.UnowebRedirect);
    // Set up the logger object for preauthed request.
    const preAuthTelemetryContext = {
        // Using the tenantRegion here in case the tenantId was specified in the url and we know the region
        locale: configProvider().sessionMetaData.tenantRegion,
        userId: null,
        tenantId: null,
        ringId: "", // Teams ring according to Teams client SDK
        isMSA: false,
        isGuestUser: false, // Setting it to false before we login.
        platform: TelemetryPlatform.Web,
    };
    // Setup loggers
    const loggerManager = new LoggerManager(preAuthTelemetryContext, configProvider);
    const telemetryLoggers = loggerManager.getLoggers();
    const loggers = {
        ...telemetryLoggers,
        performanceMarker,
    };
    const preAuthMsalCorrelationId = generateGuid();
    loggers.traceLogger.logTrace(0x1e2e164b /* tag_4l7zl */, TraceLevel.Info, `[WebAppBootstrap] Pre-Auth correlationIds`, {
        AppLoadCorrelationId: configProvider().sessionMetaData.appLoadCorrelationId,
        SessionId: configProvider().sessionMetaData.sessionId,
        MsalCorrelationId: preAuthMsalCorrelationId,
    });
    const clientType = configProvider().sessionMetaData.appMetadata.clientType;
    const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
    const environment = configProvider().sessionMetaData.environment;
    const clientVersion = configProvider().sessionMetaData.clientBuild;
    const clientName = LoggingStrings.ProductName;
    const isGccSubRoute = window.location.pathname.toLowerCase().startsWith(`/${WebUrlSegments.WebUI}/${WebUrlSegments.GccPath}`);
    const pathSuffix = isGccSubRoute ? `/${WebUrlSegments.GccPath}` : "";
    const defaultRedirectUrlPath = `/${WebUrlSegments.WebUI}${pathSuffix}`;
    const defaultBlankRedirectUrlPath = `/${WebUrlSegments.WebUI}${pathSuffix}/${WebUrlSegments.BlankMsalRedirect}`;
    let postLogoutRedirectUriPath = defaultRedirectUrlPath;
    let redirectUrlPath = defaultRedirectUrlPath;
    let blankRedirectUrlPath = defaultBlankRedirectUrlPath;
    const msalSettings = configProvider().settings.auth.msalSettings;
    if (msalSettings) {
        postLogoutRedirectUriPath = msalSettings.postLogoutRedirectUrlPath
            ? msalSettings.postLogoutRedirectUrlPath
            : defaultRedirectUrlPath;
        redirectUrlPath = msalSettings.redirectUrlPath ? msalSettings.redirectUrlPath : defaultRedirectUrlPath;
        blankRedirectUrlPath = msalSettings.blankRedirectUrlPath ? msalSettings.blankRedirectUrlPath : defaultBlankRedirectUrlPath;
    }
    const silentRedirectUrl = constructRedirectUrl(blankRedirectUrlPath);
    // If this is the blank msal redirect page, we should not render the app.
    // This is called as a part of silent auth flow in a hidden iframe.
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
    if (window.location.pathname === silentRedirectUrl.pathname) {
        return;
    }
    // Initialize Msal
    try {
        const msalConfigSettings = {
            clientId: ClientAppId.MicrosoftPlannerClient,
            msalCacheLocation: MsalCacheLocation.LocalStorage,
            environment,
            clientType,
            redirectUrl: constructRedirectUrl(redirectUrlPath),
            silentRedirectUrl,
            postLogoutRedirectUrl: constructPostLogoutUri(postLogoutRedirectUriPath, tenantId),
            clientName,
            clientVersion,
            correlationId: preAuthMsalCorrelationId,
        };
        const msalHelperOptions = {
            useCachedPromises: true,
            handleInteractionRequiredOnSilentLogin: !configProvider().changeGates.DisableAuthInteractionRequiredUsingPopup,
            cp1ClientCapability: !configProvider().changeGates.DisableCp1ClientCapabilityWeb,
        };
        await MsalHelper.initialize(msalConfigSettings, msalHelperOptions, loggers);
    }
    catch (error) {
        handleInfraSetupError(error, configProvider().sessionMetaData.sessionId, loggers.traceLogger);
        // Unfortunately, we can't really continue without MSAL
        throw error;
    }
    const isCalledByLogout = logoutValue === LogoutSuccessfulQueryParamValue;
    // If logout redirected us here with no tenantId, show the landing page.
    // If tenantId is present, we should attempt to login to that tenant after logout
    // since it means that the user was logged in and then attempted to login as guest in another tenant.
    // Only one user is signed in at a time in Uno web.
    // This matches legacy Planner web behavior.
    if (isCalledByLogout) {
        removeQueryParamsFromUrl(WebQueryParams.Logout);
        if (!tenantId) {
            showLandingPage(loggers, tenantId);
            return;
        }
    }
    try {
        loggers.traceLogger.logTrace(0x1e29d057 /* tag_4k3bx */, TraceLevel.Info, `[Bootstrap] msal ensureLoggedIn. [tenantId=${tenantId}][hasUserId=${!!userId}][hasLoginHint=${!!loginHint}]`);
        await MsalHelper.instance.ensureLoggedIn({
            ensureActiveAccount: true,
            forceLogin: false,
            tenantId,
            userId,
            loginHint,
        });
    }
    catch (error) {
        const showLoginPage = !!tenantId || !!loginHint || !!error?.claims;
        const errorMessage = showLoginPage
            ? `Showing login page. [TenantId=${tenantId}][hasLoginHint=${!!loginHint}][hasClaims=${!!error?.claims}]`
            : "Displaying landing page.";
        loggers.traceLogger.logTrace(0x1e307205 /* tag_4mhif */, TraceLevel.Warning, `[Bootstrap] Error during login. ${errorMessage}`);
        if (showLoginPage) {
            // For Guest user take them to login screen without showing landing page
            await forceLogin(loggers, tenantId, error?.claims, loginHint);
        }
        else {
            showLandingPage(loggers, tenantId);
        }
        return;
    }
    const activeAccount = MsalHelper.instance.getActiveAccount();
    // If there is no active account, show the landing page
    if (!activeAccount) {
        showLandingPage(loggers, tenantId);
        return;
    }
    // Set the telemetryRegion from the token
    if (configProvider().sessionMetaData.tenantRegion === TenantRegion.Eu && activeAccount.telemetryRegion === TenantRegion.Row) {
        loggers.traceLogger.logTrace(0x1e301814 /* tag_4mb6u */, TraceLevel.Warning, `[Bootstrap] Telemetry region mismatch. We are not updating the region. MTRS thinks region is EU. The token says the region is ROW. TenantId: ${activeAccount.tenantId}`);
    }
    else {
        configProvider().sessionMetaData.tenantRegion = activeAccount.telemetryRegion;
    }
    // Stop the preauthorization logger
    // This is required for compliance since the user is now logged in and could be in EU.
    // We will stop the msal logging and resume when the MsalLogger is reset below.
    MsalHelper.instance.stopLogger();
    // If the userId and tenantId query params are not present, reload the page with the query params.
    if (!userId || !tenantId || !MsalHelper.instance.validateActiveAccount(userId, tenantId)) {
        reloadWithUserAndTenantQueryParamsIfSpecified();
        return;
    }
    // Reset any subRoutePaths that are used only for authentication routing purposes
    // For instance, /gcc subpath is a url planner wepb path that is used only for routing to MTRS retail service on PROD
    removeGccPathFromBrowserUrl();
    // Remove query param userId from the URL
    removeQueryParamsFromUrl(WebQueryParams.UserId);
    // Initialize the host adaptor
    const hostAdaptor = new WebAdaptor(configProvider, () => MsalHelper.instance);
    const context = await getContextAndInitializeCultureData(hostAdaptor);
    const telemetryContext = getTelemetryContext(context);
    resetLogHandler(telemetryContext, loggers.logHandler, configProvider);
    // Reset msal logger
    const postAuthMsalCorrelationId = generateGuid();
    MsalHelper.instance.setLogger(loggers, clientName, clientVersion, postAuthMsalCorrelationId);
    // Set logger on host adaptor
    hostAdaptor.setLoggers(loggers);
    loggers.traceLogger.logTrace(0x1e2e164a /* tag_4l7zk */, TraceLevel.Info, `[WebAppBootstrap] Post-Auth CorrelationIds`, {
        MsalCorrelationId: postAuthMsalCorrelationId,
    });
    // Sanity check for mismatch on GCC vs Prod environment
    if ((environment === Environment.Prod && activeAccount.accountEnvironment === Environment.Gcc) ||
        (environment === Environment.Gcc && activeAccount.accountEnvironment === Environment.Prod)) {
        // Detected that we load page/bundle from one environment but account is the other, this should not happen for GCC/Prod.
        // If happens ensure there is a proper handling of routing/redirect for Gcc+Prod users.
        loggers.traceLogger.logTrace(0x1e2e28d1 /* tag_4l89r */, TraceLevel.Error, `[Bootstrap] Gcc vs Prod mismatch for account environment. [env=${environment}][accountEnvironment=${activeAccount.accountEnvironment}]`);
    }
    // Prefetch main scopes for app load and evaluate conditional access policies if required
    prefetchAndEvaluateConditionalAccessAsync(configProvider, loggers);
    // Setup the flux registry
    const registry = new FluxRegistry(UnoRegistryDescriptor);
    let initialRoute = getInitialRoute(clientFlavor);
    const initialRouteFromDeepLink = await hostAdaptor.getInitialRoute();
    if (initialRouteFromDeepLink != null) {
        initialRoute = initialRouteFromDeepLink;
    }
    setCurrentRouteInfoForLogs(initialRoute.subRouteType, initialRoute.subRoute, loggers.logHandler);
    // Triggering data pre-fetcher
    if (configProvider().flights.EnableDataPreFetcherForWeb) {
        const dataPreFetcher = new WebAppDataPreFetcher(initialRouteFromDeepLink, activeAccount.userId, async (resource) => {
            const token = await hostAdaptor.getAccessToken(resource);
            return token.accessToken;
        });
        dataPreFetcher.init();
    }
    // Setup persistent storage
    const persistentStorage = getLocalStorage(context.userId, loggers.traceLogger, configProvider);
    // Check if the combination of rollout stage and environment is unexpected
    const isCombinationUnexpected = AppContext.isRolloutStageAndEnvironmentCombinationUnexpected();
    if (isCombinationUnexpected) {
        loggers.traceLogger.logTrace(0x1e355147 /* tag_4nvfh */, TraceLevel.Error, `[Bootstrap] Found invalid combination of rollout stage and environment: [RolloutStage=${configProvider().settings.rolloutStage}][Environment=${configProvider().sessionMetaData.environment}`);
    }
    // Initialize Stores
    const user = User.fromUserContext(context);
    const defaultQueryParameters = hostAdaptor.getDefaultQueryParameters();
    const loader = new ComponentLoader(loggers);
    const storeFactory = new WebStoreFactory(loader, context, user, initialRoute, loggers, persistentStorage, configProvider, initialRouteFromDeepLink != null, defaultQueryParameters);
    const storeProviders = getStoreProviders(storeFactory);
    // Initialize agent providers
    const agentFactory = new AgentFactory(loader);
    const agentProviders = getAgentProviders(agentFactory);
    // Initialize engagement logging
    initializeEngagementLogger(storeProviders.route().getCurrentSubRouteType.bind(storeProviders.route()), loggers.engagementLogger);
    // Log relevant engagement info (which can only be done AFTER we've initialized EngagementLogger)
    loggers.engagementLogger.logEngagement(EngagementTelemetryConstants.WebApp, EngagementTelemetryConstants.Bootstrap);
    logNotificationInfo(initialRoute, loggers);
    logPlanViewSubRouteType(EngagementTelemetryConstants.WebApp, storeProviders.route().getCurrentSubRouteType(), loggers);
    // Dispatcher for the flux system.
    const dispatcher = getAsyncDispatcher(configProvider, registry, storeFactory, loggers);
    loader.setOnFailure((name) => dispatcher.dispatchActionAsync(new DynamicImportFailureAction()));
    // Set up request prioritizer
    const requestPrioritizer = getRequestPrioritizer(storeProviders);
    // Setup Providers
    const serviceFactory = new WebServiceFactory(loader, loggers);
    // lazy loaded logic modules depend on lazy loaded services typically but authModule as an exception is needed by services upfront.
    // That is why authModule is created here and passed to services.
    const authService = getAuthServiceProvider(serviceFactory, dispatcher, hostAdaptor, loggers, configProvider)();
    const authStore = storeFactory.getAuthStoreProvider()();
    const lmFactory = new WebLogicModuleFactory(loader, loggers);
    const authModule = lmFactory.getAuthModuleProvider(dispatcher, authService, authStore, configProvider)();
    // Auth module's fetchAccessTokenAsync method needed by services
    const fetchAccessToken = authModule.fetchAccessTokenAsync.bind(authModule);
    // Auth module's refreshAccessTokenAsync method needed by services
    const refreshAccessToken = authModule.refreshAccessTokenAsync.bind(authModule);
    const serviceOrchestrator = new ProductionServiceOrchestrator(serviceFactory, requestPrioritizer, configProvider, loggers, fetchAccessToken, refreshAccessToken);
    const serviceProviders = getServiceProviders(serviceOrchestrator, authService);
    const lmProviders = getLogicModuleProviders(lmFactory, dispatcher, serviceProviders, storeProviders, configProvider, hostAdaptor, authService, authStore, fetchAccessToken);
    const acFactory = new WebActionCreatorFactory(loader, dispatcher, lmProviders, storeProviders, configProvider, loggers);
    const acProviders = getActionCreatorProviders(acFactory);
    const modalActionCreator = await acProviders.modal();
    // Register msal interaction required event handler
    registerMsalErrorHandler(modalActionCreator, loggers, tenantId, storeProviders, configProvider);
    // Fire app start action (must be done after store init)
    const perfCollectionActionCreator = acProviders.performanceCollection();
    perfCollectionActionCreator.appStart(storeProviders.route().getCurrentPath(), Date.now());
    // Setup persistent async storage
    const persistentAsyncStorage = getPersistentAsyncStorage(configProvider, context.userId, loggers);
    const workerLoader = new WebWorkerLoader(acProviders, storeProviders, (store) => storeFactory.loadForPersistence(store), (store) => storeFactory.loadForHydration(store), loader, loggers, telemetryLoggers, WebUrlSegments.WebUI, persistentAsyncStorage, configProvider, serviceProviders, lmProviders, hostAdaptor, dispatcher);
    loadAndInitializeWorkers(workerLoader, requestPrioritizer, loggers, persistentAsyncStorage, configProvider, hostAdaptor);
    // Apply Global styles before any classes or styles are generated.
    applyUnoGlobalStyleOverrides();
    // Register theme change handler to update theme
    hostAdaptor.registerThemeChangeHandler(async (theme) => {
        const appContextAc = await acProviders.appContext();
        appContextAc.updateAppTheme(theme);
    });
    // Register logout event handler
    window.addEventListener(WebAuthEventNames.SuiteNavLogoutEventName, async () => {
        // If the user clicked on logout, we should logout the user and redirect to the landing page.
        // The url should not have the tenantId query param.
        const postLogoutUrl = constructPostLogoutUri(postLogoutRedirectUriPath);
        await MsalHelper.instance.logout(postLogoutUrl);
    });
    const showDevTools = configProvider().sessionMetaData.environment === Environment.Ppe;
    // create default policy
    if (configProvider().flights.EnableDefaultPolicy) {
        createDefaultPolicy();
    }
    // If it is a unoweb redirect session, remove the query param and log
    if (isUnowebRedirectSession) {
        removeQueryParamsFromUrl(WebQueryParams.UnowebRedirect);
        loggers.traceLogger.logTrace(0x1e28125a /* tag_4kbj0 */, TraceLevel.Verbose, `Uno web redirect session`);
    }
    // If it is a ocdi redirect session, remove the query param and show the news bar banner
    if (isOcdiRedirectSession) {
        const newsBarAC = await acProviders.newsBar();
        newsBarAC.showNewsBar(NewsBarNewsIds.OcdiRedirectExperience);
        removeQueryParamsFromUrl(WebQueryParams.OcdiRedirect);
        loggers.traceLogger.logTrace(0x1e2998a0 /* tag_4kz86 */, TraceLevel.Info, `Ocdi redirect session`);
    }
    ReactDOM.render(React.createElement(UnoShell, { dispatcherEventManagement: dispatcher, actionCreatorProviders: acProviders, logicModuleProviders: lmProviders, serviceProviders: serviceProviders, storeProviders: storeProviders, workerLoader: workerLoader, hostAdaptor: hostAdaptor, loggers: loggers, onLoad: hostAdaptor.notifyLoaded, onSuccess: () => { }, onFail: (message) => hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message }), configProvider: configProvider, showDevTools: showDevTools, agentProviders: agentProviders }), document.getElementById(AppRootElementId));
}
/**
 * Register the msal error handler
 * @param modalActionCreator Modal action creator
 * @param loggers Logger context
 * @param tenantId The tenantId
 * @param storeProviders Store providers
 * @param configProvider App configuration provider
 */
function registerMsalErrorHandler(modalActionCreator, loggers, tenantId, storeProviders, configProvider) {
    // Register the interaction required error handler
    MsalHelper.instance.registerInteractionRequiredErrorHandler(async (claims, isPopupBlockedError) => {
        if (!configProvider().changeGates.DisableForceAuthRefreshOnInactivity) {
            const activityStore = await storeProviders.activity();
            const lastUserActivity = activityStore.getLastUserActivityTimestamp();
            const now = moment();
            const AutoRefreshIfInactiveMinutes = 1;
            if (lastUserActivity && now.diff(lastUserActivity, "minutes") > AutoRefreshIfInactiveMinutes) {
                // When user is inactive instead of displaying the auth error modal we force the sign-in auth callback to refresh auth.
                // This normally happens when user leaves browser open for a long time and token expires
                // Result here is likely either a silent sign-in SSO to just work and session gets refreshed by AAD or AAD stops login and ask for extra auth info (i.e. MFA)
                loggers.traceLogger.logTrace(0x1e29c420 /* tag_4k2q6 */, TraceLevel.Warning, `Forcing sign-in auth callback due to inactivity[hasClaims=${!!claims}]`);
                await forceLogin(loggers, tenantId, claims);
                return;
            }
        }
        // Show auth error modal and sign the user in again with the claims from claims challenge
        modalActionCreator.openAuthErrorModal(async () => {
            await forceLogin(loggers, tenantId, claims);
        }, isPopupBlockedError, null);
    });
    // Register the popup blocked error handler
    MsalHelper.instance.registerPopupBlockedErrorHandler((scopes, onSignInButtonClick) => {
        loggers.traceLogger.logTrace(0x1e28c3a1 /* tag_4kmo7 */, TraceLevel.Info, "Popup window is blocked. Showing user a dialog window to disable popup or click to login.");
        // Show auth error modal and sign the user in again with the claims from claims challenge
        modalActionCreator.openAuthErrorModal(() => {
            // Close the modal and sign the user in again
            modalActionCreator.closeModal();
            onSignInButtonClick();
        }, true, scopes);
    });
}
/**
 * Show the login prompt and force the user to login
 * @param loggers Logger context
 * @param tenantId Tenant Id
 * @param claimsChallenge Claims Challenge
 */
async function forceLogin(loggers, tenantId, claimsChallenge, loginHint) {
    try {
        loggers.traceLogger.logTrace(0x1e29d056 /* tag_4k3bw */, TraceLevel.Info, `[Bootstrap] MSAL forceLogin. [TenantId: ${tenantId}][HasClaimsChallenge: ${!!claimsChallenge}][hasLoginHint: ${!!loginHint}]`);
        await MsalHelper.instance.ensureLoggedIn({
            forceLogin: true,
            ensureActiveAccount: false,
            tenantId: tenantId,
            claimsChallenge,
            loginHint,
        });
    }
    catch (error) {
        loggers.traceLogger.logTrace(0x1e2e1312 /* tag_4l7ms */, TraceLevel.Warning, `[Bootstrap] Error attempting login in forceLogin. [TenantId: ${tenantId}][HasClaimsChallenge: ${claimsChallenge !== undefined}][error.hasClaims: ${!!error?.claims}]`);
        handleInfraSetupError(error);
    }
}
// Prefetch asynchronously main scopes for app load and evaluate conditional access policies if required
function prefetchAndEvaluateConditionalAccessAsync(configProvider, loggers) {
    if (configProvider().changeGates.DisableBootstrapAuthPrefetchAndCAEvaluation) {
        return;
    }
    // Adding graph and Planner Client app scopes as main default to prefetch and evaluate conditional access policies during bootstrap
    // FUTURE: we can potentially update this to include required scopes for current URL, for instance, premium plan -> add project scope
    const prefetchScopes = [
        `${configProvider().settings.serviceConfigurations.graph.authResourceURI}/.default`,
        `${ClientAppId.MicrosoftPlannerClient}/.default`,
    ];
    loggers.traceLogger.logTrace(0x1e29820f /* tag_4kyip */, TraceLevel.Info, `[Bootstrap] prefetch & evaluate CA. [Scopes=${prefetchScopes.join(", ")}]`);
    // Intentionally fire and forget. NOTE: it might trigger a page reload during bootstrap if conditional access policy is not met
    MsalHelper.instance.prefetchAndEvaluateConditionalAccess(prefetchScopes);
}
/**
 * General error handler for basic infra setup error
 * Note: Failing in basic infra setup means we can't really continue
 * @param error The error with basic infra setup
 * @param correlationId The correlationId (if available)
 * @param traceLogger The trace logger (if available)
 */
function handleInfraSetupError(error, correlationId, traceLogger) {
    traceLogger?.logTrace(0x1e2e3596 /* tag_4l9ww */, TraceLevel.Error, `Error in basic infra setup. [ErrorName=${error.name}][CorrelationId=${correlationId}]`);
    ReactDOM.render(React.createElement(Suspense, { fallback: renderEmptyLoadingPage() },
        React.createElement(LazyGeneralErrorView, { correlationId: correlationId })), document.getElementById(AppRootElementId));
}
/**
 * Renders an empty page while loading the error view
 */
function renderEmptyLoadingPage() {
    return React.createElement("div", null);
}
/**
 * Remove the query Parameters from the query string
 */
function removeQueryParamsFromUrl(...queryParams) {
    const url = new URL(window.location.href);
    // Remove query param from the URL
    for (const param of queryParams) {
        url.searchParams.delete(param);
    }
    // Update the URL without reloading the page
    history.replaceState({}, "", url);
}
/**
 * The url that the user will be redirected to after logout.
 * @param postLogoutRedirectUriPath The base post logout redirect url path
 * @param tenantId The tenantId if specified
 */
function constructPostLogoutUri(postLogoutRedirectUriPath, tenantId) {
    const postLogoutUrl = constructRedirectUrl(postLogoutRedirectUriPath);
    postLogoutUrl.searchParams.set(WebQueryParams.Logout, LogoutSuccessfulQueryParamValue);
    if (tenantId) {
        postLogoutUrl.searchParams.set(WebQueryParams.TenantId, tenantId);
    }
    return postLogoutUrl;
}
/**
 * Redirect Url constructed using specified path.
 * @param urlPath Redirect Url path specified
 */
function constructRedirectUrl(urlPath) {
    const path = urlPath.startsWith("/") ? urlPath : `/${urlPath}`;
    const redirectUrl = `${window.location.origin}${path}`;
    return new URL(redirectUrl);
}
/**
 * Add the userId and tenantId query parameters which also reloads the page.
 * This is called after the user is successfully logged in.
 */
function reloadWithUserAndTenantQueryParamsIfSpecified() {
    const account = MsalHelper.instance.getActiveAccount();
    const { userId, tenantId, accountEnvironment } = account ?? {};
    const { environment } = AppContext.appConfiguration.sessionMetaData;
    if (!account || !userId || !tenantId) {
        return;
    }
    // Create a new URLSearchParams object from the current URL's query string
    const url = new URL(window.location.href);
    // Add or update userId and tenantId in the query parameters
    url.searchParams.set(WebQueryParams.UserId, userId);
    url.searchParams.set(WebQueryParams.TenantId, tenantId);
    // Setup to enable only on PROD or GCC and PPE for test
    const enableGccSubRoute = environment === Environment.Ppe || environment === Environment.Gcc || environment === Environment.Prod;
    if (enableGccSubRoute) {
        // Standard path is loading /webui path, when user is in GCC we need to append /gcc to the url during bootstrap only
        // If the user is in GCC, append GCC to the url for authentication routing purposes on PROD
        if (accountEnvironment === Environment.Gcc) {
            // Append GCC to the url for authentication routing purposes on PROD if not already there
            addGccPathToURL(url);
        }
        else {
            // Any non-gcc account we expect it for PROD - Ensure we clear /gcc from the url
            removeGccPathFromURL(url);
        }
    }
    window.location.href = url.toString();
}
/**
 * Show the landing page.
 * @param tenant The tenantId
 */
function showLandingPage(loggers, tenant) {
    const tenantId = tenant ?? "";
    // Reset any subRoutePaths that are used only for authentication routing purposes
    // For instance, /gcc subpath is a url planner wepb path that is used only for routing to MTRS retail service on PROD
    removeGccPathFromBrowserUrl();
    const configProvider = () => AppContext.appConfiguration;
    ReactDOM.render(React.createElement(LandingPage, { onClick: async () => {
            await forceLogin(loggers, tenantId);
            reloadWithUserAndTenantQueryParamsIfSpecified();
        }, loggers: loggers, enableExternalUrlRegexCheck: configProvider().flights.EnableExternalUrlRegexCheck }), document.getElementById(AppRootElementId));
}
