// Constants
import { ClientFlavor, ClientType, MsaTenantId } from "@ms/uno-constants/lib/local/AppConstants";
import { EngagementTelemetryConstants } from "@ms/uno-constants/lib/local/EngagementTelemetryConstants";
import { SubRouteType } from "@ms/uno-constants/lib/local/RoutingConstants";
import { AppContext } from "@ms/uno-constants/lib/local/configuration/AppContext";
import { SettingsObjectName } from "@ms/uno-constants/lib/local/configuration/ISettings";
import { PlannerAsyncStoragePrefix, PlannerAsyncStorageSchemaVersionV1, PlannerAsyncStorageSchemaVersionV2, } from "@ms/uno-constants/lib/local/PersistenceConstants";
// Flux
import { AsyncDispatcher } from "@ms/uno-fluxcore/lib/local/dispatcher";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
// Host Adaptor
import { FailedReason } from "@ms/uno-hostadaptors/lib/local/IUnoHostAdaptor";
// Models
import { AssetLoadingError } from "@ms/uno-errors/lib/local/errors/AssetLoadingError";
import { PersistedObjectStoreSchemaV1, PersistedObjectStoreSchemaV2, } from "@ms/uno-models/lib/local/client/persistence/PersistedAsyncStorageSchema";
// Services
import { AuthenticatedAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/AuthenticatedAjaxClient";
import { BasicAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/BasicAjaxClient";
import { GatedGetAjaxClient } from "@ms/uno-services/lib/local/ajaxClients/GatedGetAjaxClient";
import { RequestPrioritizer } from "@ms/uno-services/lib/local/priorityRequestQueue/RequestPrioritizer";
import { Log } from "@ms/uno-telemetry/lib/local/events/Action.event";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { setIsOnlineStatusForLogs } from "@ms/uno-telemetry/lib/local/utilities/LogUtilities";
// Utilities
import { initializeCultureData } from "@ms/uno-shell/lib/local/utilities/CultureUtilities";
import { HtmlSettingsReader } from "@ms/uno-utilities/lib/local/HtmlSettingsReader";
import { IndexedDBStorage } from "@ms/uno-utilities/lib/local/storage/IndexedDBStorage";
import { Worker } from "@ms/uno-workers/lib/local/workers/Worker";
/**
 * Sets up the AppContext
 * @throws {Error} If there is an error initializing the AppContext
 */
export function setupAppContext() {
    const reader = new HtmlSettingsReader(SettingsObjectName);
    AppContext.initializeFromSettings(AppContext.BuildContextSettingsFromSettingsGetter(reader.getSetting.bind(reader)));
}
/**
 * Gets the context from hostAdaptor and also initializes culture data
 */
export async function getContextAndInitializeCultureData(hostAdaptor) {
    let context;
    try {
        context = await hostAdaptor.getContext();
        await initializeCultureData(context.locale);
    }
    catch (e) {
        const err = new AssetLoadingError(e.name, e.message);
        hostAdaptor.notifyRenderFailure({ reason: FailedReason.Other, message: e.message });
        // TODO - 7545465, 8108486 need to implement errors and error logging
        // Task 8108486: Uno LazyLoading and asset loading error management
        throw err;
    }
    return context;
}
/**
 * Gets the TelemetryContext from the Context
 */
export function getTelemetryContext(context) {
    return {
        locale: context.locale,
        userId: context.userId,
        tenantId: context.tenantId,
        ringId: context.ringId, // Teams ring according to Teams client SDK
        isMSA: context.tenantId === MsaTenantId,
        isGuestUser: context.isGuestUser,
        platform: context.platform,
    };
}
/**
 * Gets the StoreProviders from the StoreFactory
 */
export function getStoreProviders(storeFactory) {
    return {
        load: storeFactory.load.bind(storeFactory),
        loadAll: storeFactory.loadAll.bind(storeFactory),
        aggregates: storeFactory.getAggregatesStoreProvider(),
        activity: storeFactory.getActivityStoreProvider(),
        auth: storeFactory.getAuthStoreProvider(),
        appContext: storeFactory.getAppContextStoreProvider(),
        basicBucket: storeFactory.getBasicBucketStoreProvider(),
        basicPlan: storeFactory.getBasicPlanStoreProvider(),
        basicPlanConversionStatus: storeFactory.getBasicPlanConversionStatusStoreProvider(),
        basicPermissions: storeFactory.getBasicPermissionsStoreProvider(),
        basicTask: storeFactory.getBasicTaskStoreProvider(),
        campaign: storeFactory.getCampaignStoreProvider(),
        capabilities: storeFactory.getCapabilitiesStoreProvider(),
        container: storeFactory.getContainerStoreProvider(),
        conversations: storeFactory.getConversationsStoreProvider(),
        createTaskFromMessage: storeFactory.getCreateTaskFromMessageStoreProvider(),
        deltaSync: storeFactory.getDeltaSyncStoreProvider(),
        driveItem: storeFactory.getDriveItemStoreProvider(),
        groups: storeFactory.getGroupsStoreProvider(),
        intelligence: storeFactory.getIntelligenceStoreProvider(),
        modal: storeFactory.getModalStoreProvider(),
        mru: storeFactory.getMruStoreProvider(),
        navigation: storeFactory.getNavigationStoreProvider(),
        newsBar: storeFactory.getNewsBarStoreProvider(),
        notification: storeFactory.getNotificationStoreProvider(),
        operationTracking: storeFactory.getOperationTrackingStoreProvider(),
        org: storeFactory.getOrgStoreProvider(),
        performanceCollection: storeFactory.getPerformanceCollectionStoreProvider(),
        planConversion: storeFactory.getPlanConversionStoreProvider(),
        portfolio: storeFactory.getPortfolioStoreProvider(),
        premiumPlan: storeFactory.getPremiumPlanStoreProvider(),
        premiumPlanPermissions: storeFactory.getPremiumPlanPermissionsStoreProvider(),
        projectHomeResource: storeFactory.getProjectHomeResourceStoreProvider(),
        publishing: storeFactory.getPublishingStoreProvider(),
        route: storeFactory.getRouteStoreProvider(),
        statusReport: storeFactory.getStatusReportStoreProvider(),
        suiteNav: storeFactory.getSuiteNavStoreProvider(),
        teams: storeFactory.getTeamsStoreProvider(),
        templates: storeFactory.getTemplatesStoreProvider(),
        tenant: storeFactory.getTenantStoreProvider(),
        upsell: storeFactory.getUpsellStoreProvider(),
        unoShell: storeFactory.getUnoShellStoreProvider(),
        user: storeFactory.getUserStoreProvider(),
        userMembership: storeFactory.getUserMembershipStoreProvider(),
        view: storeFactory.getViewStoreProvider(),
        recommendedPlans: storeFactory.getRecommendedPlansStoreProvider(),
        workflowCopilot: storeFactory.getWorkflowCopilotStoreProvider(),
    };
}
/**
 * Gets the AsyncDispatcher
 * @param configProvider The config provider
 * @param registry flux registry
 * @param storeFactory store providers
 * @param loggers used for logging
 */
export function getAsyncDispatcher(configProvider, registry, storeFactory, loggers) {
    return new AsyncDispatcher(registry, (storeType) => () => storeFactory.loadForDispatch(storeType), (storeType) => () => storeFactory.loadForSubscription(storeType), (event) => Log(event, loggers.logHandler), (tag, traceLevel, message) => loggers.traceLogger.logTrace(tag, traceLevel, message), configProvider().flights.EnableAsyncDispatchV2);
}
/**
 * Gets the RequestPrioritizer
 */
export function getRequestPrioritizer(storeProviders) {
    return new RequestPrioritizer((request) => {
        // Cancel request if it is not for the current view
        const currentViewId = storeProviders.load(Store.Route).getCurrentViewId();
        return currentViewId != null && request.viewIds != null && request.viewIds.indexOf(currentViewId) === -1;
    });
}
/**
 * Method to get persistent async storage instance
 * @param configProvider config provider
 * @param userId user id
 * @param loggers used for logging
 * @returns persisted async storage instance or null if data persistence is disabled or IndexedDB is not supported
 */
export function getPersistentAsyncStorage(configProvider, userId, loggers) {
    let persistentAsyncStorage = null;
    if (configProvider().flights.EnableDataPersistence && userId) {
        if (IndexedDBStorage.isIndexedDBSupported()) {
            try {
                persistentAsyncStorage = new IndexedDBStorage(userId, PlannerAsyncStoragePrefix + "_" + configProvider().settings.persistentAsyncStorageVersion, getPersistentAsyncStorageVersion(configProvider), getPersistentAsyncStorageSchema(configProvider), loggers.traceLogger);
            }
            catch (error) {
                loggers.traceLogger.logTrace(0x1e25684e /* tag_4jw7o */, TraceLevel.Error, error);
            }
        }
        else {
            loggers.traceLogger.logTrace(0x1e28338e /* tag_4kdoo */, TraceLevel.Info, "IndexedDB is not supported");
        }
    }
    return persistentAsyncStorage;
}
/**
 * Method to get the persistent async storage version
 * @param configProvider config provider
 */
export function getPersistentAsyncStorageVersion(configProvider) {
    return configProvider().settings.persistenceSettings.tasks ? PlannerAsyncStorageSchemaVersionV2 : PlannerAsyncStorageSchemaVersionV1;
}
/**
 * Method to get the persistent async storage schema
 * @param configProvider config provider
 */
export function getPersistentAsyncStorageSchema(configProvider) {
    return configProvider().settings.persistenceSettings.tasks ? PersistedObjectStoreSchemaV2 : PersistedObjectStoreSchemaV1;
}
/**
 * Gets the AuthService
 */
export function getAuthServiceProvider(factory, dispatcher, hostAdaptor, loggers, configProvider) {
    const { auth } = configProvider().settings.serviceConfigurations;
    const { globalAjaxTimeout } = configProvider().settings.retrySettings;
    const apiClient = new AuthenticatedAjaxClient(new BasicAjaxClient(globalAjaxTimeout, loggers), auth.authResourceURI, loggers, hostAdaptor.getAccessToken);
    const gatedApiClient = new GatedGetAjaxClient(apiClient);
    return factory.getAuthServiceProvider(gatedApiClient, hostAdaptor, configProvider);
}
/**
 * Gets the ServiceProviders
 */
export function getServiceProviders(serviceOrchestrator, authService) {
    return {
        augloop: serviceOrchestrator.getAugloopServiceProvider(),
        auth: () => authService,
        centro: serviceOrchestrator.getCentroServiceProvider(),
        chatSvcAgg: serviceOrchestrator.getChatSvcAggServiceProvider(),
        conversations: serviceOrchestrator.getConversationsServiceProvider(),
        driveItem: serviceOrchestrator.getDriveItemServiceProvider(),
        flow: serviceOrchestrator.getFlowServiceProvider(),
        gateway: serviceOrchestrator.getGatewayServiceProvider(),
        iris: serviceOrchestrator.getIrisServiceProvider(),
        groups: serviceOrchestrator.getGroupsServiceProvider(),
        loop: serviceOrchestrator.getLoopServiceProvider(),
        loopMts: serviceOrchestrator.getLoopMtsServiceProvider(),
        mars: serviceOrchestrator.getMarsServiceProvider(),
        mru: serviceOrchestrator.getMruServiceProvider(),
        ocps: serviceOrchestrator.getOcpsServiceProvider(),
        oneshell: serviceOrchestrator.getOneShellServiceProvider(),
        planner: serviceOrchestrator.getPlannerServiceProvider(),
        plannerGraph: serviceOrchestrator.getPlannerGraphServiceProvider(),
        plp: serviceOrchestrator.getProjectLandingPageServiceProvider(),
        pss: serviceOrchestrator.getProjectSchedulingServiceProvider(),
        publishing: serviceOrchestrator.getPublishingServiceProvider(),
        tcms: serviceOrchestrator.getTcmsServiceProvider(),
        teams: serviceOrchestrator.getTeamsServiceProvider(),
        tenant: serviceOrchestrator.getTenantServiceProvider(),
        retail: serviceOrchestrator.getRetailServiceProvider(),
        roaming: serviceOrchestrator.getRoamingServiceProvider(),
        siteURL: serviceOrchestrator.getSiteURLServiceProvider(),
        todo: serviceOrchestrator.getTodoServiceProvider(),
        user: serviceOrchestrator.getUserServiceProvider(),
    };
}
/**
 * Gets the LogicModuleProviders
 */
export function getLogicModuleProviders(lmFactory, dispatcher, serviceProviders, storeProviders, configProvider, hostAdaptor, authService, authStore, fetchAccessToken) {
    return {
        adaptiveCard: lmFactory.getAdaptiveCardModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        appContext: lmFactory.getAppContextModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        auth: lmFactory.getAuthModuleProvider(dispatcher, authService, authStore, configProvider),
        basicBucket: lmFactory.getBasicBucketModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicPlan: lmFactory.getBasicPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        basicTask: lmFactory.getBasicTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        campaign: lmFactory.getCampaignModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        capabilities: lmFactory.getCapabilitiesModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        centro: lmFactory.getCentroModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        chatSvcAgg: lmFactory.getChatSvcAggModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        conversations: lmFactory.getConversationsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        deltaSync: lmFactory.getDeltaSyncModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        excelExport: lmFactory.getExcelExportModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        driveItem: lmFactory.getDriveItemModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        groups: lmFactory.getGroupsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        intelligence: lmFactory.getIntelligenceModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        links: lmFactory.getLinksModuleProvider(dispatcher, serviceProviders, storeProviders, hostAdaptor, configProvider),
        loop: lmFactory.getLoopModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        mru: lmFactory.getMruModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        notification: lmFactory.getNotificationModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        org: lmFactory.getOrgModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        performanceCollection: lmFactory.getPerformanceCollectionModuleProvider(dispatcher),
        planai: lmFactory.getPlanAiModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        planConversion: lmFactory.getPlanConversionModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        portfolio: lmFactory.getPortfolioModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumPlan: lmFactory.getPremiumPlanModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        premiumTask: lmFactory.getPremiumTaskModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        publishing: lmFactory.getPublishingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider, fetchAccessToken),
        roaming: lmFactory.getRoamingModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        roster: lmFactory.getRosterModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        suiteNav: lmFactory.getSuiteNavModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        teams: lmFactory.getTeamsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        templates: lmFactory.getTemplateModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        tenant: lmFactory.getTenantModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        todo: lmFactory.getTodoModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider, fetchAccessToken),
        ttTabs: lmFactory.getTeamsTasksTabsModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        upsell: lmFactory.getUpsellModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
        user: lmFactory.getUserModuleProvider(dispatcher, serviceProviders, storeProviders, configProvider),
    };
}
/**
 * Gets the ActionCreatorProviders
 */
export function getActionCreatorProviders(acFactory) {
    return {
        load: acFactory.load.bind(acFactory),
        activity: acFactory.getActivityAcProvider(),
        appContext: acFactory.getAppContextAcProvider(),
        basicBucket: acFactory.getBasicBucketAcProvider(),
        basicPlan: acFactory.getBasicPlanAcProvider(),
        basicTask: acFactory.getPlannerTaskAcProvider(),
        campaign: acFactory.getCampaignAcProvider(),
        capabilities: acFactory.getCapabilitiesAcProvider(),
        container: acFactory.getContainerAcProvider(),
        createTaskFromTeamsMessage: acFactory.getCreateTaskFromTeamsMessageAcProvider(),
        dataPreFetch: acFactory.getDataPreFetchAcProvider(),
        deltaSync: acFactory.getDeltaSyncAcProvider(),
        driveItem: acFactory.getDriveItemAcProvider(),
        export: acFactory.getExportAcProvider(),
        groups: acFactory.getGroupsAcProvider(),
        intelligence: acFactory.getIntelligenceAcProvider(),
        links: acFactory.getLinksAcProvider(),
        modal: acFactory.getModalAcProvider(),
        mru: acFactory.getMruAcProvider(),
        newsBar: acFactory.getNewsBarAcProvider(),
        notification: acFactory.getNotificationAcProvider(),
        operationTracking: acFactory.getOperationTrackingAcProvider(),
        org: acFactory.getOrgAcProvider(),
        performanceCollection: acFactory.getPerformanceCollectionAcProvider(),
        planAi: acFactory.getPlanAiAcProvider(),
        portfolio: acFactory.getPortfolioAcProvider(),
        publishing: acFactory.getPublishingAcProvider(),
        planConversion: acFactory.getPlanConversionAcProvider(),
        premiumPlan: acFactory.getPremiumPlanAcProvider(),
        routing: acFactory.getRoutingAcProvider(),
        suiteNavView: acFactory.getSuiteNavViewAcProvider(),
        teams: acFactory.getTeamsAcProvider(),
        teamsTab: acFactory.getTeamsTabAcProvider(),
        templates: acFactory.getTemplatesAcProvider(),
        tenant: acFactory.getTenantAcProvider(),
        unoShell: acFactory.getUnoShellAcProvider(),
        upsell: acFactory.getUpsellAcProvider(),
        user: acFactory.getUserAcProvider(),
        ocv: acFactory.getOCVAcProvider(),
        recommendedPlans: acFactory.getRecommendedPlansAcProvider(),
        projectHomeResource: acFactory.getResourceAcProvider(),
        // View AC providers
        basicPlanView: acFactory.getBasicPlanViewAcProvider(),
        createPlanView: acFactory.getCreatePlanViewAcProvider(),
        copyPlanView: acFactory.getCopyPlanViewAcProvider(),
        createPortfolioView: acFactory.getCreatePortfolioViewAcProvider(),
        createTaskView: acFactory.getCreateTaskViewAcProvider(),
        leftNavView: acFactory.getLeftNavViewAcProvider(),
        myDayView: acFactory.getMyDayViewAcProvider(),
        myPlansView: acFactory.getMyPlansViewAcProvider(),
        myPortfoliosView: acFactory.getMyPortfoliosViewAcProvider(),
        myTasksView: acFactory.getMyTasksViewAcProvider(),
        unoShellView: acFactory.getUnoShellViewAcProvider(),
        convertPlanView: acFactory.getConvertPlanViewAcProvider(),
        createTeamsTabView: acFactory.getCreateTeamsTabViewAcProvider(),
        sharePlanView: acFactory.getSharePlanViewAcProvider(),
        publishingLeftNavView: acFactory.getPublishingLeftNavViewAcProvider(),
        campaignCalloutView: acFactory.getCampaignCalloutViewAcProvider(),
        viewTransforms: acFactory.getViewTransformsAcProvider(),
    };
}
/**
 * Gets the agent providers from the agent factory
 */
export function getAgentProviders(agentFactory) {
    const agentProviders = {
        projectManager: agentFactory.getProjectManagerAgentProvider(),
    };
    return agentProviders;
}
/**
 * Load chunks and initialize deferrable workers
 */
export function loadAndInitializeDeferrableWorkers(workerLoader, loggers, persistentAsyncStorage, configProvider) {
    try {
        const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
        if (clientFlavor !== ClientFlavor.TaskFromMessage) {
            workerLoader.load(Worker.DeltaSyncFeedProcessor).init();
            workerLoader.load(Worker.Mru).init();
            workerLoader.load(Worker.Modal).init();
            workerLoader.load(Worker.Navigation).init();
            workerLoader.load(Worker.PremiumPlanCreationStatus).init();
            workerLoader.load(Worker.ProjectTrialOperationTracker).init();
            workerLoader.load(Worker.AugloopTileSync).init();
            workerLoader.load(Worker.OperationTracking).init();
            workerLoader.load(Worker.RecommendedPlanUpdate).init();
            workerLoader.load(Worker.BasicPlanContainerFetcher).init();
        }
        if (configProvider().flights.EnableDataPersistence) {
            if (persistentAsyncStorage !== null) {
                workerLoader.load(Worker.CacheUpdate).init();
                if (configProvider().flights.EnableCacheEvictionWorker) {
                    workerLoader.load(Worker.CacheEviction).init();
                }
            }
            else {
                loggers.traceLogger.logTrace(0x1e25b68f /* tag_4j10p */, TraceLevel.Info, "Persistent Async Storage is not available. Skipping CacheUpdate worker initialization.");
            }
        }
        //Initialize the performance observer worker
        if (configProvider().flights.EnableModernPerformanceMetrics) {
            workerLoader.load(Worker.PerformanceObserver).init();
        }
        if (configProvider().flights.EnableGsdInBasicPlans || configProvider().flights.EnableGsdInMeetingPlans) {
            workerLoader.load(Worker.WorkflowCopilot).init();
        }
        if (configProvider().flights.EnableUnoLevelProjectProvisioning) {
            workerLoader.load(Worker.CdsProvisioningStatus).init();
        }
        const shouldInitializeStatusTrackerWorker = configProvider().flights.EnableUpsellConversion &&
            (configProvider().sessionMetaData.appMetadata.clientType === ClientType.Teams
                ? clientFlavor !== ClientFlavor.TaskFromMessage
                : true);
        if (shouldInitializeStatusTrackerWorker) {
            if (configProvider().flights.EnableBasicPlanParallelConversion) {
                workerLoader.load(Worker.PlanConversionStatusTracker).init();
            }
            else {
                workerLoader.load(Worker.BasicPlanConversionTracker).init();
            }
        }
    }
    catch (e) {
        loggers.traceLogger.logTrace(0x1e25b68e /* tag_4j10o */, TraceLevel.Warning, `Failed to load and initialize deferrable workers. [ErrorType=${e.name}][Error=${e.message}]`);
    }
}
/**
 * Load chunks and initialize critical workers
 */
export function loadAndInitializeWorkers(workerLoader, requestPrioritizer, loggers, persistentAsyncStorage, configProvider, hostAdaptor) {
    try {
        // 1. Initialize the static workers
        workerLoader.load(Worker.DataFetch).init(); // critical because we want data fetch to be as fast as possible
        const bootObserverWorker = workerLoader.load(Worker.BootObserver); // critical because we want to make sure we are able to detect exactly when boot is complete
        bootObserverWorker.init();
        if (configProvider().flights.EnableInstantiatePremiumPlanFromWorker) {
            workerLoader.load(Worker.ChildAppInstantiationWorker).init(); // critical to ensure that child app is booted as quickly as possible
        }
        // 2. Initialize the workers that are not static but critical be loaded before critical boot
        const networkConnectivityWorker = workerLoader.load(Worker.NetworkConnectivity);
        networkConnectivityWorker.init(); // critical because we want to show some skeleton UI in the absence of a network connection
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(requestPrioritizer.onNetworkConnectivityChanged.bind(requestPrioritizer));
        networkConnectivityWorker.addNetworkConnectivityChangeCallback(setIsOnlineStatusForLogs);
        if (configProvider().flights.EnableDataPersistence) {
            if (persistentAsyncStorage !== null) {
                workerLoader.load(Worker.StoreHydration).init(); // critical because we want to load the cached data into the stores
            }
            else {
                loggers.traceLogger.logTrace(0x1e25b68d /* tag_4j10n */, TraceLevel.Info, "Persistent Async Storage is not available. Skipping StoreHydration worker initialization.");
            }
        }
        if (configProvider().sessionMetaData.appMetadata.clientFlavor !== ClientFlavor.TaskFromMessage) {
            workerLoader.load(Worker.DeltaSync).init(); // critical because delta sync must happen before any data calls are made to ensure correctness
        }
        if (!configProvider().flights.EnableWorkerDeferredInit) {
            loadAndInitializeDeferrableWorkers(workerLoader, loggers, persistentAsyncStorage, configProvider);
        }
        // 3. Actions to be taken after critical boot is complete
        bootObserverWorker.onBootCompletion(() => {
            // Notify render success on boot completion
            if (configProvider().sessionMetaData.appMetadata.clientType === ClientType.Web ||
                (configProvider().sessionMetaData.appMetadata.clientType === ClientType.Teams &&
                    configProvider().flights.EnableTeamsNotifySuccessV2)) {
                hostAdaptor.notifyRenderSuccess();
            }
            // Load and initialize the workers that are not critical
            if (configProvider().flights.EnableWorkerDeferredInit) {
                loadAndInitializeDeferrableWorkers(workerLoader, loggers, persistentAsyncStorage, configProvider);
            }
        });
    }
    catch (e) {
        loggers.traceLogger.logTrace(0x1e25b68c /* tag_4j10m */, TraceLevel.Warning, `Failed to load and initialize pre-boot workers. [ErrorType=${e.name}][Error=${e.message}]`);
    }
}
/**
 * Log initial plan view sub route type info for experimentation
 * @param componentName The component name
 * @param subRouteType The current sub route type
 * @param loggers The loggers
 */
export function logPlanViewSubRouteType(componentName, subRouteType, loggers) {
    // If initial route is for a plan view, track in engagement telemetry
    if (subRouteType === SubRouteType.BasicPlan || subRouteType === SubRouteType.PremiumPlan || subRouteType === SubRouteType.TodoList) {
        loggers.engagementLogger.logEngagement(componentName, EngagementTelemetryConstants.OpenPlanFromPersistedRoute, {
            subRouteType: subRouteType,
        });
    }
}
