// Constants
import { DeltaApiConstants } from "@ms/uno-constants/lib/local/DeltaApiConstants";
// Model
import { TaskServiceEntityType } from "../ITaskServiceEntity";
import { PriorityName, TaskKind } from "./ITask";
import { ProgressState } from "../progressState/ProgressState";
import { PreviewType, PreviewTypeConverter } from "../previewType/PreviewType";
import { Assignment } from "./Assignment";
import { TaskCreationSource } from "./creationSource/TaskCreationSource";
import { Task } from "./Task";
import { RecurrenceOccurrence } from "./recurrence/RecurrenceOccurrence";
import { SpecifiedCompletionRequirements } from "../../../service/graph-legacy/task/completionRequirements/SpecifiedCompletionRequirements";
import { TaskInternalProperties } from "./extensionFields/TaskInternalProperties";
// Utilities
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { DeltaTranslationMap } from "../deltaSync/DeltaTranslationMap";
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import { getSpecifiedCompletionRequirementsFromGraph } from "../utilities/CompletionRequirementsUtils";
import { calculateDueDateFromSchedule } from "./recurrence/utilities/RecurrenceCalculatorUtilities";
import { NormalizeDate } from "@ms/uno-utilities/lib/local/DateUtilities";
import { ConvertProgressStateToPercentComplete, ConvertPriorityNameToPriority } from "../utilities/ConversionUtilities";
/**
 * Configuration and building of task objects
 */ export class TaskBuilder {
    get recurrence() {
        return this._recurrence;
    }
    get dueDate() {
        return this._dueDate;
    }
    get startDate() {
        return this._startDate;
    }
    /**
     * Build out the task
     */ build() {
        return new Task(this);
    }
    /**
     * Build from a graph task resource entity as initialization data.
     * @param taskResource Task resource entity to use for initializing the task builder
     */ fromGraphResource(taskResource) {
        const assignments = Assignment.getClientDictionaryTypeFromGraph(taskResource.assignments || {});
        const appliedCategories = this.getAppliedCategoriesFromGraph(taskResource.appliedCategories || {});
        const specifiedCompletionRequirements = getSpecifiedCompletionRequirementsFromGraph(taskResource.specifiedCompletionRequirements);
        return this.withPropertyBag({
            appliedCategories: appliedCategories,
            assignments: assignments,
            id: taskResource.id,
            title: taskResource.title,
            planId: taskResource.planId,
            assigneePriority: taskResource.assigneePriority,
            bucketId: taskResource.bucketId,
            completedBy: taskResource.completedBy != null ? taskResource.completedBy.user != null ? taskResource.completedBy.user.id : undefined : undefined,
            completedDate: taskResource.completedDateTime != null ? moment(taskResource.completedDateTime) : undefined,
            conversationThreadId: taskResource.conversationThreadId,
            createdBy: taskResource.createdBy != null ? taskResource.createdBy.user != null ? taskResource.createdBy.user.id : undefined : undefined,
            createdDate: taskResource.createdDateTime != null ? moment(taskResource.createdDateTime) : undefined,
            creationSource: taskResource.creationSource != null ? TaskCreationSource.fromGraphResource(taskResource.creationSource) : undefined,
            deletedBy: taskResource.deletedBy != null ? taskResource.deletedBy.user != null ? taskResource.deletedBy.user.id : undefined : undefined,
            dueDate: taskResource.dueDateTime != null ? moment(taskResource.dueDateTime) : undefined,
            hasDescription: taskResource.hasDescription,
            isOnMyDay: taskResource.isOnMyDay,
            numberOfActiveChecklistItems: taskResource.activeChecklistItemCount,
            numberOfReferences: taskResource.referenceCount,
            numberOfChecklistItems: taskResource.checklistItemCount,
            orderHint: taskResource.orderHint,
            previewType: PreviewTypeConverter.getPreviewTypeFromGraph(taskResource.previewType != null ? taskResource.previewType : PreviewTypeConverter.getGraphPreviewType(this.previewType)),
            priority: taskResource.priority,
            recurrence: taskResource.recurrence != null ? RecurrenceOccurrence.builder.fromGraphResource(taskResource.recurrence).build() : undefined,
            startDate: taskResource.startDateTime != null ? moment(taskResource.startDateTime) : undefined,
            percentComplete: taskResource.percentComplete,
            itemVersion: taskResource["@odata.etag"],
            specifiedCompletionRequirements,
            internalProperties: taskResource.internalProperties ? TaskInternalProperties.fromResource(taskResource.internalProperties) : undefined
        });
    }
    /**
     * Build from provided property bag
     * @param propertyBag Set of properties to use for initialization
     */ withPropertyBag(propertyBag) {
        const appliedCategories = propertyBag.appliedCategories || this.appliedCategories;
        this.kind = propertyBag.kind != null ? propertyBag.kind : this.kind;
        this.appliedCategories = appliedCategories.slice();
        this.assignments = propertyBag.assignments || this.assignments;
        this.assigneePriority = propertyBag.assigneePriority || this.assigneePriority;
        this.bucketId = propertyBag.bucketId || this.bucketId;
        this.completedBy = propertyBag.completedBy || this.completedBy;
        this.completedDate = propertyBag.completedDate || this.completedDate;
        this.conversationThreadId = propertyBag.conversationThreadId || this.conversationThreadId;
        this.createdBy = propertyBag.createdBy || this.createdBy;
        this.createdDate = propertyBag.createdDate || this.createdDate;
        this.creationSource = propertyBag.creationSource || this.creationSource;
        this.deletedBy = propertyBag.deletedBy || this.deletedBy;
        this._dueDate = propertyBag.dueDate !== undefined ? propertyBag.dueDate : this.dueDate;
        this.hasDescription = propertyBag.hasDescription || this.hasDescription;
        this.isOnMyDay = propertyBag.isOnMyDay || this.isOnMyDay;
        this.id = propertyBag.id;
        this.itemVersion = propertyBag.itemVersion || this.itemVersion;
        this.userContentLastModifiedBy = propertyBag.userContentLastModifiedBy || this.userContentLastModifiedBy;
        this.userContentLastModifiedDate = propertyBag.userContentLastModifiedDate || this.userContentLastModifiedDate;
        this.numberOfActiveChecklistItems = propertyBag.numberOfActiveChecklistItems != null ? propertyBag.numberOfActiveChecklistItems : this.numberOfActiveChecklistItems;
        this.numberOfChecklistItems = propertyBag.numberOfChecklistItems != null ? propertyBag.numberOfChecklistItems : this.numberOfChecklistItems;
        this.numberOfReferences = propertyBag.numberOfReferences != null ? propertyBag.numberOfReferences : this.numberOfReferences;
        this.orderHint = propertyBag.orderHint || this.orderHint;
        this.planId = propertyBag.planId || this.planId;
        this.percentComplete = propertyBag.percentComplete != null ? propertyBag.percentComplete : this.percentComplete;
        this.previewType = propertyBag.previewType != null ? propertyBag.previewType : this.previewType;
        this.priority = propertyBag.priority || this.priority;
        this._startDate = propertyBag.startDate || this.startDate;
        this._recurrence = propertyBag.recurrence ?? this.recurrence;
        this.title = propertyBag.title || this.title;
        this.specifiedCompletionRequirements = propertyBag.specifiedCompletionRequirements || this.specifiedCompletionRequirements;
        this.internalProperties = propertyBag.internalProperties || this.internalProperties;
        return this;
    }
    /**
     * Set the title for the task
     * @param title Value to set for the title
     */ withTitle(title) {
        this.title = title;
        return this;
    }
    /**
     * Set the planId for the task
     * @param planId Value to set for the planId
     */ withPlanId(planId) {
        this.planId = planId;
        return this;
    }
    /**
     * Add a locally unique id to the task
     */ withLocallyUniqueId() {
        this.id = LocalIdGenerator.generate();
        return this;
    }
    /**
     * Add an assigment to the task
     * @param userId Id of the user to whom the task is to be assigned
     * @param assignment Assignment object
     */ withAssignment(userId, assignment) {
        if (this.assignments == null) {
            this.assignments = {};
        }
        this.assignments[userId] = assignment;
        return this;
    }
    /**
     * Set the isOnMyDay field on the task to true
     * @param isOnMyDay boolean Value to set for the isOnMyDay field
     */ withIsOnMyDay(isOnMyDay) {
        this.isOnMyDay = isOnMyDay;
        return this;
    }
    /**
     * Set the completedBy field on the task
     * @param userId Id of the user to set the field with
     */ withCompletedBy(userId) {
        this.completedBy = userId;
        return this;
    }
    /**
     * Set the percentComplete field on the task
     * @param percentComplete Value of percentComplete between 0 and 100 (inclusive)
     */ withPercentComplete(percentComplete) {
        this.percentComplete = percentComplete;
        return this;
    }
    /**
     * Add a preview type to the task
     * @param previewType PreviewType we want to use
     * @param [numberOfReferences] Optional parameter to set the number of references
     */ withPreviewType(previewType, numberOfReferences) {
        this.previewType = previewType;
        if (numberOfReferences != null) {
            this.numberOfReferences = numberOfReferences;
        }
        return this;
    }
    /**
     * Add specified completion requirements to the task
     * @param requirements PreviewType we want to use
     */ withSpecifiedCompletionRequirements(requirements) {
        this.specifiedCompletionRequirements = requirements;
        return this;
    }
    /**
     * Add a priority value to the task
     * @param priority Priority we want to use
     */ withPriority(priority) {
        this.priority = ConvertPriorityNameToPriority(priority);
        return this;
    }
    /**
     * Add a specific id to the task
     * @param id Id to assign to the task
     */ withId(id) {
        this.id = id;
        return this;
    }
    /**
     * Add a specific bucket id to the task
     * @param bucketId Bucket id to assign to the task
     */ withBucketId(bucketId) {
        this.bucketId = bucketId;
        return this;
    }
    /**
     * Add a creation source to the task
     * @param source Creation source to use
     */ withCreationSource(source) {
        this.creationSource = source;
        return this;
    }
    /**
     * Sets the start date. Date should already be set ToUTC10am.
     * @remarks If start date comes after the due date, the due date will be adjusted to the same value.
     * @remarks Changing the due date of a recurring task will also update the recurrence.
     * @param startDate Start date to use. Date should already be set ToUTC10am.
     */ withStartDate(startDate) {
        this._startDate = NormalizeDate(startDate);
        if (this.startDate && this.dueDate && this.startDate > this.dueDate) {
            this.withDueDate(this.startDate);
        }
        return this;
    }
    /**
     * Sets the due date. Date should already be set ToUTC10am.
     * @remarks If the due date comes before the start date, the start date will be adjusted to the same value.
     * @remarks Changing the due date of a recurring task will also update the recurrence.
     * @param dueDate Due date to use. Date should already be set ToUTC10am.
     */ withDueDate(dueDate) {
        // TODO: PBI #8762419: [Uno]: TaskBuilder - Move NormalizeDate from builder to Planner Service
        this._dueDate = NormalizeDate(dueDate);
        if (this.dueDate && this.startDate && this.dueDate < this.startDate) {
            this._startDate = this.dueDate.clone();
        }
        if (this.recurrence?.isActive()) {
            if (dueDate == null) {
                // Remove recurrence.
                this._recurrence = this.recurrence.setRecurrenceSchedule(null);
            } else if (dueDate >= this.recurrence.nextOccurrenceDate) {
                // Update range.startDate to match dueDate if the dueDate is on or after the next occurrence
                this._recurrence = this.recurrence.setRecurrenceRangeStartDate(dueDate);
            }
        }
        return this;
    }
    /**
     * Sets the recurrence.
     * @remarks Changing the recurrence will also update the due date and start date if needed.
     * @param recurrence Recurrence to use
     */ withRecurrence(recurrence) {
        this._recurrence = recurrence;
        // When recurrence changes we need to adjust the dueDate
        this._dueDate = calculateDueDateFromSchedule(recurrence.schedule, this.dueDate ?? this.startDate ?? moment());
        return this;
    }
    /**
     * Sets the recurrence scehdule. To remove a recurrence send null.
     * @remarks Changing the recurrence will also update the due date and start date if needed.
     * @param schedule Schedule to use
     */ withRecurrenceSchedule(schedule) {
        const newRangeStartDate = calculateDueDateFromSchedule(schedule, this.dueDate ?? this.startDate ?? moment());
        if (this.recurrence != null) {
            // UpdateSchedule
            // Recreation
            // Termination (schedule == null)
            this.withRecurrence(this.recurrence.setRecurrenceSchedule(schedule?.setRecurrenceRangeStartDate(newRangeStartDate) ?? null));
        } else if (schedule != null) {
            // Creation
            this.withRecurrence(RecurrenceOccurrence.builder.withRecurrenceSchedule(schedule.setRecurrenceRangeStartDate(newRangeStartDate)).build());
        }
        return this;
    }
    /** Test method to specify the kind */ withKind(taskKind) {
        this.kind = taskKind;
        return this;
    }
    /**
     * Create the task from diff sync
     * @param update Changes from diff sync to apply
     */ newFromDifferentialUpdate(update) {
        if (update.entityType !== TaskServiceEntityType.Task) {
            throw new Error("ArgumentException: invalid entity");
        }
        const baseline = Task.builder().withPropertyBag({
            id: update.id,
            itemVersion: update.itemVersion,
            planId: "",
            title: ""
        }).build();
        const diffData = update.getCreateDiffData();
        const task = baseline.applyDiffs(diffData);
        return this.forClone(task);
    }
    /**
     * Applies given instance data to have a full clone builder
     */ forClone(task) {
        return this.withPropertyBag({
            kind: task.kind,
            appliedCategories: task.appliedCategories.slice(),
            assignments: cloneDeep(task.assignments),
            id: task.id,
            title: task.title,
            planId: task.planId,
            assigneePriority: task.assigneePriority,
            bucketId: task.bucketId,
            completedBy: task.completedBy || undefined,
            completedDate: task.completedDate != null ? task.completedDate.clone() : undefined,
            conversationThreadId: task.conversationThreadId,
            createdBy: task.createdBy,
            createdDate: task.createdDate != null ? task.createdDate.clone() : undefined,
            creationSource: cloneDeep(task.creationSource),
            deletedBy: task.deletedBy || undefined,
            dueDate: task.dueDate != null ? task.dueDate.clone() : undefined,
            hasDescription: task.hasDescription,
            isOnMyDay: task.isOnMyDay,
            userContentLastModifiedBy: task.userContentLastModifiedBy,
            userContentLastModifiedDate: task.userContentLastModifiedDate != null ? task.userContentLastModifiedDate.clone() : undefined,
            numberOfActiveChecklistItems: task.numberOfActiveChecklistItems,
            numberOfReferences: task.numberOfReferences,
            numberOfChecklistItems: task.numberOfChecklistItems,
            orderHint: task.orderHint,
            previewType: cloneDeep(task.previewType),
            priority: task.priority,
            recurrence: task.recurrence != null ? cloneDeep(task.recurrence) : undefined,
            startDate: task.startDate != null ? task.startDate.clone() : undefined,
            percentComplete: task.percentComplete,
            itemVersion: task.itemVersion,
            specifiedCompletionRequirements: cloneDeep(task.specifiedCompletionRequirements),
            internalProperties: cloneDeep(task.internalProperties)
        });
    }
    /**
     * Computes the diff object from a given differential update from Graph API diff sync and generates diff data that can be applied to Client OM entity
     * @param update The income differential update
     * @param [storeCopy] Optional parameter with a store copy of the entity used when computing the diff object based for an update
     */ getDiffDataFromGraphResource(update, storeCopy) {
        const translateDateString = (dateValue)=>dateValue != null ? moment(dateValue) : null;
        const translateReqDateString = (dateValue)=>dateValue != null ? moment(dateValue) : moment();
        const translateDate = (dateValue)=>dateValue != null ? moment(dateValue) : null;
        const translateGraphUser = (graphUser)=>graphUser?.user?.id;
        const translateReqGraphUser = (graphUser)=>graphUser?.user?.id ?? "";
        const translateAssignmentUpdates = (graphUpdates)=>{
            if (graphUpdates == null) {
                // Reset collection
                return DeltaApiConstants.CollectionCleanupValue;
            }
            const assignments = storeCopy ? cloneDeep(storeCopy.assignments) : {};
            for(const key in graphUpdates){
                if (graphUpdates[key] == null) {
                    // Remove Item
                    delete assignments[key];
                } else if (key in assignments) {
                    // Update Item values - may include partial properties
                    if ("orderHint" in graphUpdates[key]) {
                        assignments[key] = assignments[key].setProperty("order", graphUpdates[key].orderHint);
                    }
                    if ("assignedBy" in graphUpdates[key]) {
                        assignments[key] = assignments[key].setProperty("assignedBy", translateReqGraphUser(graphUpdates[key].assignedBy));
                    }
                    if ("assignedDateTime" in graphUpdates[key]) {
                        assignments[key] = assignments[key].setProperty("assignedDate", translateDate(graphUpdates[key].assignedDateTime) ?? moment());
                    }
                } else {
                    // Insert item
                    assignments[key] = Assignment.getAssignmentFromGraph(graphUpdates[key]);
                }
            }
            return assignments;
        };
        const translateCategoryUpdates = (graphCategoryUpdates)=>{
            if (graphCategoryUpdates == null) {
                // Reset list
                return [];
            }
            // Prepare store copy for graph updates
            const categories = storeCopy?.appliedCategories ? storeCopy.appliedCategories : [];
            const graphCategories = {};
            forEach(categories, (value)=>{
                graphCategories[`category${value + 1}`] = true;
            });
            // Apply graph changes
            for(const key in graphCategoryUpdates){
                graphCategories[key] = graphCategoryUpdates[key];
            }
            return this.getAppliedCategoriesFromGraph(graphCategories);
        };
        const graphTranslationMap = new DeltaTranslationMap();
        graphTranslationMap.addMapping("appliedCategories", "appliedCategories", translateCategoryUpdates);
        graphTranslationMap.addMapping("assigneePriority", "assigneePriority");
        graphTranslationMap.addMapping("assignments", "assignments", translateAssignmentUpdates);
        graphTranslationMap.addMapping("bucketId", "bucketId");
        graphTranslationMap.addMapping("completedBy", "completedBy", translateGraphUser);
        graphTranslationMap.addMapping("completedDateTime", "completedDate", translateDateString);
        graphTranslationMap.addMapping("conversationThreadId", "conversationThreadId");
        graphTranslationMap.addMapping("createdBy", "createdBy", translateReqGraphUser);
        graphTranslationMap.addMapping("createdDateTime", "createdDate", translateReqDateString);
        graphTranslationMap.addMapping("deletedBy", "deletedBy", translateGraphUser);
        graphTranslationMap.addMapping("dueDateTime", "dueDate", translateDateString);
        graphTranslationMap.addMapping("hasDescription", "hasDescription");
        graphTranslationMap.addMapping("isOnMyDay", "isOnMyDay");
        graphTranslationMap.addMapping("activeChecklistItemCount", "numberOfActiveChecklistItems");
        graphTranslationMap.addMapping("checklistItemCount", "numberOfChecklistItems");
        graphTranslationMap.addMapping("referenceCount", "numberOfReferences");
        graphTranslationMap.addMapping("orderHint", "orderHint");
        graphTranslationMap.addMapping("percentComplete", "percentComplete");
        graphTranslationMap.addMapping("planId", "planId");
        graphTranslationMap.addMapping("previewType", "previewType", (value)=>PreviewTypeConverter.getPreviewTypeFromGraph(value));
        graphTranslationMap.addMapping("priority", "priority");
        graphTranslationMap.addMapping("startDateTime", "startDate", translateDateString);
        graphTranslationMap.addMapping("title", "title");
        graphTranslationMap.addMapping("specifiedCompletionRequirements", "specifiedCompletionRequirements");
        const translatedTask = graphTranslationMap.translate(update);
        if ("recurrence" in update) {
            const updatedRecurrence = RecurrenceOccurrence.builder.getDiffDataFromGraphResource(update);
            if (!isEmpty(updatedRecurrence)) {
                translatedTask["recurrence"] = updatedRecurrence;
            }
        }
        return translatedTask;
    }
    /**
     * Map the applied categories from graph ("category1": true, "category2": false, etc)
     * to a list of applied category indexes
     * @param graphCategories Categories returned from graph
     */ getAppliedCategoriesFromGraph(graphCategories) {
        if (isEmpty(graphCategories)) {
            return [];
        }
        const appliedCategories = [];
        for(const key in graphCategories){
            if (key.indexOf("category") === 0) {
                if (graphCategories[key]) {
                    const categoryIndex = parseInt(key.replace("category", ""), 10) - 1;
                    appliedCategories.push(categoryIndex);
                }
            }
        }
        return appliedCategories;
    }
    /**
     * Initialize an instance of the task builder
     */ constructor(){
        this.id = LocalIdGenerator.generate();
        this.planId = LocalIdGenerator.generate();
        this.title = "";
        this.appliedCategories = [];
        this.assignments = {};
        this.bucketId = null;
        this.createdBy = "currentUserId"; // TODO: Need to get the actual current user id
        this.createdDate = moment();
        this.creationSource = new TaskCreationSource();
        this.previewType = PreviewType.Automatic;
        this.hasDescription = false;
        this.isOnMyDay = false;
        this.numberOfActiveChecklistItems = 0;
        this.numberOfChecklistItems = 0;
        this.numberOfReferences = 0;
        this.percentComplete = ConvertProgressStateToPercentComplete(ProgressState.NotStarted);
        this.priority = ConvertPriorityNameToPriority(PriorityName.Medium);
        this.orderHint = " !";
        this.completedBy = null;
        this.conversationThreadId = null;
        this.kind = TaskKind.Shared; // Plex only creates shared tasks
        this._recurrence = null;
        this._dueDate = null;
        this._startDate = null;
        this.specifiedCompletionRequirements = SpecifiedCompletionRequirements.None;
        this.internalProperties = new TaskInternalProperties();
    }
}
