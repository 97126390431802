// Constants
import { ClientFlavor, UserPolicyFlags } from "@ms/uno-constants/lib/local/AppConstants";
// Errors
import { NotSupportedError } from "@ms/uno-errors/lib/local/errors/NotSupportedError";
import { AppContextStore } from "@ms/uno-stores/lib/local/stores/appContext/AppContextStore";
import { AuthStore } from "@ms/uno-stores/lib/local/stores/auth/AuthStore";
import { PerformanceCollectionStore } from "@ms/uno-stores/lib/local/stores/performanceCollection/PerformanceCollectionStore";
import { RouteStore } from "@ms/uno-stores/lib/local/stores/route/RouteStore";
import { UnoShellStore } from "@ms/uno-stores/lib/local/stores/unoShell/UnoShellStore";
import { UserStore } from "@ms/uno-stores/lib/local/stores/user/UserStore";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
import { isPersistedStore } from "@ms/uno-stores/lib/local/utilities/StoreUtilities";
/**
 * Factory class for store providers in Base app
 */
export class BaseStoreFactory {
    // #endregion
    /**
     * Initialize a new instance of the TeamsStoreFactory
     * @param loader The component loader to use for lazy loading stores
     * @param initialAppContext The initial context value to populate the AppContextStore
     * @param user The user data to populate the UserStore
     * @param rootPath The root path of the app
     * @param loggers The loggers for telemetry
     * @param persistentStorage The persistent storage to use for the stores
     * @param configProvider app configuration to populate the AppContextStore
     * @param isDeepLinkInit Whether the app is initialized from a deep link
     * @param defaultQueryParameters If specified, these are default query parameters when serializing route
     */
    constructor(loader, initialAppContext, user, rootPath, loggers, persistentStorage, configProvider, isDeepLinkInit, defaultQueryParameters) {
        this.loader = loader;
        this.loggers = loggers;
        this.persistentStorage = persistentStorage;
        this.configProvider = configProvider;
        this.loadSuiteNavStore = () => {
            throw new NotSupportedError("SuiteNavStore is not supported");
        };
        this.loadCreateTaskFromMessageStore = () => {
            throw new NotSupportedError("CreateTaskFromMessage is not supported");
        };
        /** Map of all functions that load a store */
        this.storeLoaders = {
            [Store.Activity]: this.dynamicAccessor(Store.Activity, () => import(/* webpackChunkName: "uno-activitystore" */ "@ms/uno-stores/lib/local/stores/activity/ActivityStore"), (bundle) => new bundle.ActivityStore(this.loggers)),
            [Store.Aggregates]: this.dynamicAccessor(Store.Aggregates, () => import(/* webpackChunkName: "uno-aggregatesstore" */ "@ms/uno-stores/lib/local/stores/aggregates/AggregatesStore"), (bundle) => new bundle.AggregatesStore()),
            [Store.AppContext]: this.staticAccessor(() => this.appContextStore),
            [Store.Auth]: this.staticAccessor(() => this.authStore),
            [Store.BasicBucket]: this.dynamicAccessor(Store.BasicBucket, () => import(
            /* webpackChunkName: "uno-plannerbucketstore" */ "@ms/uno-stores/lib/local/stores/legacyPlanner/bucket/BasicBucketStore"), (bundle) => new bundle.BasicBucketStore(this.loggers)),
            [Store.BasicPlan]: this.dynamicAccessor(Store.BasicPlan, () => import(/* webpackChunkName: "uno-basicplanstore" */ "@ms/uno-stores/lib/local/stores/legacyPlanner/plan/BasicPlanStore"), (bundle) => new bundle.BasicPlanStore(this.loggers)),
            [Store.BasicPlanConversionStatus]: this.dynamicAccessor(Store.BasicPlanConversionStatus, () => import(
            /* webpackChunkName: "uno-basicplanconversionstatusstore" */ "@ms/uno-stores/lib/local/stores/upsell/BasicPlanConversionStatusStore"), (bundle) => new bundle.BasicPlanConversionStatusStore(this.loggers, this.configProvider)),
            [Store.BasicPermissions]: this.dynamicAccessor(Store.BasicPermissions, () => import(
            /* webpackChunkName: "uno-basicpermissionsstore" */ "@ms/uno-stores/lib/local/stores/permissions/BasicPermissionsStore"), (bundle) => new bundle.BasicPermissionsStore(this.appContextStore.getAppContext(), this.configProvider, UserPolicyFlags.None, // TODO: Remove this once we have a way to get the user's policy flags
            this.loggers)),
            [Store.BasicTask]: this.dynamicAccessor(Store.BasicTask, () => import(/* webpackChunkName: "uno-basictaskstore" */ "@ms/uno-stores/lib/local/stores/legacyPlanner/task/BasicTaskStore"), (bundle) => new bundle.BasicTaskStore(this.loggers)),
            [Store.Campaign]: this.dynamicAccessor(Store.Campaign, () => import(/* webpackChunkName: "uno-campaignstore" */ "@ms/uno-stores/lib/local/stores/campaign/CampaignStore"), (bundle) => new bundle.CampaignStore(this.loggers, this.persistentStorage, this.configProvider)),
            [Store.Capabilities]: this.dynamicAccessor(Store.Capabilities, () => import(/* webpackChunkName: "uno-capabilitiesstore" */ "@ms/uno-stores/lib/local/stores/capabilities/CapabilitiesStore"), (bundle) => new bundle.CapabilitiesStore(this.configProvider, this.loggers)),
            [Store.Container]: this.dynamicAccessor(Store.Container, () => import(/* webpackChunkName: "uno-containerstore" */ "@ms/uno-stores/lib/local/stores/container/ContainerStore"), (bundle) => new bundle.ContainerStore(this.loggers, this.appContextStore.getAppContext().userId ?? "")),
            [Store.Conversations]: this.dynamicAccessor(Store.Conversations, () => import(/* webpackChunkName: "uno-conversationsstore" */ "@ms/uno-stores/lib/local/stores/conversations/ConversationsStore"), (bundle) => new bundle.ConversationsStore(this.loggers)),
            [Store.CreateTaskFromMessage]: {
                loadForDispatch: () => {
                    return this.loadCreateTaskFromMessageStore();
                },
                load: () => this.loader.get(this.getStoreBundleKey(Store.CreateTaskFromMessage)),
                loadForPersistence: () => null,
            },
            [Store.DeltaSync]: this.dynamicAccessor(Store.DeltaSync, () => import(
            /* webpackChunkName: "uno-deltasyncstore" */ "@ms/uno-stores/lib/local/stores/legacyPlanner/deltaSync/DeltaSyncStore"), (bundle) => new bundle.DeltaSyncStore(this.loggers)),
            [Store.DriveItem]: this.dynamicAccessor(Store.DriveItem, () => import(/* webpackChunkName: "uno-driveitemstore" */ "@ms/uno-stores/lib/local/stores/driveItem/DriveItemStore"), (bundle) => new bundle.DriveItemStore(this.loggers)),
            [Store.Groups]: this.dynamicAccessor(Store.Groups, () => import(/* webpackChunkName: "uno-groupsstore" */ "@ms/uno-stores/lib/local/stores/groups/GroupsStore"), (bundle) => new bundle.GroupsStore(this.loggers)),
            [Store.Intelligence]: this.dynamicAccessor(Store.Intelligence, () => import(/* webpackChunkName: "uno-intelligencestore" */ "@ms/uno-stores/lib/local/stores/intelligence/IntelligenceStore"), (bundle) => new bundle.IntelligenceStore(this.loggers)),
            [Store.Modal]: this.dynamicAccessor(Store.Modal, () => import(/* webpackChunkName: "uno-modalstore" */ "@ms/uno-stores/lib/local/stores/modal/ModalStore"), (bundle) => new bundle.ModalStore(this.loggers, this.configProvider)),
            [Store.WorkflowCopilot]: this.dynamicAccessor(Store.WorkflowCopilot, () => import(
            /* webpackChunkName: "uno-workflowcopilotstore" */ "@ms/uno-stores/lib/local/stores/workflowCopilot/WorkflowCopilotStore"), (bundle) => new bundle.WorkflowCopilotStore()),
            [Store.Mru]: this.dynamicAccessor(Store.Mru, () => import(/* webpackChunkName: "uno-mrustore" */ "@ms/uno-stores/lib/local/stores/mru/MruStore"), (bundle) => new bundle.MruStore(this.loggers, this.configProvider)),
            [Store.Navigation]: this.dynamicAccessor(Store.Navigation, () => import(/* webpackChunkName: "uno-navigationstore" */ "@ms/uno-stores/lib/local/stores/route/NavigationStore"), (bundle) => new bundle.NavigationStore(this.loggers)),
            [Store.NewsBar]: this.dynamicAccessor(Store.NewsBar, () => import(/* webpackChunkName: "uno-newsbarstore" */ "@ms/uno-stores/lib/local/stores/newsBar/NewsBarStore"), (bundle) => new bundle.NewsBarStore(this.loggers, this.persistentStorage)),
            [Store.Notification]: this.dynamicAccessor(Store.Notification, () => import(/* webpackChunkName: "uno-notificationstore" */ "@ms/uno-stores/lib/local/stores/notification/NotificationStore"), (bundle) => new bundle.NotificationStore(this.loggers)),
            [Store.OperationTracking]: this.dynamicAccessor(Store.OperationTracking, () => import(
            /* webpackChunkName: "uno-optrackstore" */ "@ms/uno-stores/lib/local/stores/operationTracking/OperationTrackingStore"), (bundle) => new bundle.OperationTrackingStore(this.loggers)),
            [Store.Org]: this.dynamicAccessor(Store.Org, () => import(/* webpackChunkName: "uno-orgstore" */ "@ms/uno-stores/lib/local/stores/org/OrgStore"), (bundle) => new bundle.OrgStore(this.configProvider(), this.persistentStorage, this.loggers)),
            [Store.PerformanceCollection]: {
                loadForDispatch: () => this.performanceCollectionStore,
                load: () => this.performanceCollectionStore,
                loadForPersistence: () => null,
            },
            [Store.PlanConversion]: this.dynamicAccessor(Store.PlanConversion, () => import(/* webpackChunkName: "uno-planconversionstore" */ "@ms/uno-stores/lib/local/stores/upsell/PlanConversionStore"), (bundle) => new bundle.PlanConversionStore(this.loggers, this.configProvider)),
            [Store.Portfolio]: this.dynamicAccessor(Store.Portfolio, () => import(/* webpackChunkName: "uno-portfoliostore" */ "@ms/uno-stores/lib/local/stores/portfolio/PortfolioStore"), (bundle) => new bundle.PortfolioStore(this.loggers, this.configProvider)),
            [Store.PremiumPlan]: this.dynamicAccessor(Store.PremiumPlan, () => import(
            /* webpackChunkName: "uno-premiumplanstore" */ "@ms/uno-stores/lib/local/stores/project/premiumPlan/PremiumPlanStore"), (bundle) => new bundle.PremiumPlanStore(this.loggers, this.configProvider)),
            [Store.PremiumPlanPermissions]: this.dynamicAccessor(Store.PremiumPlanPermissions, () => import(
            /* webpackChunkName: "uno-premiumplanpermissionsstore" */ "@ms/uno-stores/lib/local/stores/permissions/PremiumPlanPermissionsStore"), (bundle) => new bundle.PremiumPlanPermissionsStore(this.configProvider, this.loggers)),
            [Store.Publishing]: this.dynamicAccessor(Store.Publishing, () => import(/* webpackChunkName: "uno-publishingstore" */ "@ms/uno-stores/lib/local/stores/publishing/PublishingStore"), (bundle) => new bundle.PublishingStore(this.loggers)),
            [Store.ProjectHomeResource]: this.dynamicAccessor(Store.ProjectHomeResource, () => import(
            /* webpackChunkName: "uno-projecthomeresourcestore" */ "@ms/uno-stores/lib/local/stores/projectHome/ProjectHomeResourceStore"), (bundle) => new bundle.ProjectHomeResourceStore(this.loggers)),
            [Store.RecommendedPlans]: this.dynamicAccessor(Store.RecommendedPlans, () => import(
            /* webpackChunkName: "uno-recommendedplansstore" */ "@ms/uno-stores/lib/local/stores/recommendedPlan/RecommendedPlansStore"), (bundle) => new bundle.RecommendedPlansStore(this.loggers)),
            [Store.Route]: this.staticAccessor(() => this.routeStore),
            [Store.StatusReport]: this.dynamicAccessor(Store.StatusReport, () => import(/* webpackChunkName: "uno-statusreportstore" */ "@ms/uno-stores/lib/local/stores/statusReport/StatusReportStore"), (bundle) => new bundle.StatusReportStore(this.loggers)),
            [Store.SuiteNav]: {
                loadForDispatch: () => {
                    return this.loadSuiteNavStore();
                },
                load: () => this.loader.get(this.getStoreBundleKey(Store.SuiteNav)),
                loadForPersistence: () => null,
            },
            [Store.Tasks]: this.dynamicAccessor(Store.Tasks, () => import(/* webpackChunkName: "uno-taskstore" */ "@ms/uno-stores/lib/local/stores/task/TaskStore"), (bundle) => new bundle.TaskStore(this.loggers)),
            [Store.Teams]: this.dynamicAccessor(Store.Teams, () => import(/* webpackChunkName: "uno-teamsstore" */ "@ms/uno-stores/lib/local/stores/teams/TeamsStore"), (bundle) => new bundle.TeamsStore(this.loggers)),
            [Store.Templates]: this.dynamicAccessor(Store.Templates, () => import(/* webpackChunkName: "uno-templatesstore" */ "@ms/uno-stores/lib/local/stores/templates/TemplateStore"), (bundle) => new bundle.TemplateStore(this.loggers)),
            [Store.Tenant]: this.dynamicAccessor(Store.Tenant, () => import(/* webpackChunkName: "uno-tenantstore" */ "@ms/uno-stores/lib/local/stores/tenant/TenantStore"), (bundle) => new bundle.TenantStore(this.loggers)),
            [Store.Upsell]: this.dynamicAccessor(Store.Upsell, () => import(/* webpackChunkName: "uno-upsellstore" */ "@ms/uno-stores/lib/local/stores/upsell/UpsellStore"), (bundle) => new bundle.UpsellStore(this.loggers, this.persistentStorage)),
            [Store.UnoShell]: this.staticAccessor(() => this.unoShellStore),
            [Store.User]: this.staticAccessor(() => this.userStore),
            [Store.UserMembership]: this.dynamicAccessor(Store.UserMembership, () => import(/* webpackChunkName: "uno-usermembershipstore" */ "@ms/uno-stores/lib/local/stores/user/UserMembershipStore"), (bundle) => new bundle.UserMembershipStore(this.loggers)),
            [Store.View]: this.dynamicAccessor(Store.View, () => import(/* webpackChunkName: "uno-viewstore" */ "@ms/uno-stores/lib/local/stores/view/ViewStore"), (bundle) => new bundle.ViewStore(this.loggers, this.persistentStorage, this.configProvider)),
        };
        // Setup the static stores
        this.appContextStore = new AppContextStore(initialAppContext, configProvider(), loggers);
        this.authStore = new AuthStore(loggers);
        this.unoShellStore = new UnoShellStore(loggers);
        // Only enable persistence for the app flavor.
        // Tabs, Components will require a more elaborate strategy due to requiring independent caches which
        // results in unbounded storage requirements (as there is no bound on number of tabs or components)
        // CreateTaskFromMessage has a fixed route and thus also does not require this.
        const clientFlavor = configProvider().sessionMetaData.appMetadata.clientFlavor;
        const shouldEnableRoutePersistence = clientFlavor === ClientFlavor.App;
        // Enable hydration only for app flavor and when not initialized from a deep link. (Tabs and components are not supported for now)
        const shouldEnableRouteHydration = clientFlavor === ClientFlavor.App && !isDeepLinkInit;
        this.routeStore = new RouteStore(rootPath, loggers, persistentStorage, configProvider, shouldEnableRoutePersistence, shouldEnableRouteHydration, this.getRouteStorePathPrefix(), defaultQueryParameters);
        this.performanceCollectionStore = new PerformanceCollectionStore(this.routeStore.getCurrentSubRouteType.bind(this.routeStore), loggers);
        this.userStore = new UserStore(user, loggers, configProvider);
    }
    /**
     * Gets the prefix (if any) to use for RouteStore when forming the route path
     * TODO[UnoWeb] ADO#9153353 - We only need to do this while we don't have AFD mapping
     */
    getRouteStorePathPrefix() {
        // By default, no prefix
        return null;
    }
    getAppContextStoreProvider() {
        return () => this.load(Store.AppContext);
    }
    getAuthStoreProvider() {
        return () => this.loadLegacy(Store.Auth);
    }
    getRouteStoreProvider() {
        return () => this.routeStore;
    }
    getActivityStoreProvider() {
        return () => this.loadLegacy(Store.Activity);
    }
    getAggregatesStoreProvider() {
        return () => this.loadLegacy(Store.Aggregates);
    }
    getBasicBucketStoreProvider() {
        return () => this.loadLegacy(Store.BasicBucket);
    }
    getBasicPlanStoreProvider() {
        return () => this.loadLegacy(Store.BasicPlan);
    }
    getBasicPlanConversionStatusStoreProvider() {
        return () => this.loadLegacy(Store.BasicPlanConversionStatus);
    }
    getBasicPermissionsStoreProvider() {
        return () => this.loadLegacy(Store.BasicPermissions);
    }
    getBasicTaskStoreProvider() {
        return () => this.loadLegacy(Store.BasicTask);
    }
    getCreateTaskFromMessageStoreProvider() {
        return () => this.loadLegacy(Store.CreateTaskFromMessage);
    }
    getCampaignStoreProvider() {
        return () => this.loadLegacy(Store.Campaign);
    }
    getCapabilitiesStoreProvider() {
        return () => this.loadLegacy(Store.Capabilities);
    }
    getContainerStoreProvider() {
        return () => this.loadLegacy(Store.Container);
    }
    getConversationsStoreProvider() {
        return () => this.loadLegacy(Store.Conversations);
    }
    getDeltaSyncStoreProvider() {
        return () => this.loadLegacy(Store.DeltaSync);
    }
    getDriveItemStoreProvider() {
        return () => this.loadLegacy(Store.DriveItem);
    }
    getGroupsStoreProvider() {
        return () => this.loadLegacy(Store.Groups);
    }
    getIntelligenceStoreProvider() {
        return () => this.loadLegacy(Store.Intelligence);
    }
    getModalStoreProvider() {
        return () => this.loadLegacy(Store.Modal);
    }
    getMruStoreProvider() {
        return () => this.loadLegacy(Store.Mru);
    }
    getNavigationStoreProvider() {
        return () => this.loadLegacy(Store.Navigation);
    }
    getNewsBarStoreProvider() {
        return () => this.loadLegacy(Store.NewsBar);
    }
    getNotificationStoreProvider() {
        return () => this.loadLegacy(Store.Notification);
    }
    getOperationTrackingStoreProvider() {
        return () => this.loadLegacy(Store.OperationTracking);
    }
    getOrgStoreProvider() {
        return () => this.loadLegacy(Store.Org);
    }
    getPerformanceCollectionStoreProvider() {
        return () => this.load(Store.PerformanceCollection);
    }
    getPlanConversionStoreProvider() {
        return () => this.loadLegacy(Store.PlanConversion);
    }
    getPortfolioStoreProvider() {
        return () => this.loadLegacy(Store.Portfolio);
    }
    getPremiumPlanStoreProvider() {
        return () => this.loadLegacy(Store.PremiumPlan);
    }
    getPremiumPlanPermissionsStoreProvider() {
        return () => this.loadLegacy(Store.PremiumPlanPermissions);
    }
    getPublishingStoreProvider() {
        return () => this.loadLegacy(Store.Publishing);
    }
    getRecommendedPlansStoreProvider() {
        return () => this.loadLegacy(Store.RecommendedPlans);
    }
    getProjectHomeResourceStoreProvider() {
        return () => this.loadLegacy(Store.ProjectHomeResource);
    }
    getStatusReportStoreProvider() {
        return () => this.loadLegacy(Store.StatusReport);
    }
    getSuiteNavStoreProvider() {
        return () => this.loadLegacy(Store.SuiteNav);
    }
    getTeamsStoreProvider() {
        return () => this.loadLegacy(Store.Teams);
    }
    getTemplatesStoreProvider() {
        return () => this.loadLegacy(Store.Templates);
    }
    getTenantStoreProvider() {
        return () => this.loadLegacy(Store.Tenant);
    }
    getUpsellStoreProvider() {
        return () => this.loadLegacy(Store.Upsell);
    }
    getUnoShellStoreProvider() {
        return () => this.unoShellStore;
    }
    getUserStoreProvider() {
        return () => this.load(Store.User);
    }
    getUserMembershipStoreProvider() {
        return () => this.loadLegacy(Store.UserMembership);
    }
    getViewStoreProvider() {
        return () => this.loadLegacy(Store.View);
    }
    getWorkflowCopilotStoreProvider() {
        return () => this.loadLegacy(Store.WorkflowCopilot);
    }
    /**
     * Load a store for reading from it and subscribing to it (used by UI components)
     * @param store The store to load
     * @returns The interface that only allows reads and subscriptions
     */
    load(store) {
        return this.storeLoaders[store].load();
    }
    /**
     * Load a store for each of the input keys
     * @param stores The stores to load
     * @returns A tuple of stores for each of the input keys
     */
    loadAll(stores) {
        return stores.map((store) => this.load(store));
    }
    /**
     * Load a store for persistence related operations, used by workers
     * @param store persisted store to load
     */
    loadForPersistence(store) {
        return this.storeLoaders[store].loadForPersistence();
    }
    /**
     * Load a store for hydration, used by the store hydration worker currently
     * @param store persisted store to load
     */
    loadForHydration(store) {
        return this.storeLoaders[store].loadForHydration();
    }
    /**
     * Load a store for dispatching an action to it (used by the dispatcher)
     * @param store The store to load
     * @returns The IStore interface that only allows action dispatch
     */
    loadForDispatch(store) {
        return this.storeLoaders[store].loadForDispatch();
    }
    /**
     * Load a store for subscription (used by the dispatcher)
     * @param store The store to load
     * @returns The IEventPublishingStore interface that only allows subscription
     */
    loadForSubscription(store) {
        return this.storeLoaders[store].load();
    }
    /**
     * Legacy load API
     * @param store The store to load
     * @returns The store interface or a promise of the store interface
     */
    loadLegacy(store) {
        return this.storeLoaders[store].loadForDispatch();
    }
    getStoreBundleKey(storeType) {
        return `uno-${storeType.toString().toLowerCase()}store`;
    }
    /**
     * Helper method to club together the loadForDispatch and load methods for a dynamic store
     * @param store The store to load
     * @param importCall Method to import the store bundle
     * @param instantiate Method to instantiate the store
     */
    dynamicAccessor(store, importCall, instantiate) {
        const bundleKey = this.getStoreBundleKey(store);
        return {
            loadForDispatch: () => this.loader.load(bundleKey, importCall, instantiate),
            load: () => this.loader.get(bundleKey),
            loadForPersistence: () => {
                const storeInstance = this.loader.get(bundleKey);
                if (isPersistedStore(storeInstance)) {
                    return storeInstance;
                }
                return null; // Return null if the store is not already loaded or does not implement IPersistedStore
            },
            loadForHydration: () => this.loader.load(bundleKey, importCall, instantiate),
        };
    }
    /**
     * Helper method to club together the loadForDispatch and load methods for a static store
     * @param getter Method to read reference to static store
     */
    staticAccessor(getter) {
        return {
            loadForDispatch: getter,
            load: getter,
            loadForPersistence: () => null,
        };
    }
}
