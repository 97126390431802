// Constants
import { ViewRenderState, ViewName } from "@ms/uno-constants/lib/local/ViewConstants";
import { Store } from "@ms/uno-stores/lib/local/stores/Store";
import { SubRouteKeys, SubRouteTypeToViewNameMap } from "@ms/uno-constants/lib/local/RoutingConstants";
import { ClientFlavor } from "@ms/uno-constants/lib/local/AppConstants";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
import { getValueForKey } from "@ms/uno-routing/lib/local/utilities/RouteUtilities";
import { sleep } from "@ms/uno-utilities/lib/local/ControlFlowUtilities";
import { MarkerTag } from "@ms/uno-telemetry/lib/local/performance/constants/PerformanceMarkerConstants";
var BootObservationState;
/**
 * Boot observation state
 */ (function(BootObservationState) {
    /** Boot state is not yet known */ BootObservationState[BootObservationState["Unknown"] = 0] = "Unknown";
    /** Observation timed out */ BootObservationState[BootObservationState["TimedOut"] = 1] = "TimedOut";
    /** All visible views have rendered with critical data */ BootObservationState[BootObservationState["Critical"] = 2] = "Critical";
    /** All visible views have rendered with all data */ BootObservationState[BootObservationState["Full"] = 3] = "Full";
})(BootObservationState || (BootObservationState = {}));
/**
 * Worker that observes and reports boot completion
 */ export class BootObserverWorker {
    /**
     * Initialize the worker
     * @param disableTimeout Whether to disable the timeout (useful for unit testing)
     */ async init(disableTimeout = false) {
        this.storeLoader.load(Store.PerformanceCollection).subscribeToStore(this.checkBootCompletion);
        /**
         * If we haven't seen a boot completion event after the timeout, we'll invoke
         * the boot completion callbacks, to guarantee that the callbacks are always invoked.
         *
         * Note: This implies that any allbacks that assumes that boot has completed
         * will be inaccurate in cases when this times out. Although the timeout value
         * will be choosen so that this happens only for a negligible number of cases.
         *
         * Trade-off: Guarantee eventual callback execution V.S. Trigger accuracy
         */ if (!disableTimeout) {
            await sleep(this.configProvider().settings.workerSettings.bootObserverWorker.timeout);
            if (this.bootObservationState === 0) {
                this.bootObservationState = 1;
                this.loggers.traceLogger.logTrace(0x1e2616c1 /* tag_4j71b */ , TraceLevel.Warning, "BootObserverTimeout");
                this.invokeBootCompletionCallbacks();
            }
        }
    }
    onBootCompletion(callback) {
        this.bootCompletionCallbacks.push(callback);
    }
    /**
     * Handle boot completion
     * @param state The boot observation state
     */ handleBootCompletion(state) {
        this.bootObservationState = state;
        this.loggers.performanceMarker.mark(MarkerTag.BootCompleted, {
            state: BootObservationState[state]
        });
        this.invokeBootCompletionCallbacks();
    }
    /**
     * Create an instance of BootObserverWorker
     * @param configProvider The config provider
     * @param storeLoader The store loader
     * @param loggers Loggers
     */ constructor(configProvider, storeLoader, loggers){
        this.configProvider = configProvider;
        this.storeLoader = storeLoader;
        this.loggers = loggers;
        this.bootObservationState = 0;
        this.areCompletionCallbacksInvoked = false;
        this.bootCompletionCallbacks = [];
        this.checkBootCompletion = ()=>{
            if (this.bootObservationState === 3) {
                return;
            }
            const [performanceStore, routeStore] = this.storeLoader.loadAll([
                Store.PerformanceCollection,
                Store.Route
            ]);
            // Views that are rendered on the current route
            const renderedViews = [];
            let primaryView = SubRouteTypeToViewNameMap[routeStore.getCurrentSubRouteType()];
            // For task deeplinks, the user cares most about the task details
            const taskId = getValueForKey(routeStore.getCurrentSubRoute(), SubRouteKeys.Task);
            if (taskId) {
                primaryView = ViewName.TaskEditorContainer;
            }
            renderedViews.push(primaryView);
            // For "App" flavor, left nav render is rendered on every route
            const clientFlavor = this.configProvider().sessionMetaData.appMetadata.clientFlavor;
            if (clientFlavor === ClientFlavor.App) {
                renderedViews.push(ViewName.UnoLeftNavContainer);
            }
            // Check whether full boot has completed
            if (this.bootObservationState < 3) {
                const isFullBootCompleted = renderedViews.every((view)=>{
                    // Every view should have reached the final state
                    return performanceStore.getViewRenderState(view) === ViewRenderState.All;
                });
                if (isFullBootCompleted) {
                    this.handleBootCompletion(3);
                    // Unsubscribe from performance store once full boot has been observed
                    performanceStore.unsubscribeFromStore(this.checkBootCompletion);
                }
            }
            // Check whether critical boot has completed
            if (this.bootObservationState < 2) {
                const isCriticalBootCompleted = renderedViews.every((view)=>{
                    const state = performanceStore.getViewRenderState(view);
                    // Every view should have reached at least the critical state
                    return state !== null && state >= ViewRenderState.Critical;
                });
                if (isCriticalBootCompleted) {
                    this.handleBootCompletion(2);
                }
            }
        };
        this.invokeBootCompletionCallbacks = ()=>{
            if (this.areCompletionCallbacksInvoked) {
                return;
            }
            this.areCompletionCallbacksInvoked = true;
            this.bootCompletionCallbacks.forEach((callback)=>callback());
        };
    }
}
